import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";

const LoadingScreen = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box display="flex" justifyContent="center" alignItems="center" mt="40vh">
      <CircularProgress style={{ color: colors.greenAccent[500] }} />
    </Box>
  );
};

export default LoadingScreen;
