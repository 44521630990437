import { useEffect, useState, useContext } from "react";
import "react-datepicker/dist/react-datepicker.css";
import CoolTable from "../../components/CoolTable";
import SimpleCoolTable from "../../components/SimpleCoolTable";
import LoupeOutlinedIcon from "@mui/icons-material/LoupeOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { AuthContext } from "../../context/authContext";
import LoadingScreen from "../global/LoadingScreen";
import { tokens } from "../../theme";
import { format } from "date-fns";
import Header from "../../components/Header";
import { Box, TextField, Alert, AlertTitle, Fade, Container, useTheme, Typography, Grid } from "@mui/material";
import * as yup from "yup";
import { Formik } from "formik";

function CreateSimulation() {
  const { t } = useTranslation();
  const [pcCount, setPcCount] = useState(1);
  const [floorsCount, setFloorsCount] = useState(0);
  const [floorNames, setFloorNames] = useState([]);
  const [isSent, setIsSent] = useState(false);
  const [generalError, setGeneralError] = useState(false);
  const [sessionError, setSessionError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [idNav, setIdNav] = useState(0);
  const [emails0, setEmails0] = useState([]);
  const [emails1, setEmails1] = useState([]);
  const [hide, setHide] = useState(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [pePcLayout, setPePcLayout] = useState<{
    [key: string]: { [key: string]: any };
  }>({});

  const [pePcConnections, setPePcConnections] = useState<{
    [key: string]: { [key: string]: number };
  }>({});

  const navigate = useNavigate();

  const { currentUser, verifyUser, logout } = useContext(AuthContext);
  const [currentLicense, setCurrentLicense] = useState(null);

  const config = {
    headers: {
      "access-token": process.env.REACT_APP_ACCESS_TOKEN,
      "Content-Type": "application/json", // Adjust the content type if needed
    },
    withCredentials: true,
  };

  const verifyLicense = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + `licenses/email/${currentUser?.email}`, config);

      if (response.data.success === 0) {
        setCurrentLicense(0);
      } else if (response.data.data[0].time === 0) {
        setCurrentLicense(1);
      } else if (response.data.data[0].active === 1) {
        setCurrentLicense(2);
      }
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
        logout();
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    verifyUser(currentUser);
    if (!currentUser || currentUser === null) {
      navigate("/login");
    } else {
      verifyLicense();
      if (currentLicense !== null && currentLicense !== 2) {
        navigate("/login");
      }
      // else if (currentUser.user_type === 1 || currentUser.user_type === 2 || currentUser.user_type === 3) {
      //   navigate("/");
      // }
    }
  }, [currentLicense, currentUser]);

  const [project, setProject] = useState<any>({
    client_name: "",
    email: "",
    email_comercial: currentUser?.email,
    state: 0,
    date: format(Date.now(), "yyyy-MM-dd HH:mm:ss"),
    tipo_tubo: "Multicamada",
    has_solar_panels: false,
    house_parts_count: 1,
    pe_usage: 2,
    running_time: 24,
    running_time_hoterway: 0.5,
    volume_aqs: 0.4,
    water_consumption: 60,
    water_in_temp: 10,
    hot_water_temp: 55,
    average_power: 25,
    solar_heater_water: 0,
    insolation_width: 0,
    ccppt: 1.65,
    cct: 1,
    obs: "",
    pc_count: 1,
    floors_count: 1,
    isShared: 0,
    price_thermal: 0.22,
    price_energy: 0.22,
    water_heating_system: "T0",
    water_price: 4.25,
    building_area: 150,
    energy_reference: 150,
    certificate_class: 1,
    building_type: 0,
  });

  useEffect(() => {
    if (floorNames.length === floorsCount) return;
    const newNames = [...floorNames];
    if (floorNames.length > floorsCount) for (let i = floorNames.length; i > floorsCount; i--) newNames.pop();
    else if (floorNames.length < floorsCount) for (let i = floorsCount; i < floorNames.length; i++) newNames.push("");
    setFloorNames(newNames);
  }, [floorsCount]);

  const getEmails = async () => {
    setIsLoading(true);
    try {
      const response1 = await axios.get(process.env.REACT_APP_API_CALL + "users/emails/0", config);
      setEmails0(response1.data.data);
      const response2 = await axios.get(process.env.REACT_APP_API_CALL + "users/emails/1", config);
      setEmails1(response2.data.data);
      setIsLoading(false);
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getEmails();
    setFloorsCount(project.floors_count);
  }, []);

  let array = [];
  if (emails0 === undefined || emails1 === undefined) {
    console.log("undefined");
  } else {
    for (let i = 0; i < emails0.length; i++) {
      array.push(emails0[i].email);
    }

    for (let i = 0; i < emails1.length; i++) {
      array.push(emails1[i].email);
    }
  }

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    if (values.client_name === "" || values.email_comercial === "") {
      setIsLoading(false);
      window.scrollTo(0, 0);
      setIsEmpty(true);
      return;
    } else {
      try {
        const response = await axios.post(process.env.REACT_APP_API_CALL + "simulation", values, config);
        window.scrollTo(0, 0);
        setIsSent(true);
        setIdNav(response.data.data.insertId);
        setIsLoading(false);
        setHide(true);
      } catch (error) {
        if (error.response.data.auth === false) {
          setSessionError(true);
        } else {
          setGeneralError(true);
          console.log(error);
        }
      }
    }
  };

  const style = {
    "& label.Mui-focused": {
      color: colors.primary[100],
      fontSize: "16px",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: colors.primary[100],
      },
    },
    "& .MuiFormHelperText-root": {
      fontSize: "14px",
    },
  };
  // const styleRequired = {
  //   "& label.Mui-focused": {
  //     color: colors.primary[100],
  //     fontSize: "16px",
  //     fontWeight: "bold",
  //   },
  //   "& .MuiOutlinedInput-root": {
  //     "&.Mui-focused fieldset": {
  //       borderColor: colors.primary[100],
  //       fontWeight: "bold",
  //     },
  //   },
  //   "& .MuiFormHelperText-root": {
  //     fontSize: "14px",
  //     fontWeight: "bold",
  //   },
  //   backgroundColor: colors.blueAccent[1000],
  //   fontWeight: "bold",
  // };

  const simulationSchema = yup.object().shape({
    client_name: yup.string().required(t("create_simulation.schema.client_name")),
    email_comercial: yup.string().email(t("create_simulation.schema.email_comercial")),
    house_parts_count: yup
      .number()
      .typeError(t("create_simulation.schema.house_parts_count.type_error"))
      .required(t("create_simulation.schema.house_parts_count.required"))
      .positive(t("create_simulation.schema.house_parts_count.positive")),
    floors_count: yup
      .number()
      .typeError(t("create_simulation.schema.floors_count.type_error"))
      .required(t("create_simulation.schema.floors_count.required"))
      .min(0, t("create_simulation.schema.floors_count.min")),
  });

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <Box m="20px">
            <Header title={t("create_simulation.title")} subtitle={t("create_simulation.subtitle")} />
          </Box>

          <Grid container xs={1} sm={1} md={1} lg={1} xl={1}>
            <Box
              ml="20px"
              onClick={() => navigate(-1)}
              p="5px 7px 5px 5px"
              display="flex"
              justifyContent="center"
              borderRadius="4px"
              alignItems="center"
              backgroundColor={colors.blueAccent[600]}
              sx={{
                cursor: "pointer",
                textDecoration: "none",
              }}
            >
              <ArrowBackOutlinedIcon
                sx={{
                  textDecoration: "none",
                  color: colors.primary[100],
                  justifyContent: "center",
                }}
              />
              <Typography
                justifyContent="center"
                color={colors.primary[100]}
                sx={{
                  ml: "5px",
                  textDecoration: "none",
                  fontWeight: "bold",
                  justifyContent: "center",
                  marginTop: "1px",
                }}
              >
                {t("general.back_button")}
              </Typography>
            </Box>
          </Grid>
          <Container component="main" maxWidth="100%">
            <Box display="flex" flexDirection="column" alignItems="center" mt="30px">
              <Fade
                in={isEmpty}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  window.scrollTo(0, 0);
                  setTimeout(() => {
                    setIsEmpty(false);
                  }, 3000);
                }}
              >
                <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                  <AlertTitle sx={{ fontSize: "16px" }}>{t("create_simulation.alert_fields.is_empty.title")}</AlertTitle>
                  {t("create_simulation.alert_fields.is_empty.description")}
                </Alert>
              </Fade>

              <Fade
                in={generalError}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  window.scrollTo(0, 0);
                  setTimeout(() => {
                    setGeneralError(false);
                  }, 3000);
                }}
              >
                <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                  <AlertTitle sx={{ fontSize: "16px" }}>{t("create_simulation.alert_fields.general_error.title")}</AlertTitle>
                  {t("create_simulation.alert_fields.general_error.description")}
                </Alert>
              </Fade>

              <Fade
                in={sessionError}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  window.scrollTo(0, 0);
                  setTimeout(() => {
                    setSessionError(false);
                    navigate("/login");
                    logout();
                  }, 3000);
                }}
              >
                <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                  <AlertTitle sx={{ fontSize: "16px" }}>{t("create_simulation.alert_fields.session_error.title")}</AlertTitle>
                  {t("create_simulation.alert_fields.session_error.description")}
                </Alert>
              </Fade>

              <Fade
                in={isSent}
                timeout={{ enter: 1000, exit: 1000 }}
                unmountOnExit={true}
                addEndListener={() => {
                  setTimeout(() => {
                    setIsSent(false);
                    if (idNav !== 0) {
                      navigate("/simulations/" + idNav);
                    }
                  }, 3000);
                }}
              >
                <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="success">
                  <AlertTitle sx={{ fontSize: "16px" }}>{t("create_simulation.alert_fields.is_sent.title")}</AlertTitle>
                  {t("create_simulation.alert_fields.is_sent.description")}
                </Alert>
              </Fade>

              {hide === false ? (
                <Formik
                  onSubmit={handleFormSubmit}
                  initialValues={project}
                  validationSchema={simulationSchema}
                  validateOnChange={false}
                  validateOnBlur={true}
                >
                  {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={4} mt="10px" justifyContent="left">
                        <Grid item xs={12}>
                          <Typography component="h1" variant="h3">
                            {t("create_simulation.form.title1")}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={3.5} xl={3.5}>
                          <TextField
                            fullWidth
                            multiline
                            variant="filled"
                            type="text"
                            label={t("create_simulation.form.client_name")}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.client_name}
                            name="client_name"
                            error={!!touched.client_name && !!errors.client_name}
                            helperText={touched.client_name && errors.client_name}
                            inputProps={{ style: { fontSize: 16 } }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            sx={style}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Typography component="h1" variant="h3" mt="20px" mb="-5px">
                            {t("create_simulation.form.title2")}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                          <TextField
                            id="filled-number"
                            fullWidth
                            multiline
                            variant="filled"
                            type="number"
                            label={t("create_simulation.form.house_parts_count")}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);

                              if (parseInt(e.target.value) > 0) {
                                let updatePePcLayout = Object.keys(pePcLayout).length - parseInt(e.target.value);
                                if (updatePePcLayout !== 0) {
                                  let pepc_layout_copy = { ...pePcLayout };
                                  for (let i = 0; i < Math.abs(updatePePcLayout); i++) {
                                    if (updatePePcLayout > 0) delete pepc_layout_copy["div_" + (Object.keys(pePcLayout).length - i - 1)];
                                    else {
                                      pepc_layout_copy["div_" + (Object.keys(pePcLayout).length + i)] = {};
                                      pepc_layout_copy["div_" + (Object.keys(pePcLayout).length + i)]["name"] = "";
                                      pepc_layout_copy["div_" + (Object.keys(pePcLayout).length + i)]["nickname"] = "";
                                      pepc_layout_copy["div_" + (Object.keys(pePcLayout).length + i)]["caudal_minimo"] = "";
                                      pepc_layout_copy["div_" + (Object.keys(pePcLayout).length + i)]["caudal_total"] = "";
                                      pepc_layout_copy["div_" + (Object.keys(pePcLayout).length + i)]["hoterway"] = "0";
                                    }
                                  }
                                  setPePcLayout(pepc_layout_copy);
                                }

                                setProject({
                                  ...project,
                                  house_parts_count: parseInt(e.target.value),
                                });
                              } else {
                                setProject({
                                  ...project,
                                  house_parts_count: 0,
                                });
                              }
                            }}
                            value={values.house_parts_count}
                            name="house_parts_count"
                            error={!!touched.house_parts_count && !!errors.house_parts_count}
                            helperText={touched.house_parts_count && errors.house_parts_count}
                            inputProps={{ style: { fontSize: 16 }, type: "number" }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            sx={style}
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={4} xl={1.5}>
                          <TextField
                            fullWidth
                            multiline
                            variant="filled"
                            type="number"
                            label={t("create_simulation.form.floors_count")}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              let value = parseInt(e.target.value);
                              if (value > 0) setFloorsCount(value);
                              if (value === 0) setFloorsCount(0);
                            }}
                            value={values.floors_count}
                            name="floors_count"
                            error={!!touched.floors_count && !!errors.floors_count}
                            helperText={touched.floors_count && errors.floors_count}
                            inputProps={{ style: { fontSize: 16 }, type: "number" }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            sx={style}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Typography component="h1" variant="h6" mt="-20px">
                            {t("create_simulation.form.required")}
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography component="h1" variant="h3" mt="20px" mb="-5px">
                            {t("create_simulation.form.title3")}
                          </Typography>
                        </Grid>

                        <>
                          <div
                            className="projectSectionContainer"
                            style={{
                              flexDirection: "row",
                              width: "90%",
                              flexWrap: "wrap",
                            }}
                          >
                            {Array.from(Array(project.house_parts_count).keys()).map((i) => {
                              const setPe_PCValues = (items_in: any[]) => {
                                let res: { [key: string]: any } = {};
                                items_in.forEach((item: any, index) => {
                                  if (index === 0) res["name"] = item;
                                  else if (index === 1) res["nickname"] = item;
                                  else if (index === 2) res["caudal_minimo"] = item;
                                  else if (index === 3) res["caudal_total"] = item;
                                  else if (index === 4) res["hoterway"] = item;
                                });
                                let pePcLayoutCopy = pePcLayout;
                                pePcLayoutCopy["div_" + i] = res;

                                setPePcLayout({ ...pePcLayoutCopy });
                              };
                              return (
                                <div key={"" + i} className="projectPePcContainer">
                                  <p className="projectSectionTitle">{t("create_simulation.form.compartment") + " " + (i + 1)}</p>
                                  <div className="coolTablesContainer">
                                    <CoolTable
                                      t={t}
                                      editMode={Object.keys(pePcConnections).length === 0 ? 0 : 1}
                                      index={i}
                                      pepcLayout={null}
                                      setPe_PCValues={setPe_PCValues}
                                      className="coolTable"
                                      pcCount={pcCount}
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </>

                        <>
                          <div
                            className="projectSectionContainer"
                            style={{
                              flexDirection: "row",
                              width: "90%",
                              flexWrap: "wrap",
                            }}
                          >
                            {Array.from(Array(floorsCount).keys()).map((i) => {
                              const setName = (name) => {
                                const newNames = [...floorNames];
                                newNames[i] = name;
                                setFloorNames(newNames);
                              };
                              return (
                                <div key={"" + i} className="projectPePcContainer">
                                  <p className="projectSectionTitle">{t("create_simulation.form.floor") + " " + (i + 1)}</p>
                                  <div className="coolTablesContainer">
                                    <SimpleCoolTable t={t} index={i} setName={setName} editMode={0} />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </>
                      </Grid>
                      <Grid container justifyContent="flex-end" spacing={4}>
                        <Grid item xs={12} sm={5} md={2.5} lg={3} xl={2} mt="40px" mr="25px">
                          <Box
                            p="5px 7px 5px 5px"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            borderRadius="4px"
                            mb="20px"
                            backgroundColor={colors.blueAccent[600]}
                            sx={{
                              cursor: "pointer",
                              textDecoration: "none",
                            }}
                            onClick={() => {
                              const complete_project = { ...project };
                              complete_project["pepc_layout"] = {
                                ...pePcLayout,
                              };

                              handleFormSubmit({
                                client_name: values.client_name,
                                email: complete_project["email"],
                                email_comercial: currentUser?.email,
                                house_parts_count: values.house_parts_count,
                                tipo_tubo: values.tipo_tubo,
                                isShared: values.isShared,
                                address: complete_project["address"],
                                average_power: complete_project["average_power"],
                                date: format(Date.now(), "yyyy-MM-dd HH:mm:ss"),
                                has_solar_panels: complete_project["has_solar_panels"],
                                hot_water_temp: complete_project["hot_water_temp"],
                                insolation_width: complete_project["insolation_width"],
                                ccppt: complete_project["ccppt"],
                                cct: complete_project["cct"],
                                nif: complete_project["nif"],
                                obs: complete_project["obs"],
                                pe_usage: complete_project["pe_usage"],
                                pepc_layout: complete_project["pepc_layout"],
                                phone: complete_project["phone"],
                                postal_code: complete_project["postal_code"],
                                running_time: complete_project["running_time"],
                                running_time_hoterway: complete_project["running_time_hoterway"],
                                volume_aqs: complete_project["volume_aqs"],
                                solar_heater_water: complete_project["solar_heater_water"],
                                state: complete_project["state"],
                                total_habitants: values.house_parts_count,
                                version: complete_project["version"],
                                water_consumption: complete_project["water_consumption"],
                                price_thermal: complete_project["price_thermal"],
                                price_energy: complete_project["price_energy"],
                                water_in_temp: complete_project["water_in_temp"],
                                water_price: complete_project["water_price"],
                                floors_count: floorsCount,
                                floors_names: floorNames,
                                building_area: complete_project["building_area"],
                                energy_reference: complete_project["energy_reference"],
                                certificate_class: complete_project["certificate_class"],
                                building_type: complete_project["building_type"],
                              });
                            }}
                          >
                            <LoupeOutlinedIcon
                              sx={{
                                textDecoration: "none",
                                color: colors.grey[100],
                                justifyContent: "center",
                              }}
                            />
                            <Typography
                              variant="h3"
                              justifyContent="center"
                              color={colors.grey[100]}
                              sx={{
                                ml: "5px",
                                textDecoration: "none",
                                fontWeight: "bold",
                                justifyContent: "center",
                                marginTop: "1px",
                              }}
                            >
                              {t("create_simulation.form.buttons.create_simulation")}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </Formik>
              ) : (
                <LoadingScreen />
              )}
            </Box>
          </Container>
        </>
      )}
    </>
  );
}

export default CreateSimulation;
