import { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  useTheme,
  Alert,
  AlertTitle,
  Fade,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
} from "@mui/material";
import { DataGrid, GridToolbarContainer, GridToolbarFilterButton, GridToolbarDensitySelector } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import Header from "../../components/Header";
import axios from "axios";
import LoadingScreen from "../global/LoadingScreen";
import { AuthContext } from "../../context/authContext";
import { useTranslation } from "react-i18next";
import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";

const AWSUsers = () => {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isEliminated, setIsEliminated] = useState(false);
  const [open, setOpen] = useState(false);
  const [generalError, setGeneralError] = useState(false);
  const [sessionError, setSessionError] = useState(false);
  const [currentLicense, setCurrentLicense] = useState(null);
  const [idToDelete, setIdToDelete] = useState();

  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { currentUser, verifyUser, logout } = useContext(AuthContext);

  const config = {
    headers: {
      "access-token": process.env.REACT_APP_ACCESS_TOKEN,
      "Content-Type": "application/json", // Adjust the content type if needed
    },
    withCredentials: true,
  };

  const verifyLicense = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + `licenses/email/${currentUser?.email}`, config);

      if (response.data.success === 0) {
        setCurrentLicense(0);
      } else if (response.data.data[0].time === 0) {
        setCurrentLicense(1);
      } else if (response.data.data[0].active === 1) {
        setCurrentLicense(2);
      }
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
        logout();
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    verifyUser(currentUser);
    if (!currentUser) {
      navigate("/login");
    } else {
      verifyLicense();
      if (currentLicense !== null && currentLicense !== 2) {
        navigate("/login");
      } else if (currentUser.user_type === 2 || currentUser.user_type === 3) {
        navigate("/");
      }
    }
  }, [currentLicense, currentUser]);

  const handleClickOpen = (id) => {
    setOpen(true);
    setIdToDelete(id);
  };

  const handleCloseDialog = () => {
    closeMaybe();
    setIdToDelete(null);
    setOpen(false);
    return;
  };

  const handleClose = () => {
    deleteUser(idToDelete);
    setIdToDelete(null);
    setOpen(false);
    return;
  };

  const closeMaybe = async () => {
    try {
      await axios.get(process.env.REACT_APP_API_CALL + "users", config);
      setOpen(false);
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
      }
    }
  };

  const getUsers = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + "users", config);
      setUsers(response.data.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
      }
    }
  };

  const deleteUser = async (id) => {
    try {
      await axios.delete(process.env.REACT_APP_API_CALL + `users/${id}`, config);
      getUsers();
      setIsEliminated(true);
      setOpen(false);
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
      }
    }
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  };

  useEffect(() => {
    getUsers();
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      headerAlign: "left",
      align: "left",
      minWidth: 40,
      maxWidth: 40,
    },
    {
      field: "first_name",
      headerName: t("aws_users.table_fields.first_name"),
      headerAlign: "left",
      align: "left",
      minWidth: 100,
      maxWidth: 100,
    },
    {
      field: "last_name",
      headerName: t("aws_users.table_fields.last_name"),
      headerAlign: "left",
      align: "left",
      minWidth: 100,
      maxWidth: 100,
    },
    {
      field: "email",
      headerName: t("aws_users.table_fields.email"),
      headerAlign: "left",
      align: "left",
      minWidth: 150,
    },
    {
      field: "email_supervisor",
      headerName: t("aws_users.table_fields.email_supervisor"),
      headerAlign: "left",
      align: "left",
      minWidth: 175,
    },
    {
      field: "license",
      headerName: t("aws_users.table_fields.license"),
      headerAlign: "left",
      align: "left",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "user_type",
      headerName: t("aws_users.table_fields.user_type"),
      headerAlign: "center",
      align: "center",
      minWidth: 175,
      maxWidth: 175,
      flex: 1,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      sortable: false,
      renderCell: ({ row: { user_type } }) => {
        return (
          <Box
            width="100%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            borderRadius="4px"
            backgroundColor={
              user_type === 0
                ? colors.greenAccent[600]
                : user_type === 1
                ? colors.greenAccent[800]
                : user_type === 2
                ? "#c66302"
                : colors.redAccent[500]
            }
          >
            {user_type === 3 && <Person2OutlinedIcon />}
            {user_type === 2 && <ManageAccountsOutlinedIcon />}
            {user_type === 1 && <BusinessCenterOutlinedIcon />}
            {user_type === 0 && <AdminPanelSettingsOutlinedIcon />}
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {user_type === 3 && <>{t("aws_users.table_fields.user_types.3")}</>}
              {user_type === 2 && <>{t("aws_users.table_fields.user_types.2")}</>}
              {user_type === 1 && <>{t("aws_users.table_fields.user_types.1")}</>}
              {user_type === 0 && <>{t("aws_users.table_fields.user_types.0")}</>}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "edit",
      headerName: "",
      minWidth: 90,
      maxWidth: 90,
      flex: 1,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box
            width="100%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            borderRadius="4px"
            backgroundColor={colors.blueAccent[600]}
            component={Link}
            to={`/users/${params.row.id}`}
            sx={{
              cursor: "pointer",
              textDecoration: "none",
            }}
          >
            <AutoFixHighOutlinedIcon sx={{ textDecoration: "none", color: colors.grey[100] }} />
            <Typography color={colors.grey[100]} sx={{ ml: "5px", textDecoration: "none" }}>
              {t("aws_users.table_fields.edit")}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "delete",
      headerName: "",
      minWidth: 105,
      maxWidth: 105,
      flex: 1,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box
            width="100%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            borderRadius="4px"
            backgroundColor={colors.redAccent[600]}
            onClick={() => {
              handleClickOpen(params.row.id);
            }}
            sx={{ cursor: "pointer" }}
          >
            <DeleteOutlineOutlinedIcon />
            <Typography color={colors.grey[100]} sx={{ ml: "5px", textDecoration: "none" }}>
              {t("aws_users.table_fields.delete")}
            </Typography>
            <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title">
                <Typography
                  justifyContent="center"
                  color={colors.primary[100]}
                  sx={{
                    textDecoration: "none",
                    justifyContent: "center",
                    mt: "5px",
                  }}
                >
                  {t("aws_users.table_fields.delete_warnings.alert_dialog_title")}
                </Typography>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Typography
                    justifyContent="center"
                    color={colors.primary[100]}
                    sx={{
                      textDecoration: "none",
                      fontWeight: "bold",
                      justifyContent: "center",
                    }}
                  >
                    {t("aws_users.table_fields.delete_warnings.alert_dialog_description")}
                  </Typography>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Grid item>
                  <Box
                    m="0 auto"
                    p="5px 7px 5px 5px"
                    display="flex"
                    justifyContent="center"
                    borderRadius="4px"
                    alignItems="center"
                    backgroundColor={colors.redAccent[600]}
                    sx={{
                      cursor: "pointer",
                      textDecoration: "none",
                    }}
                    onClick={handleCloseDialog}
                  >
                    <CloseOutlinedIcon
                      sx={{
                        textDecoration: "none",
                        color: colors.primary[100],
                        justifyContent: "center",
                      }}
                    />
                    <Typography
                      justifyContent="center"
                      color={colors.primary[100]}
                      sx={{
                        ml: "5px",
                        textDecoration: "none",
                        fontWeight: "bold",
                        justifyContent: "center",
                        marginTop: "1px",
                      }}
                    >
                      {t("aws_users.table_fields.delete_warnings.alert_deny")}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item>
                  <Box
                    m="0 auto"
                    p="5px 7px 5px 5px"
                    display="flex"
                    justifyContent="center"
                    borderRadius="4px"
                    alignItems="center"
                    backgroundColor={colors.greenAccent[600]}
                    sx={{
                      cursor: "pointer",
                      textDecoration: "none",
                    }}
                    onClick={handleClose}
                  >
                    <CheckOutlinedIcon
                      sx={{
                        textDecoration: "none",
                        color: colors.primary[100],
                        justifyContent: "center",
                      }}
                    />
                    <Typography
                      justifyContent="center"
                      color={colors.primary[100]}
                      sx={{
                        ml: "5px",
                        textDecoration: "none",
                        fontWeight: "bold",
                        justifyContent: "center",
                        marginTop: "1px",
                      }}
                    >
                      {t("aws_users.table_fields.delete_warnings.alert_confirm")}
                    </Typography>
                  </Box>
                </Grid>
              </DialogActions>
            </Dialog>
          </Box>
        );
      },
    },
  ];

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <Box m="20px">
          <Header title={t("aws_users.title")} subtitle={t("aws_users.subtitle")} />

          <Grid container xs={1} sm={1} md={1} lg={1} xl={1} mb="40px">
            <Box
              onClick={() => navigate(-1)}
              p="5px 7px 5px 5px"
              display="flex"
              justifyContent="center"
              borderRadius="4px"
              alignItems="center"
              backgroundColor={colors.blueAccent[600]}
              sx={{
                cursor: "pointer",
                textDecoration: "none",
              }}
            >
              <ArrowBackOutlinedIcon
                sx={{
                  textDecoration: "none",
                  color: colors.primary[100],
                  justifyContent: "center",
                }}
              />
              <Typography
                justifyContent="center"
                color={colors.primary[100]}
                sx={{
                  ml: "5px",
                  textDecoration: "none",
                  fontWeight: "bold",
                  justifyContent: "center",
                  marginTop: "1px",
                }}
              >
                {t("general.back_button")}
              </Typography>
            </Box>
          </Grid>
          <Box mb="20px">
            <Fade
              in={generalError}
              timeout={{ enter: 500, exit: 500 }}
              unmountOnExit={true}
              addEndListener={() => {
                window.scrollTo(0, 0);
                setTimeout(() => {
                  setGeneralError(false);
                }, 3000);
              }}
            >
              <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                <AlertTitle sx={{ fontSize: "16px" }}>{t("aws_users.alert_fields.general_error.title")}</AlertTitle>
                {t("aws_users.alert_fields.general_error.description")}
              </Alert>
            </Fade>

            <Fade
              in={sessionError}
              timeout={{ enter: 500, exit: 500 }}
              unmountOnExit={true}
              addEndListener={() => {
                window.scrollTo(0, 0);
                setTimeout(() => {
                  setSessionError(false);
                  navigate("/login");
                  logout();
                }, 3000);
              }}
            >
              <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                <AlertTitle sx={{ fontSize: "16px" }}>{t("aws_users.alert_fields.session_error.title")}</AlertTitle>
                {t("aws_users.alert_fields.session_error.description")}
              </Alert>
            </Fade>

            <Fade
              in={isEliminated}
              timeout={{ enter: 1000, exit: 1000 }}
              unmountOnExit={true}
              addEndListener={() => {
                setTimeout(() => {
                  setIsEliminated(false);
                }, 3000);
              }}
            >
              <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="success">
                <AlertTitle sx={{ fontSize: "16px" }}>{t("aws_users.alert_fields.is_eliminated.title")}</AlertTitle>
                {t("aws_users.alert_fields.is_eliminated.description")}
              </Alert>
            </Fade>
          </Box>
          <Box
            m="40px 0 0 0"
            height="70vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.greenAccent[100]} !important`,
              },
              "& .MuiButtonBase-root .MuiButton-root .MuiButton-text .MuiButton-textPrimary .MuiButton-sizeMedium .MuiButton-textSizeMedium .MuiButton-root .MuiButton-text .MuiButton-textPrimary .MuiButton-sizeMedium .MuiButton-textSizeMedium .css-13qp4b7-MuiButtonBase-root-MuiButton-root":
                {
                  color: `${colors.blueAccent[700]} !important`,
                },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
            }}
          >
            <DataGrid
              localeText={{
                MuiTablePagination: {
                  labelDisplayedRows: ({ from, to, count }) => `${count} ` + t("table_custom_translation.label_displayed_rows"),
                },
                // Root
                noRowsLabel: t("table_custom_translation.no_rows_label"),
                noResultsOverlayLabel: t("table_custom_translation.no_results_overlay_label"),
                errorOverlayDefaultLabel: t("table_custom_translation.error_overlay_default_label"),
                // Density selector toolbar button text
                toolbarDensity: t("table_custom_translation.toolbar_density"),
                toolbarDensityLabel: t("table_custom_translation.toolbar_density_label"),
                toolbarDensityCompact: t("table_custom_translation.toolbar_density_compact"),
                toolbarDensityStandard: t("table_custom_translation.toolbar_density_standard"),
                toolbarDensityComfortable: t("table_custom_translation.toolbar_density_comfortable"),
                // Columns selector toolbar button text
                toolbarColumns: t("table_custom_translation.toolbar_columns"),
                toolbarColumnsLabel: t("table_custom_translation.toolbar_columns_label"),
                // Filters toolbar button text
                toolbarFilters: t("table_custom_translation.toolbar_filters"),
                toolbarFiltersLabel: t("table_custom_translation.toolbar_filters_label"),
                toolbarFiltersTooltipHide: t("table_custom_translation.toolbar_filters_tooltip_hide"),
                toolbarFiltersTooltipShow: t("table_custom_translation.toolbar_filters_tooltip_show"),
                toolbarFiltersTooltipActive: (count) =>
                  `${count} ${
                    count !== 1
                      ? t("table_custom_translation.toolbar_filters_tooltip_active.filter_plural")
                      : t("table_custom_translation.toolbar_filters_tooltip_active.filter_singular")
                  } ${
                    count !== 1
                      ? t("table_custom_translation.toolbar_filters_tooltip_active.active_plural")
                      : t("table_custom_translation.toolbar_filters_tooltip_active.active_singular")
                  }`,
                // Quick filter toolbar field
                toolbarQuickFilterPlaceholder: t("table_custom_translation.toolbar_quick_filter_placeholder"),
                toolbarQuickFilterLabel: t("table_custom_translation.toolbar_quick_filter_label"),
                toolbarQuickFilterDeleteIconLabel: t("table_custom_translation.toolbar_quick_filter_delete_icon_label"),
                // Export selector toolbar button text
                toolbarExport: t("table_custom_translation.toolbar_export"),
                toolbarExportLabel: t("table_custom_translation.toolbar_export_label"),
                toolbarExportCSV: t("table_custom_translation.toolbar_export_CSV"),
                toolbarExportPrint: t("table_custom_translation.toolbar_export_print"),
                toolbarExportExcel: t("table_custom_translation.toolbar_export_excel"),
                // Columns panel text
                columnsPanelTextFieldLabel: t("table_custom_translation.columns_panel_text_field_label"),
                columnsPanelTextFieldPlaceholder: t("table_custom_translation.columns_panel_text_field_placeholder"),
                columnsPanelDragIconLabel: t("table_custom_translation.columns_panel_drag_icon_label"),
                columnsPanelShowAllButton: t("table_custom_translation.columns_panel_showAll_button"),
                columnsPanelHideAllButton: t("table_custom_translation.columns_panel_hideAll_button"),
                // Filter panel text
                filterPanelAddFilter: t("table_custom_translation.filter_panel_add_filter"),
                filterPanelDeleteIconLabel: t("table_custom_translation.filter_panel_delete_icon_label"),
                filterPanelLinkOperator: t("table_custom_translation.filter_panel_link_operator"),
                filterPanelOperators: t("table_custom_translation.filter_panel_operators"),
                // TODO v6: rename to filterPanelOperator
                filterPanelOperatorAnd: t("table_custom_translation.filter_panel_operator_and"),
                filterPanelOperatorOr: t("table_custom_translation.filter_panel_operator_or"),
                filterPanelColumns: t("table_custom_translation.filter_panel_columns"),
                filterPanelInputLabel: t("table_custom_translation.filter_panel_input_label"),
                filterPanelInputPlaceholder: t("table_custom_translation.filter_panel_input_placeholder"),
                // Filter operators text
                filterOperatorContains: t("table_custom_translation.filter_operator_contains"),
                filterOperatorEquals: t("table_custom_translation.filter_operator_equals"),
                filterOperatorStartsWith: t("table_custom_translation.filter_operator_starts_with"),
                filterOperatorEndsWith: t("table_custom_translation.filter_operator_ends_with"),
                filterOperatorIs: t("table_custom_translation.filter_operator_is"),
                filterOperatorNot: t("table_custom_translation.filter_operator_not"),
                filterOperatorAfter: t("table_custom_translation.filter_operator_after"),
                filterOperatorOnOrAfter: t("table_custom_translation.filter_operator_on_or_after"),
                filterOperatorBefore: t("table_custom_translation.filter_operator_before"),
                filterOperatorOnOrBefore: t("table_custom_translation.filter_operator_on_or_before"),
                filterOperatorIsEmpty: t("table_custom_translation.filter_operator_is_empty"),
                filterOperatorIsNotEmpty: t("table_custom_translation.filter_operator_is_not_empty"),
                filterOperatorIsAnyOf: t("table_custom_translation.filter_operator_is_any_of"),
                // Filter values text
                filterValueAny: t("table_custom_translation.filter_value_any"),
                filterValueTrue: t("table_custom_translation.filter_value_true"),
                filterValueFalse: t("table_custom_translation.filter_value_false"),
                // Column menu text
                columnMenuLabel: t("table_custom_translation.column_menu_label"),
                columnMenuShowColumns: t("table_custom_translation.column_menu_show_columns"),
                columnMenuFilter: t("table_custom_translation.column_menu_filter"),
                columnMenuHideColumn: t("table_custom_translation.column_menu_hide_column"),
                columnMenuUnsort: t("table_custom_translation.column_menu_unsort"),
                columnMenuSortAsc: t("table_custom_translation.column_menu_sort_asc"),
                columnMenuSortDesc: t("table_custom_translation.column_menu_sort_desc"),
                // Column header text
                columnHeaderFiltersTooltipActive: (count) =>
                  `${count} ${
                    count !== 1
                      ? t("table_custom_translation.column_header_filters_tooltip_active.filter_plural")
                      : t("table_custom_translation.column_header_filters_tooltip_active.filter_singular")
                  } ${
                    count !== 1
                      ? t("table_custom_translation.column_header_filters_tooltip_active.active_plural")
                      : t("table_custom_translation.column_header_filters_tooltip_active.active_singular")
                  }`,
                columnHeaderFiltersLabel: t("table_custom_translation.column_header_filters_label"),
                columnHeaderSortIconLabel: t("table_custom_translation.column_header_sort_icon_label"),
                // Rows selected footer text
                footerRowSelected: (count) =>
                  count !== 1
                    ? `${count.toLocaleString()} +` + t("table_custom_translation.footer_row_selected.plural")
                    : `${count.toLocaleString()} ` + t("table_custom_translation.footer_row_selected.singular"),
                // Total row amount footer text
                footerTotalRows: t("table_custom_translation.footer_total_rows"),
                // Total visible row amount footer text
                footerTotalVisibleRows: (visibleCount, totalCount) =>
                  `${visibleCount.toLocaleString()} ` +
                  t("table_custom_translation.footer_total_visible_rows") +
                  `${totalCount.toLocaleString()}`,
                // Checkbox selection text
                checkboxSelectionHeaderName: t("table_custom_translation.checkbox_selection_header_name"),
                checkboxSelectionSelectAllRows: t("table_custom_translation.checkbox_selection_select_all_rows"),
                checkboxSelectionUnselectAllRows: t("table_custom_translation.checkbox_selection_unselect_all_rows"),
                checkboxSelectionSelectRow: t("table_custom_translation.checkbox_selection_select_row"),
                checkboxSelectionUnselectRow: t("table_custom_translation.checkbox_selection_unselect_row"),
                // Boolean cell text
                booleanCellTrueLabel: t("table_custom_translation.boolean_cell_true_label"),
                booleanCellFalseLabel: t("table_custom_translation.boolean_cell_false_label"),
                // Actions cell more text
                actionsCellMore: t("table_custom_translation.actions_cell_more"),
                // Column pinning text
                pinToLeft: t("table_custom_translation.pin_to_left"),
                pinToRight: t("table_custom_translation.pin_to_right"),
                unpin: t("table_custom_translation.unpin"),
                // Tree Data
                treeDataGroupingHeaderName: t("table_custom_translation.tree_data_grouping_header_name"),
                treeDataExpand: t("table_custom_translation.tree_data_expand"),
                treeDataCollapse: t("table_custom_translation.tree_data_collapse"),
                // Grouping columns
                groupingColumnHeaderName: t("table_custom_translation.grouping_column_header_name"),
                groupColumn: (name) => t("table_custom_translation.group_column") + ` ${name}`,
                unGroupColumn: (name) => t("table_custom_translation.un_group_column") + ` ${name}`,
                // Master/detail
                detailPanelToggle: t("table_custom_translation.detail_panel_toggle"),
                expandDetailPanel: t("table_custom_translation.expand_detail_panel"),
                collapseDetailPanel: t("table_custom_translation.collapse_detail_panel"),
                // Row reordering text
                rowReorderingHeaderName: t("table_custom_translation.row_reordering_header_name"),
                // Aggregation
                // aggregationMenuItemHeader: 'Aggregation',
                // aggregationFunctionLabelSum: 'sum',
                // aggregationFunctionLabelAvg: 'avg',
                // aggregationFunctionLabelMin: 'min',
                // aggregationFunctionLabelMax: 'max',
                // aggregationFunctionLabelSize: 'size',
              }}
              hideFooterSelectedRowCount
              componentsProps={{
                pagination: {
                  labelRowsPerPage: t("table_custom_translation.label_rows_per_page"),
                },
              }}
              loading={isLoading}
              rows={users}
              columns={columns}
              components={{ Toolbar: CustomToolbar }}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default AWSUsers;
