import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  useTheme,
  Grid,
  Fade,
  Alert,
  AlertTitle,
  Card,
  CardContent,
  TextField,
  CardHeader,
  InputAdornment,
} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import axios from "axios";
import LoadingScreen from "../global/LoadingScreen";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { AuthContext } from "../../context/authContext";
import { useTranslation } from "react-i18next";

const Glossary = () => {
  const { t } = useTranslation();
  const cardData = [
    {
      title: t("glossary.card_data.1.title"),
      description: t("glossary.card_data.1.description"),
    },
    {
      title: t("glossary.card_data.2.title"),
      description: t("glossary.card_data.3.description"),
    },
    {
      title: t("glossary.card_data.3.title"),
      description: t("glossary.card_data.3.description"),
    },
    {
      title: t("glossary.card_data.4.title"),
      description: t("glossary.card_data.4.description"),
    },
    {
      title: t("glossary.card_data.5.title"),
      description: t("glossary.card_data.5.description"),
    },
    {
      title: t("glossary.card_data.6.title"),
      description: t("glossary.card_data.6.description"),
    },
    {
      title: t("glossary.card_data.7.title"),
      description: t("glossary.card_data.7.description"),
    },
    {
      title: t("glossary.card_data.8.title"),
      description: t("glossary.card_data.8.description"),
    },
    {
      title: t("glossary.card_data.9.title"),
      description: t("glossary.card_data.9.description"),
    },
    {
      title: t("glossary.card_data.10.title"),
      description: t("glossary.card_data.10.description"),
    },
    {
      title: t("glossary.card_data.11.title"),
      description: t("glossary.card_data.11.description"),
    },
    {
      title: t("glossary.card_data.12.title"),
      description: t("glossary.card_data.12.description"),
    },
    {
      title: t("glossary.card_data.13.title"),
      description: t("glossary.card_data.13.description"),
    },
    {
      title: t("glossary.card_data.14.title"),
      description: t("glossary.card_data.14.description"),
    },
    {
      title: t("glossary.card_data.15.title"),
      description: t("glossary.card_data.15.description"),
    },
    {
      title: t("glossary.card_data.16.title"),
      description: t("glossary.card_data.16.description"),
    },
    {
      title: t("glossary.card_data.17.title"),
      description: t("glossary.card_data.17.description"),
    },
    {
      title: t("glossary.card_data.18.title"),
      description: t("glossary.card_data.18.description"),
    },
    {
      title: t("glossary.card_data.19.title"),
      description: t("glossary.card_data.19.description"),
    },
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [generalError, setGeneralError] = useState(false);
  const [sessionError, setSessionError] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [search, setSearch] = useState("");
  const [filteredCards, setFilteredCards] = useState(cardData);

  const style = {
    "& label.Mui-focused": {
      color: colors.primary[100],
      fontSize: "16px",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: colors.primary[100],
      },
    },
    "& .MuiFormHelperText-root": {
      fontSize: "14px",
    },
  };

  const highlightSearchTerm = (text, searchTerm) => {
    if (!searchTerm) return text;

    const regex = new RegExp(`(${searchTerm})`, "gi");
    return text.split(regex).map((part, index) =>
      regex.test(part) ? (
        <span key={index} style={{ fontWeight: "bold", backgroundColor: colors.redAccent[600] }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearch(searchTerm);

    const filtered = cardData.filter(
      (card) => card.title.toLowerCase().includes(searchTerm) || card.description.toLowerCase().includes(searchTerm)
    );
    setFilteredCards(filtered);
  };

  const navigate = useNavigate();

  const { currentUser, verifyUser, logout } = useContext(AuthContext);
  const [currentLicense, setCurrentLicense] = useState(null);

  const config = {
    headers: {
      "access-token": process.env.REACT_APP_ACCESS_TOKEN,
      "Content-Type": "application/json", // Adjust the content type if needed
    },
  };

  const verifyLicense = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + `licenses/email/${currentUser?.email}`, config);

      if (response.data.success === 0) {
        setCurrentLicense(0);
      } else if (response.data.data[0].time === 0) {
        setCurrentLicense(1);
      } else if (response.data.data[0].active === 1) {
        setCurrentLicense(2);
      }
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
        logout();
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    verifyUser(currentUser);
    if (!currentUser) {
      navigate("/login");
    } else {
      verifyLicense();
      if (currentLicense !== null && currentLicense !== 2) {
        navigate("/login");
      } else if (currentUser.user_type === 1 || currentUser.user_type === 2 || currentUser.user_type === 3) {
        navigate("/");
      }
    }
  }, [currentLicense, currentUser]);

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <Box m="20px">
          <Header title={t("glossary.title")} subtitle={t("glossary.subtitle")} />

          <Grid container xs={1} sm={1} md={1} lg={1} xl={1}>
            <Box
              onClick={() => navigate(-1)}
              p="5px 7px 5px 5px"
              display="flex"
              justifyContent="center"
              borderRadius="4px"
              alignItems="center"
              backgroundColor={colors.blueAccent[600]}
              sx={{
                cursor: "pointer",
                textDecoration: "none",
              }}
            >
              <ArrowBackOutlinedIcon
                sx={{
                  textDecoration: "none",
                  color: colors.primary[100],
                  justifyContent: "center",
                }}
              />
              <Typography
                justifyContent="center"
                color={colors.primary[100]}
                sx={{
                  ml: "5px",
                  textDecoration: "none",
                  fontWeight: "bold",
                  justifyContent: "center",
                  marginTop: "1px",
                }}
              >
                {t("general.back_button")}
              </Typography>
            </Box>
          </Grid>

          <Grid container spacing={2} mt="15px" display="flex" justifyContent="flex-end">
            <Grid item xs={12} sm={6} md={6} lg={3.5} xl={2}>
              <Box m="0 auto" p="5px 7px 5px 5px" display="flex" justifyContent="center" borderRadius="4px" alignItems="center">
                <TextField
                  mt="20px"
                  fullWidth
                  multiline
                  variant="filled"
                  type="text"
                  label={t("glossary.search_bar")}
                  onChange={handleSearch}
                  value={search}
                  name="search"
                  inputProps={{
                    style: { fontSize: 16 },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        sx={{
                          textDecoration: "none",
                          color: colors.primary[100],
                          justifyContent: "center",
                        }}
                      >
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    style: { fontSize: 16 },
                  }}
                  sx={style}
                  disabled={false}
                />
              </Box>
            </Grid>
          </Grid>

          <Box m="20px">
            <Fade
              in={generalError}
              timeout={{ enter: 500, exit: 500 }}
              unmountOnExit={true}
              addEndListener={() => {
                window.scrollTo(0, 0);
                setTimeout(() => {
                  setGeneralError(false);
                }, 3000);
              }}
            >
              <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                <AlertTitle sx={{ fontSize: "16px" }}>{t("glossary.alert_fields.general_error.title")}</AlertTitle>
                {t("glossary.alert_fields.general_error.description")}
              </Alert>
            </Fade>

            <Fade
              in={sessionError}
              timeout={{ enter: 500, exit: 500 }}
              unmountOnExit={true}
              addEndListener={() => {
                window.scrollTo(0, 0);
                setTimeout(() => {
                  setSessionError(false);
                  navigate("/login");
                  logout();
                }, 3000);
              }}
            >
              <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                <AlertTitle sx={{ fontSize: "16px" }}>{t("glossary.alert_fields.session_error.title")}</AlertTitle>
                {t("glossary.alert_fields.session_error.description")}
              </Alert>
            </Fade>
          </Box>

          <Box display="flex" flexDirection="column" alignItems="center" mt="30px">
            <Grid container spacing={4} mt="10px" justifyContent="left">
              {filteredCards.length === 0 ? (
                <Grid item xs={12}>
                  <Alert fullWidth variant="outlined" sx={{ fontSize: "16px" }} severity="info">
                    <AlertTitle sx={{ fontSize: "16px" }}>{t("glossary.alert_fields.not_found.title")}</AlertTitle>
                    {t("glossary.alert_fields.not_found.description")}
                  </Alert>
                </Grid>
              ) : (
                <>
                  {filteredCards.map((card, index) => (
                    <Grid item xs={3} key={index}>
                      <Card style={{ backgroundColor: colors.blueAccent[800] }}>
                        <CardHeader
                          style={{ backgroundColor: colors.blueAccent[600] }}
                          sx={{ fontSize: 18, mb: 1.5 }}
                          component={Typography}
                          title={highlightSearchTerm(card.title, search)}
                        />
                        <CardContent>
                          <Typography variant="h5" component="div"></Typography>
                          <Typography variant="h6">{highlightSearchTerm(card.description, search)}</Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </>
              )}
            </Grid>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Glossary;
