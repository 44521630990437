import { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, TextField, Alert, AlertTitle, Select, MenuItem, Container, Fade, Grid, useTheme, Typography, Avatar } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import Header from "../../components/Header";
import axios from "axios";
import LoadingScreen from "../global/LoadingScreen";
import { AuthContext } from "../../context/authContext";
import { tokens } from "../../theme";
import { useTranslation } from "react-i18next";

const EditAWSUser = () => {
  const { t } = useTranslation();
  const [generalError, setGeneralError] = useState(false);
  const [sessionError, setSessionError] = useState(false);
  const { idUser } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isEdited, setIsEdited] = useState(false);
  const [initialValues, setInitialValues] = useState({});

  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { currentUser, verifyUser, logout } = useContext(AuthContext);
  const [currentLicense, setCurrentLicense] = useState(null);

  const config = {
    headers: {
      "access-token": process.env.REACT_APP_ACCESS_TOKEN,
      "Content-Type": "application/json", // Adjust the content type if needed
    },
    withCredentials: true,
  };

  const userSchema = yup.object().shape({
    first_name: yup.string(),
    last_name: yup.string(),
    email: yup.string().email(t("edit_aws_user.schema.email")),
    user_type: yup.string().required(t("edit_aws_user.schema.user_type")),
  });

  const verifyLicense = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + `licenses/email/${currentUser?.email}`, config);

      if (response.data.success === 0) {
        setCurrentLicense(0);
      } else if (response.data.data[0].time === 0) {
        setCurrentLicense(1);
      } else if (response.data.data[0].active === 1) {
        setCurrentLicense(2);
      }
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
        logout();
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    verifyUser(currentUser);
    if (!currentUser) {
      navigate("/login");
    } else {
      verifyLicense();
      if (currentLicense !== null && currentLicense !== 2) {
        navigate("/login");
      } else if (currentUser.user_type === 1 || currentUser.user_type === 2 || currentUser.user_type === 3) {
        navigate("/");
      }
    }
  }, [currentLicense, currentUser]);

  const style = {
    "& label.Mui-focused": {
      color: colors.primary[100],
      fontSize: "16px",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: colors.primary[100],
      },
    },
    "& .MuiFormHelperText-root": {
      fontSize: "14px",
    },
  };

  const getUser = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + `users/user/${idUser}`, config).then();
      setInitialValues(response.data.data);
      setIsLoading(false);
    } catch (error) {
      setGeneralError(true);
      console.log(error);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  const handleFormSubmit = async (values) => {
    try {
      await axios.patch(process.env.REACT_APP_API_CALL + "users", values, config);
      setIsEdited(true);
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
      }
    }
  };
  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <Box m="20px">
            <Header title={t("edit_aws_user.title")} subtitle={t("edit_aws_user.subtitle")} m="20px" />
          </Box>

          <Grid container xs={1} sm={1} md={1} lg={1} xl={1}>
            <Box
              ml="20px"
              onClick={() => navigate(-1)}
              p="5px 7px 5px 5px"
              display="flex"
              justifyContent="center"
              borderRadius="4px"
              alignItems="center"
              backgroundColor={colors.blueAccent[600]}
              sx={{
                cursor: "pointer",
                textDecoration: "none",
              }}
            >
              <ArrowBackOutlinedIcon
                sx={{
                  textDecoration: "none",
                  color: colors.primary[100],
                  justifyContent: "center",
                }}
              />
              <Typography
                justifyContent="center"
                color={colors.primary[100]}
                sx={{
                  ml: "5px",
                  textDecoration: "none",
                  fontWeight: "bold",
                  justifyContent: "center",
                  marginTop: "1px",
                }}
              >
                {t("general.back_button")}
              </Typography>
            </Box>
          </Grid>

          <Container component="main" maxWidth="xs">
            <Box display="flex" flexDirection="column" alignItems="center" mt="30px">
              <Fade
                in={generalError}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  window.scrollTo(0, 0);
                  setTimeout(() => {
                    setGeneralError(false);
                  }, 3000);
                }}
              >
                <Alert severity="error">
                  <AlertTitle>{t("edit_aws_user.alert_fields.general_error.title")}</AlertTitle>
                  {t("edit_aws_user.alert_fields.general_error.description")}
                </Alert>
              </Fade>

              <Fade
                in={sessionError}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  window.scrollTo(0, 0);
                  setTimeout(() => {
                    setSessionError(false);
                    navigate("/login");
                    logout();
                  }, 3000);
                }}
              >
                <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                  <AlertTitle sx={{ fontSize: "16px" }}>{t("edit_aws_user.alert_fields.session_error.title")}</AlertTitle>
                  {t("edit_aws_user.alert_fields.session_error.description")}
                </Alert>
              </Fade>

              <Fade
                in={isEdited}
                timeout={{ enter: 1000, exit: 1000 }}
                unmountOnExit={true}
                addEndListener={() => {
                  setTimeout(() => {
                    setIsEdited(false);
                  }, 3000);
                }}
              >
                <Alert severity="success">
                  <AlertTitle>{t("edit_aws_user.alert_fields.is_edited.title")}</AlertTitle>
                  {t("edit_aws_user.alert_fields.is_edited.description")}
                </Alert>
              </Fade>

              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>{/* <LoginOutlinedIcon /> */}</Avatar>
              <Grid item xs={12}>
                <Typography component="h1" variant="h2">
                  {t("edit_aws_user.form.title")}
                </Typography>
              </Grid>

              <Formik enableReinitialize onSubmit={handleFormSubmit} initialValues={initialValues} validationSchema={userSchema}>
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                  <form onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={3} mt="10px">
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          multiline
                          variant="filled"
                          type="text"
                          label={t("edit_aws_user.form.first_name")}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.first_name}
                          name="first_name"
                          error={!!touched.first_name && !!errors.first_name}
                          helperText={touched.first_name && errors.first_name}
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          sx={style}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          multiline
                          variant="filled"
                          type="text"
                          label={t("edit_aws_user.form.last_name")}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.last_name}
                          name="last_name"
                          error={!!touched.last_name && !!errors.last_name}
                          helperText={touched.last_name && errors.last_name}
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          sx={style}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          multiline
                          variant="filled"
                          type="text"
                          label={t("edit_aws_user.form.email")}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.email}
                          name="email"
                          error={!!touched.email && !!errors.email}
                          helperText={touched.email && errors.email}
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          sx={style}
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={12} sx={{ maxWidth: "100% !important" }}>
                        <Typography
                          sx={{
                            textDecoration: "none",
                            paddingLeft: "6px",
                            paddingBottom: "5px",
                            justifyContent: "left",
                          }}
                          component="h1"
                          variant="h5"
                        >
                          {t("edit_aws_user.form.user_type.title")}
                        </Typography>
                        <Select
                          fullWidth
                          hiddenLabel
                          variant="filled"
                          labelId="user_type"
                          id="user_type"
                          value={values.user_type}
                          onChange={handleChange("user_type")}
                        >
                          <MenuItem value={3}>{t("edit_aws_user.form.user_type.3")}</MenuItem>
                          <MenuItem value={2}>{t("edit_aws_user.form.user_type.2")}</MenuItem>
                          <MenuItem value={1}>{t("edit_aws_user.form.user_type.1")}</MenuItem>
                          <MenuItem value={0}>{t("edit_aws_user.form.user_type.0")}</MenuItem>
                        </Select>
                      </Grid>

                      <Grid item xs={12} mt="20px">
                        <Box
                          p="5px 7px 5px 5px"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          borderRadius="4px"
                          backgroundColor={colors.blueAccent[600]}
                          sx={{
                            cursor: "pointer",
                            textDecoration: "none",
                          }}
                          onClick={handleSubmit}
                        >
                          <EditOutlinedIcon
                            sx={{
                              textDecoration: "none",
                              color: colors.grey[100],
                              justifyContent: "center",
                            }}
                          />
                          <Typography
                            variant="h4"
                            justifyContent="center"
                            color={colors.grey[100]}
                            sx={{
                              ml: "5px",
                              textDecoration: "none",
                              fontWeight: "bold",
                              justifyContent: "center",
                              marginTop: "1px",
                            }}
                          >
                            {t("edit_aws_user.form.buttons.edit_aws_user")}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
            </Box>
          </Container>
        </>
      )}
    </>
  );
};

export default EditAWSUser;
