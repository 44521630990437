import { useEffect, useState, useContext } from "react";
import { defaults_t } from "../../types";
import "./App.css";
import "./AddProject.css";
import "react-datepicker/dist/react-datepicker.css";
import { useParams, useNavigate, Link } from "react-router-dom";
import SimpleCoolTable from "../../components/SimpleCoolTable";
import CoolTableSim from "../../components/CoolTableSim";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { AuthContext } from "../../context/authContext";
import LoadingScreen from "../global/LoadingScreen";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import LoupeOutlinedIcon from "@mui/icons-material/LoupeOutlined";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DoDisturbAltOutlinedIcon from "@mui/icons-material/DoDisturbAltOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ExpandMoreIconOutlined from "@mui/icons-material/ExpandMoreOutlined";
import {
  Box,
  TextField,
  Typography,
  useTheme,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Grid,
  Fade,
  Alert,
  AlertTitle,
  Container,
  Select,
  MenuItem,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import * as yup from "yup";
import "yup-phone";
import { Formik } from "formik";

function EditSimulation() {
  const { t } = useTranslation();
  const { idSimulation } = useParams();
  const [defaults, setDefaults] = useState<defaults_t | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [isEditedScenario, setIsEditedScenario] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canCreateScenario, setCanCreateScenario] = useState(false);
  const [changeType, setChangeType] = useState(false);
  const [project, setProject] = useState<any>();
  const [scenarios, setScenarios] = useState<any>([]);
  const [generalError, setGeneralError] = useState(false);
  const [sessionError, setSessionError] = useState(false);
  const [open, setOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState();
  const [pressDelete, setPressDelete] = useState(false);
  const [isEliminated, setIsEliminated] = useState(false);
  const [isResidential, setIsResidential] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [emails0, setEmails0] = useState([]);
  const [emails1, setEmails1] = useState([]);
  const [isDuplicated, setIsDuplicated] = useState(false);
  const [idNav, setIdNav] = useState(0);
  const [floorsCount, setFloorsCount] = useState(0);
  const [floorNames, setFloorNames] = useState([]);
  const [pePcLayout, setPePcLayout] = useState<{
    [key: string]: { [key: string]: any };
  }>({});
  const [hide, setHide] = useState(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();

  const { currentUser, verifyUser, logout } = useContext(AuthContext);
  const [currentLicense, setCurrentLicense] = useState(null);

  const config = {
    headers: {
      "access-token": process.env.REACT_APP_ACCESS_TOKEN,
      "Content-Type": "application/json", // Adjust the content type if needed
    },
    withCredentials: true,
  };

  const verifyLicense = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + `licenses/email/${currentUser?.email}`, config);

      if (
        response.data.data[0].type === 0 ||
        response.data.data[0].type === "0" ||
        response.data.data[0].type === 1 ||
        response.data.data[0].type === "1" ||
        response.data.data[0].type === 2 ||
        response.data.data[0].type === "2"
      ) {
        setChangeType(true);
      }

      if (response.data.success === 0) {
        setCurrentLicense(0);
      } else if (response.data.data[0].time === 0) {
        setCurrentLicense(1);
      } else if (response.data.data[0].active === 1) {
        setCurrentLicense(2);
      }
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
        logout();
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    verifyUser(currentUser);
    if (!currentUser) {
      navigate("/login");
    } else {
      verifyLicense();
      if (currentLicense !== null && currentLicense !== 2) {
        navigate("/login");
      }
      // else if (currentUser.user_type === 1 || currentUser.user_type === 2 || currentUser.user_type === 3) {
      //   navigate("/");
      // }
    }
  }, [currentLicense, currentUser]);

  useEffect(() => {
    if (floorNames.length === floorsCount) return;
    const newNames = [...floorNames];
    if (floorNames.length > floorsCount) for (let i = floorNames.length; i > floorsCount; i--) newNames.pop();
    else if (floorNames.length < floorsCount) for (let i = floorsCount; i < floorNames.length; i++) newNames.push("");
    setFloorNames(newNames);
  }, [floorsCount]);

  const handleClickOpen = (id) => {
    setOpen(true);
    setIdToDelete(id);
  };

  const handleClose = () => {
    deleteScenario(idToDelete);
    setIdToDelete(null);
    setOpen(false);
    return;
  };

  const handleCloseDialog = () => {
    closeMaybe();
    setOpen(false);
  };

  const pressDeleted = () => {
    setPressDelete(true);
  };

  const closeMaybe = async () => {
    try {
      await axios.get(process.env.REACT_APP_API_CALL + "users", config);
      setOpen(false);
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
      }
    }
  };

  const getProject = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(process.env.REACT_APP_API_CALL + `simulation/${idSimulation}`, config).then();
      setProject(response.data.data);
      setFloorsCount(response.data.data.floors_count);
      setFloorNames(response.data.data.floors_names);
      setPePcLayout(response.data.data["pepc_layout"]);

      if (response.data.data.building_type === 0) {
        setIsResidential(true);
      }

      // CÓDIGO PARA QUEM PODE EDITAR SIMULAÇÕES
      if (
        currentUser &&
        currentUser.user_type === 2 &&
        response.data.data.email === currentUser.email &&
        response.data.data.isShared === 1
      ) {
        setCanEdit(false);
        setCanCreateScenario(true);
      } else if (
        currentUser &&
        currentUser.user_type === 2 &&
        response.data.data.email === currentUser.email &&
        response.data.data.isShared === 0
      ) {
        navigate("/");
      } else if (currentUser && response.data.data.email_comercial === currentUser.email) {
        setCanEdit(true);
        setCanCreateScenario(true);
      } else if (currentUser && currentUser.user_type === 0) {
        setCanEdit(true);
        setCanCreateScenario(true);
      } else {
        setCanEdit(false);
        setCanCreateScenario(false);
      }

      const response2 = await axios.get(process.env.REACT_APP_API_CALL + `scenario/simulation/${idSimulation}`, config);
      setScenarios(response2.data.data);
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
      }
    }
  };

  const deleteScenario = async (id) => {
    try {
      await axios.delete(process.env.REACT_APP_API_CALL + `scenario/${id}`, config);
      getProject();
      setOpen(false);
      setIsLoading(false);
      setIsEliminated(true);
      window.scrollTo(0, 0);
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
      }
    }
  };

  const getEmails = async () => {
    try {
      const response1 = await axios.get(process.env.REACT_APP_API_CALL + "users/emails/0", config);
      setEmails0(response1.data.data);
      const response2 = await axios.get(process.env.REACT_APP_API_CALL + "users/emails/1", config);
      setEmails1(response2.data.data);
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
      }
    }
  };

  let array = [];
  if (emails0 === undefined || emails1 === undefined) {
    console.log("undefined");
  } else {
    for (let i = 0; i < emails0.length; i++) {
      array.push(emails0[i].email);
    }

    for (let i = 0; i < emails1.length; i++) {
      array.push(emails1[i].email);
    }
  }

  useEffect(() => {
    getDefaults();
    getEmails();
    getProject();
    setFloorsCount(project?.floors_count);
  }, []);

  const handleFormSubmit = async (values) => {
    if (
      values.client_name === "" ||
      values.email_comercial === "" ||
      values.total_habitants === "" ||
      values.total_habitants === 0 ||
      values.total_habitants === undefined ||
      values.total_habitants === null
    ) {
      window.scrollTo(0, 0);
      setIsEmpty(true);
      return;
    } else {
      try {
        await axios.patch(process.env.REACT_APP_API_CALL + "simulation", values, config);
        window.scrollTo(0, 0);
        setIsEdited(true);
        setHide(true);
      } catch (error) {
        if (error.response.data.auth === false) {
          setSessionError(true);
        } else {
          setGeneralError(true);
          console.log(error);
        }
      }
    }
  };

  const handleFormDuplicate = async (values) => {
    setIsLoading(true);
    if (
      values.client_name === "" ||
      values.email_comercial === "" ||
      values.total_habitants === "" ||
      values.total_habitants === 0 ||
      values.total_habitants === undefined ||
      values.total_habitants === null
    ) {
      setIsLoading(false);
      window.scrollTo(0, 0);
      setIsEmpty(true);
      return;
    } else {
      try {
        const response = await axios.post(process.env.REACT_APP_API_CALL + "simulation", values, config);

        const requests = [];

        for (let i = 0; i < scenarios?.length; i++) {
          let valores = {
            id_simulation: response.data.data.insertId,
            scenario_name: scenarios[i].scenario_name,
            email: scenarios[i].email,
            email_comercial: scenarios[i].email_comercial,
            date: scenarios[i].date,
            editor_image: scenarios[i].editor_image,
            pepc_connections: scenarios[i].pepc_connections,
            pepc_layout2: scenarios[i].pepc_layout2,
          };
          const additionalResponse = axios.post(
            process.env.REACT_APP_API_CALL + "scenario/duplicate",
            valores, // Replace with your actual values
            config
          );
          requests.push(additionalResponse);
        }

        // Execute all additional requests in parallel
        const additionalResponses = await Promise.all(requests);

        window.scrollTo(0, 0);
        setIsDuplicated(true);
        setIdNav(response.data.data.insertId);
        setIsLoading(false);
        setHide(true);
      } catch (error) {
        if (error.response.data.auth === false) {
          setSessionError(true);
        } else {
          setGeneralError(true);
          console.log(error);
        }
      }
    }
  };

  const getDefaults = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(process.env.REACT_APP_API_CALL + "defaults", config).then();
      setDefaults(response.data.data[0].default_values);
      setIsLoading(false);
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
      }
    }
  };

  const style = {
    "& label.Mui-focused": {
      color: colors.primary[100],
      fontSize: "16px",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: colors.primary[100],
      },
    },
    "& .MuiFormHelperText-root": {
      fontSize: "14px",
    },
  };

  // const styleRequired = {
  //   "& label.Mui-focused": {
  //     color: colors.primary[100],
  //     fontSize: "16px",
  //     fontWeight: "bold",
  //   },
  //   "& .MuiOutlinedInput-root": {
  //     "&.Mui-focused fieldset": {
  //       borderColor: colors.primary[100],
  //       fontWeight: "bold",
  //     },
  //   },
  //   "& .MuiFormHelperText-root": {
  //     fontSize: "14px",
  //     fontWeight: "bold",
  //   },
  //   backgroundColor: colors.blueAccent[1000],
  //   fontWeight: "bold",
  // };

  const postalCodeRegExp = /^[1-9][0-9]{3}-[0-9]{3}$/;

  const simulationSchema = yup.object().shape(
    {
      email_comercial: yup.string().email(t("edit_simulation.schema.email_comercial")),
      client_name: yup.string().required(t("edit_simulation.schema.client_name")),
      nif: yup
        .number()
        .test("len", t("edit_simulation.schema.nif.test"), (val) => !val || val.toString().length === 8)
        .typeError(t("edit_simulation.schema.nif.type_error"))
        .positive(t("edit_simulation.schema.nif.positive")),
      address: yup.string(),
      postal_code: yup.string().matches(postalCodeRegExp, t("edit_simulation.schema.postal_code")),
      phone: yup.string().when("phone", {
        is: (value) => value?.length > 0,
        then: yup.string().phone("PT", true, t("edit_simulation.schema.phone")),
        otherwise: yup.string(),
      }),
      solar_heater_water: yup
        .number()
        .typeError(t("edit_simulation.schema.solar_heater_water.type_error"))
        .positive(t("edit_simulation.schema.solar_heater_water.positive")),
      total_habitants: isResidential
        ? yup
            .number()
            .required(t("edit_simulation.schema.total_habitants.required"))
            .typeError(t("edit_simulation.schema.total_habitants.type_error"))
            .positive(t("edit_simulation.schema.total_habitants.positive"))
        : yup.string(),
      pe_usage: isResidential
        ? yup.number().typeError(t("edit_simulation.schema.pe_usage.type_error")).positive(t("edit_simulation.schema.pe_usage.positive"))
        : yup.string(),
      running_time: yup
        .number()
        .required(t("edit_simulation.schema.running_time.required"))
        .typeError(t("edit_simulation.schema.running_time.type_error"))
        .positive(t("edit_simulation.schema.running_time.positive"))
        .max(24, t("edit_simulation.schema.running_time.max"))
        .min(4, t("edit_simulation.schema.running_time.min")),
      running_time_hoterway: yup
        .number()
        .required(t("edit_simulation.schema.running_time_hoterway.required"))
        .typeError(t("edit_simulation.schema.running_time_hoterway.type_error"))
        .positive(t("edit_simulation.schema.running_time_hoterway.positive"))
        .max(2, t("edit_simulation.schema.running_time_hoterway.max"))
        .min(0.5, t("edit_simulation.schema.running_time_hoterway.min")),
      volume_aqs: yup
        .number()
        .required(t("edit_simulation.schema.volume_aqs.required"))
        .typeError(t("edit_simulation.schema.volume_aqs.type_error"))
        .positive(t("edit_simulation.schema.volume_aqs.positive")),
      water_consumption: yup
        .number()
        .typeError(t("edit_simulation.schema.water_consumption.type_error"))
        .positive(t("edit_simulation.schema.water_consumption.positive")),
      price_thermal: yup.number().typeError(t("edit_simulation.schema.price_thermal.type_error")).positive(t("edit_simulation.schema.price_thermal.positive")),
      price_energy: yup.number().typeError(t("edit_simulation.schema.price_energy.type_error")).positive(t("edit_simulation.schema.price_energy.positive")),
      water_in_temp: yup.number().typeError(t("edit_simulation.schema.water_in_temp.type_error")).positive(t("edit_simulation.schema.water_in_temp.positive")),
      hot_water_temp: yup.number().typeError(t("edit_simulation.schema.hot_water_temp.type_error")).positive(t("edit_simulation.schema.hot_water_temp.positive")),
      average_power: yup.number().typeError(t("edit_simulation.schema.average_power.type_error")).positive(t("edit_simulation.schema.average_power.positive")),
      water_price: isResidential
        ? yup.number().typeError(t("edit_simulation.schema.water_price.type_error")).positive(t("edit_simulation.schema.water_price.positive"))
        : yup.string(),
      building_area: isResidential
        ? yup.number().typeError(t("edit_simulation.schema.building_area.type_error")).positive(t("edit_simulation.schema.building_area.positive"))
        : yup.string(),
      certificate_class: isResidential
        ? yup.number().typeError(t("edit_simulation.schema.certificate_class.type_error")).positive(t("edit_simulation.schema.certificate_class.positive"))
        : yup.string(),
      building_type: yup.number().typeError(t("edit_simulation.schema.building_type.type_error")).positive(t("edit_simulation.schema.building_type.positive")),
      obs: yup.string(),
      house_parts_count: yup
        .number()
        .required(t("edit_simulation.schema.house_parts_count.required"))
        .typeError(t("edit_simulation.schema.house_parts_count.type_error"))
        .positive(t("edit_simulation.schema.house_parts_count.positive")),
      floors_count: yup
        .number()
        .required(t("edit_simulation.schema.floors_count.required"))
        .typeError(t("edit_simulation.schema.floors_count.type_error"))
        .min(0, t("edit_simulation.schema.floors_count.min")),
    },
    [["phone", "phone"]]
  );

  const columns = [
    {
      field: "id",
      headerName: t("edit_simulation.table_fields.id"),
      minWidth: 80,
      maxWidth: 80,
      flex: 1,
    },
    {
      field: "id_simulation",
      headerName: t("edit_simulation.table_fields.id_simulation"),
      minWidth: 100,
      maxWidth: 100,
      flex: 1,
    },
    {
      field: "scenario_name",
      headerName: t("edit_simulation.table_fields.scenario_name"),
      minWidth: 100,
      maxWidth: 150,
      flex: 1,
    },
    {
      field: "date",
      headerName: t("edit_simulation.table_fields.date"),
      minWidth: 125,
      maxWidth: 1125,
      flex: 1,
    },
    {
      field: "edit",
      headerName: "",
      minWidth: 95,
      maxWidth: 95,
      flex: 1,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      sortable: false,
      renderCell: (params) => {
        if ((currentUser && currentUser.user_type === 0) || (currentUser && params.row.email_comercial === currentUser.email)) {
          return (
            <Box
              width="100%"
              m="0 auto"
              p="5px"
              display="flex"
              justifyContent="center"
              borderRadius="4px"
              backgroundColor={colors.greenAccent[600]}
              component={Link}
              to={`/scenarios/${params.row.id}`}
              sx={{
                cursor: "pointer",
                textDecoration: "none",
              }}
            >
              <PreviewOutlinedIcon sx={{ textDecoration: "none", color: colors.grey[100] }} />
              <Typography color={colors.grey[100]} sx={{ ml: "5px", textDecoration: "none", mt: "1px" }}>
                {t("edit_simulation.table_fields.edit.view")}
              </Typography>
            </Box>
          );
        } else {
          return (
            <Box
              width="100%"
              p="5px"
              display="flex"
              justifyContent="center"
              borderRadius="4px"
              backgroundColor={colors.greenAccent[800]}
              component={Link}
              to={`/scenarios/${params.row.id}`}
              sx={{
                cursor: "pointer",
                textDecoration: "none",
              }}
            >
              <PreviewOutlinedIcon sx={{ textDecoration: "none", color: colors.grey[100] }} />
              <Typography color={colors.grey[100]} sx={{ ml: "5px", textDecoration: "none" }}>
                {t("edit_simulation.table_fields.edit.view")}
              </Typography>
            </Box>
          );
        }
      },
    },
    {
      field: "report",
      headerName: "",
      minWidth: 120,
      maxWidth: 120,
      flex: 1,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box
            width="100%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            borderRadius="4px"
            backgroundColor={colors.blueAccent[600]}
            component={Link}
            to={`/scenario_report/${params.row.id}`}
            sx={{
              cursor: "pointer",
              textDecoration: "none",
            }}
          >
            <PreviewOutlinedIcon sx={{ textDecoration: "none", color: colors.grey[100] }} />
            <Typography color={colors.grey[100]} sx={{ ml: "5px", textDecoration: "none", mt: "1px" }}>
              {t("edit_simulation.table_fields.report")}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "delete",
      headerName: "",
      minWidth: 105,
      maxWidth: 105,
      flex: 1,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      sortable: false,
      renderCell: (params) => {
        if ((currentUser && currentUser.user_type === 0) || (currentUser && params.row.email_comercial === currentUser.email)) {
          return (
            <Box
              width="100%"
              m="0 auto"
              p="5px"
              display="flex"
              justifyContent="center"
              borderRadius="4px"
              backgroundColor={colors.redAccent[600]}
              onClick={() => {
                handleClickOpen(params.row.id);
              }}
              sx={{ cursor: "pointer" }}
            >
              <DeleteOutlineOutlinedIcon />
              <Typography color={colors.grey[100]} sx={{ ml: "5px", textDecoration: "none" }}>
                {t("edit_simulation.table_fields.delete")}
              </Typography>

              <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                  <Typography
                    justifyContent="center"
                    color={colors.primary[100]}
                    sx={{
                      textDecoration: "none",
                      justifyContent: "center",
                      mt: "5px",
                    }}
                  >
                    {t("edit_simulation.table_fields.delete_warnings.alert_dialog_title")}
                  </Typography>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <Typography
                      justifyContent="center"
                      color={colors.primary[100]}
                      sx={{
                        textDecoration: "none",
                        fontWeight: "bold",
                        justifyContent: "center",
                      }}
                    >
                      {t("edit_simulation.table_fields.delete_warnings.alert_dialog_description")}
                    </Typography>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Grid item>
                    <Box
                      m="0 auto"
                      p="5px 7px 5px 5px"
                      display="flex"
                      justifyContent="center"
                      borderRadius="4px"
                      alignItems="center"
                      backgroundColor={colors.redAccent[600]}
                      sx={{
                        cursor: "pointer",
                        textDecoration: "none",
                      }}
                      onClick={handleCloseDialog}
                    >
                      <CloseOutlinedIcon
                        sx={{
                          textDecoration: "none",
                          color: colors.primary[100],
                          justifyContent: "center",
                        }}
                      />
                      <Typography
                        justifyContent="center"
                        color={colors.primary[100]}
                        sx={{
                          ml: "5px",
                          textDecoration: "none",
                          fontWeight: "bold",
                          justifyContent: "center",
                          marginTop: "1px",
                        }}
                      >
                        {t("edit_simulation.table_fields.delete_warnings.alert_deny")}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item>
                    <Box
                      m="0 auto"
                      p="5px 7px 5px 5px"
                      display="flex"
                      justifyContent="center"
                      borderRadius="4px"
                      alignItems="center"
                      backgroundColor={colors.greenAccent[600]}
                      sx={{
                        cursor: "pointer",
                        textDecoration: "none",
                      }}
                      onClick={handleClose}
                    >
                      <CheckOutlinedIcon
                        sx={{
                          textDecoration: "none",
                          color: colors.primary[100],
                          justifyContent: "center",
                        }}
                      />
                      <Typography
                        justifyContent="center"
                        color={colors.primary[100]}
                        sx={{
                          ml: "5px",
                          textDecoration: "none",
                          fontWeight: "bold",
                          justifyContent: "center",
                          marginTop: "1px",
                        }}
                      >
                        {t("edit_simulation.table_fields.delete_warnings.alert_confirm")}
                      </Typography>
                    </Box>
                  </Grid>
                </DialogActions>
              </Dialog>
            </Box>
          );
        } else {
          return (
            <Box
              width="100%"
              m="0 auto"
              p="5px"
              display="flex"
              justifyContent="center"
              borderRadius="4px"
              backgroundColor={colors.grey[800]}
              sx={{ cursor: "not-allowed" }}
              onClick={pressDeleted}
            >
              <DoDisturbAltOutlinedIcon />
              <Typography color={colors.grey[100]} sx={{ ml: "5px", textDecoration: "none" }}>
                {t("edit_simulation.table_fields.delete")}
              </Typography>
            </Box>
          );
        }
      },
    },
  ];

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          {canEdit || (currentUser && currentUser.user_type === 0) ? (
            <Box m="20px">
              <Header title={t("edit_simulation.title1")} subtitle={t("edit_simulation.subtitle1")} />
            </Box>
          ) : (
            <Box m="20px">
              <Header
                title={t("edit_simulation.title2")}
                subtitle={t("edit_simulation.subtitle2")}
              />
            </Box>
          )}

          <Grid container xs={1} sm={1} md={1} lg={1} xl={1}>
            <Box
              ml="20px"
              onClick={() => navigate(-1)}
              p="5px 7px 5px 5px"
              display="flex"
              justifyContent="center"
              borderRadius="4px"
              alignItems="center"
              backgroundColor={colors.blueAccent[600]}
              sx={{
                cursor: "pointer",
                textDecoration: "none",
              }}
            >
              <ArrowBackOutlinedIcon
                sx={{
                  textDecoration: "none",
                  color: colors.primary[100],
                  justifyContent: "center",
                }}
              />
              <Typography
                justifyContent="center"
                color={colors.primary[100]}
                sx={{
                  ml: "5px",
                  textDecoration: "none",
                  fontWeight: "bold",
                  justifyContent: "center",
                  marginTop: "1px",
                }}
              >
                {t("general.back_button")}
              </Typography>
            </Box>
          </Grid>

          {project && project !== undefined && isLoading === false ? (
            <>
              <Container component="main" maxWidth="100%">
                <Box display="flex" flexDirection="column" alignItems="center" mt="30px">
                  <Fade
                    in={generalError}
                    timeout={{ enter: 500, exit: 500 }}
                    unmountOnExit={true}
                    addEndListener={() => {
                      window.scrollTo(0, 0);
                      setTimeout(() => {
                        setGeneralError(false);
                      }, 3000);
                    }}
                  >
                    <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                      <AlertTitle sx={{ fontSize: "16px" }}>{t("edit_simulation.alert_fields.general_error.title")}</AlertTitle>
                      {t("edit_simulation.alert_fields.general_error.description")}
                    </Alert>
                  </Fade>

                  <Fade
                    in={sessionError}
                    timeout={{ enter: 500, exit: 500 }}
                    unmountOnExit={true}
                    addEndListener={() => {
                      window.scrollTo(0, 0);
                      setTimeout(() => {
                        setSessionError(false);
                        navigate("/login");
                        logout();
                      }, 3000);
                    }}
                  >
                    <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                      <AlertTitle sx={{ fontSize: "16px" }}>{t("edit_simulation.alert_fields.session_error.title")}</AlertTitle>{t("edit_simulation.alert_fields.session_error.description")}
                    </Alert>
                  </Fade>

                  <Fade
                    in={isEmpty}
                    timeout={{ enter: 500, exit: 500 }}
                    unmountOnExit={true}
                    addEndListener={() => {
                      window.scrollTo(0, 0);
                      setTimeout(() => {
                        setIsEmpty(false);
                      }, 3000);
                    }}
                  >
                    <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                      <AlertTitle sx={{ fontSize: "16px" }}>{t("edit_simulation.alert_fields.is_empty.title")}</AlertTitle>{t("edit_simulation.alert_fields.is_empty.description")}
                    </Alert>
                  </Fade>

                  <Fade
                    in={isEdited}
                    timeout={{ enter: 1000, exit: 1000 }}
                    unmountOnExit={true}
                    addEndListener={() => {
                      setTimeout(() => {
                        setIsEdited(false);
                        navigate(0);
                      }, 3000);
                    }}
                  >
                    <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="success">
                      <AlertTitle sx={{ fontSize: "16px" }}>{t("edit_simulation.alert_fields.is_edited.title")}</AlertTitle>
                      {t("edit_simulation.alert_fields.is_edited.description")}
                    </Alert>
                  </Fade>

                  <Fade
                    in={isDuplicated}
                    timeout={{ enter: 1000, exit: 1000 }}
                    unmountOnExit={true}
                    addEndListener={() => {
                      setTimeout(() => {
                        setIsDuplicated(false);
                        if (idNav !== 0) {
                          navigate("/simulations/" + idNav);
                          window.location.reload();
                        }
                      }, 3000);
                    }}
                  >
                    <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="success">
                      <AlertTitle sx={{ fontSize: "16px" }}>{t("edit_simulation.alert_fields.is_duplicated.title")}</AlertTitle>
                      {t("edit_simulation.alert_fields.is_duplicated.description")}
                    </Alert>
                  </Fade>

                  <Fade
                    in={isEditedScenario}
                    timeout={{ enter: 1000, exit: 1000 }}
                    unmountOnExit={true}
                    addEndListener={() => {
                      window.scrollTo(0, 0);
                      setTimeout(() => {
                        setIsEditedScenario(false);
                        navigate(`/create_scenario/${idSimulation}`);
                      }, 4000);
                    }}
                  >
                    <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="info">
                      <AlertTitle sx={{ fontSize: "16px" }}>{t("edit_simulation.alert_fields.is_edited_scenario.title")}</AlertTitle>{t("edit_simulation.alert_fields.is_edited_scenario.description")}
                    </Alert>
                  </Fade>
                  <Fade
                    in={isEliminated}
                    timeout={{ enter: 1000, exit: 1000 }}
                    unmountOnExit={true}
                    addEndListener={() => {
                      window.scrollTo(0, 0);
                      setTimeout(() => {
                        setIsEliminated(false);
                      }, 3000);
                    }}
                  >
                    <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="success">
                      <AlertTitle sx={{ fontSize: "16px" }}>{t("edit_simulation.alert_fields.is_eliminated.title")}</AlertTitle>
                      {t("edit_simulation.alert_fields.is_eliminated.description")}
                    </Alert>
                  </Fade>

                  {hide === false ? (
                    <Formik
                      // enableReinitialize={true}
                      onSubmit={handleFormSubmit}
                      initialValues={project}
                      validationSchema={simulationSchema}
                      validateOnChange={false}
                      validateOnBlur={true}
                    >
                      {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                          <Grid container spacing={4} mt="10px" justifyContent="left">
                            <Grid item xs={12}>
                              <Accordion sx={{ backgroundColor: colors.primary[600], borderRadius: "50px" }}>
                                <AccordionSummary
                                  sx={{ backgroundColor: colors.greenAccent[700] }}
                                  expandIcon={<ExpandMoreIconOutlined />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography component="h1" variant="h3">
                                    {t("edit_simulation.form.title1")}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Grid container spacing={4} mt="10px" justifyContent="left">
                                    <Grid item xs={12} sm={6} md={6} lg={3.5} xl={1}>
                                      <TextField
                                        fullWidth
                                        multiline
                                        variant="filled"
                                        type="number"
                                        label={t("edit_simulation.form.id")}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.id}
                                        name="id"
                                        error={!!touched.id && !!errors.id}
                                        helperText={touched.id && errors.id}
                                        inputProps={{ style: { fontSize: 16 } }}
                                        InputLabelProps={{ style: { fontSize: 16 } }}
                                        sx={style}
                                        disabled={true}
                                      />
                                    </Grid>

                                    {currentUser && currentUser.user_type === 0 ? (
                                      <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                        <Typography
                                          sx={{
                                            textDecoration: "none",
                                            paddingLeft: "6px",
                                            marginTop: "-22px",
                                            justifyContent: "left",
                                          }}
                                          component="h1"
                                          variant="h6"
                                        >
                                          {t("edit_simulation.form.email_comercial")}
                                        </Typography>
                                        <Select
                                          fullWidth
                                          hiddenLabel
                                          label={t("manage_project.client.email_comercial")}
                                          variant="filled"
                                          labelId="email_comercial"
                                          id="email_comercial"
                                          value={values.email_comercial}
                                          onChange={handleChange("email_comercial")}
                                          sx={{
                                            fontSize: "16px",
                                            height: "94%",
                                          }}
                                        >
                                          {array.map((option) => (
                                            <MenuItem value={option} sx={{ fontSize: "16px" }}>
                                              {option}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </Grid>
                                    ) : (
                                      <Grid item xs={12} sm={12} md={6} lg={4} xl={2.5}>
                                        <TextField
                                          fullWidth
                                          multiline
                                          variant="filled"
                                          type="text"
                                          label={t("edit_simulation.form.email_comercial")}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          value={values.email_comercial}
                                          name="email_comercial"
                                          error={!!touched.email_comercial && !!errors.email_comercial}
                                          helperText={touched.email_comercial && errors.email_comercial}
                                          inputProps={{ style: { fontSize: 16 } }}
                                          InputLabelProps={{ style: { fontSize: 16 } }}
                                          sx={style}
                                          disabled={true}
                                        />
                                      </Grid>
                                    )}

                                    <Grid item xs={12} sm={6} md={6} lg={3.5} xl={1.5}>
                                      <TextField
                                        fullWidth
                                        multiline
                                        variant="filled"
                                        type="date"
                                        label={t("edit_simulation.form.date")}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.date}
                                        name="date"
                                        error={!!touched.date && !!errors.date}
                                        helperText={touched.date && errors.date}
                                        inputProps={{ style: { fontSize: 16 } }}
                                        InputLabelProps={{ style: { fontSize: 16 } }}
                                        sx={style}
                                        disabled={true}
                                      />
                                    </Grid>

                                    <Grid item xs={6} sm={6} md={3.5} lg={4} xl={1.5}>
                                      <Typography
                                        sx={{
                                          textDecoration: "none",
                                          marginTop: "-27px",
                                          paddingLeft: "6px",
                                          paddingBottom: "5px",
                                          justifyContent: "left",
                                        }}
                                        component="h1"
                                        variant="h5"
                                      >
                                        {t("edit_simulation.form.state.title")}
                                      </Typography>
                                      <Select
                                        fullWidth
                                        hiddenLabel
                                        variant="filled"
                                        labelId="state"
                                        id="state"
                                        value={values.state}
                                        onChange={handleChange("state")}
                                        sx={{
                                          fontSize: "15px",
                                        }}
                                        disabled={!canEdit}
                                      >
                                        <MenuItem value={0} sx={{ fontSize: "16px" }}>
                                          {t("edit_simulation.form.state.0")}
                                        </MenuItem>
                                        <MenuItem value={1} sx={{ fontSize: "16px" }}>
                                          {t("edit_simulation.form.state.1")}
                                        </MenuItem>
                                        <MenuItem value={2} sx={{ fontSize: "16px" }}>
                                          {t("edit_simulation.form.state.2")}
                                        </MenuItem>
                                        <MenuItem value={3} sx={{ fontSize: "16px" }}>
                                          {t("edit_simulation.form.state.3")}
                                        </MenuItem>
                                        <MenuItem value={4} sx={{ fontSize: "16px" }}>
                                          {t("edit_simulation.form.state.4")}
                                        </MenuItem>
                                      </Select>
                                    </Grid>

                                    {currentUser.user_type !== 2 && (
                                      <Grid item xs={6} sm={6} md={3.5} lg={4} xl={2}>
                                        <Typography
                                          sx={{
                                            textDecoration: "none",
                                            paddingLeft: "6px",
                                            paddingBottom: "5px",
                                            justifyContent: "left",
                                            marginTop: "-27px",
                                          }}
                                          component="h1"
                                          variant="h5"
                                        >
                                          {t("edit_simulation.form.is_shared.title")}
                                        </Typography>
                                        <Select
                                          fullWidth
                                          hiddenLabel
                                          variant="filled"
                                          labelId="isShared"
                                          id="isShared"
                                          value={values.isShared}
                                          onChange={handleChange("isShared")}
                                          sx={{ fontSize: "15px" }}
                                          disabled={!canEdit}
                                        >
                                          <MenuItem value={1} sx={{ fontSize: "16px" }}>
                                            {t("edit_simulation.form.is_shared.confirm")}
                                          </MenuItem>
                                          <MenuItem value={0} sx={{ fontSize: "16px" }}>
                                            {t("edit_simulation.form.is_shared.deny")}
                                          </MenuItem>
                                        </Select>
                                      </Grid>
                                    )}
                                  </Grid>
                                </AccordionDetails>
                              </Accordion>
                            </Grid>

                            <Grid item xs={12}>
                              <Accordion sx={{ backgroundColor: colors.primary[600], borderRadius: "50px" }}>
                                <AccordionSummary
                                  sx={{ backgroundColor: colors.greenAccent[700] }}
                                  expandIcon={<ExpandMoreIconOutlined />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography component="h1" variant="h3">
                                    {t("edit_simulation.form.title2")}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Grid container spacing={4} mt="10px" justifyContent="left">
                                    <Grid item xs={12} sm={6} md={6} lg={3.5} xl={3}>
                                      <TextField
                                        fullWidth
                                        multiline
                                        variant="filled"
                                        type="text"
                                        label={t("edit_simulation.form.client_name")}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.client_name}
                                        name="client_name"
                                        error={!!touched.client_name && !!errors.client_name}
                                        helperText={touched.client_name && errors.client_name}
                                        inputProps={{ style: { fontSize: 16 } }}
                                        InputLabelProps={{ style: { fontSize: 16 } }}
                                        sx={style}
                                        disabled={!canEdit}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2.5}>
                                      <TextField
                                        fullWidth
                                        multiline
                                        variant="filled"
                                        type="text"
                                        label={t("edit_simulation.form.email")}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.email}
                                        name="email"
                                        error={!!touched.email && !!errors.email}
                                        helperText={touched.email && errors.email}
                                        inputProps={{ style: { fontSize: 16 } }}
                                        InputLabelProps={{ style: { fontSize: 16 } }}
                                        sx={style}
                                        disabled={!canEdit}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={3.5} xl={1.5}>
                                      <TextField
                                        fullWidth
                                        multiline
                                        variant="filled"
                                        type="text"
                                        label={t("edit_simulation.form.nif")}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.nif}
                                        name="nif"
                                        error={!!touched.nif && !!errors.nif}
                                        helperText={touched.nif && errors.nif}
                                        inputProps={{ style: { fontSize: 16 } }}
                                        InputLabelProps={{ style: { fontSize: 16 } }}
                                        sx={style}
                                        disabled={!canEdit}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={3.5} xl={2}>
                                      <TextField
                                        fullWidth
                                        multiline
                                        variant="filled"
                                        type="text"
                                        label={t("edit_simulation.form.address")}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.address}
                                        name="address"
                                        error={!!touched.address && !!errors.address}
                                        helperText={touched.address && errors.address}
                                        inputProps={{ style: { fontSize: 16 } }}
                                        InputLabelProps={{ style: { fontSize: 16 } }}
                                        sx={style}
                                        disabled={!canEdit}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={3.5} xl={1.5}>
                                      <TextField
                                        fullWidth
                                        multiline
                                        variant="filled"
                                        type="text"
                                        label={t("edit_simulation.form.postal_code")}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.postal_code}
                                        name="postal_code"
                                        error={!!touched.postal_code && !!errors.postal_code}
                                        helperText={touched.postal_code && errors.postal_code}
                                        inputProps={{ style: { fontSize: 16 } }}
                                        InputLabelProps={{ style: { fontSize: 16 } }}
                                        sx={style}
                                        disabled={!canEdit}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={3.5} xl={1.5}>
                                      <TextField
                                        fullWidth
                                        multiline
                                        variant="filled"
                                        type="text"
                                        label={t("edit_simulation.form.phone")}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.phone}
                                        name="phone"
                                        error={!!touched.phone && !!errors.phone}
                                        helperText={touched.phone && errors.phone}
                                        inputProps={{ style: { fontSize: 16 } }}
                                        InputLabelProps={{ style: { fontSize: 16 } }}
                                        sx={style}
                                        disabled={!canEdit}
                                      />
                                    </Grid>
                                  </Grid>
                                </AccordionDetails>
                              </Accordion>
                            </Grid>

                            <Grid item xs={12}>
                              <Accordion sx={{ backgroundColor: colors.primary[600], borderRadius: "50px" }}>
                                <AccordionSummary
                                  sx={{ backgroundColor: colors.greenAccent[700] }}
                                  expandIcon={<ExpandMoreIconOutlined />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography component="h1" variant="h3">
                                    {t("edit_simulation.form.title3")}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Grid container spacing={4} mt="10px" justifyContent="left">
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                      <TextField
                                        id="filled-number"
                                        fullWidth
                                        multiline
                                        variant="filled"
                                        type="number"
                                        label={t("edit_simulation.form.house_parts_count")}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                          handleChange(e);

                                          if (parseInt(e.target.value) > 0) {
                                            let updatePePcLayout = Object.keys(pePcLayout).length - parseInt(e.target.value);
                                            if (updatePePcLayout !== 0) {
                                              let pepc_layout_copy = { ...pePcLayout };
                                              for (let i = 0; i < Math.abs(updatePePcLayout); i++) {
                                                if (updatePePcLayout > 0)
                                                  delete pepc_layout_copy["div_" + (Object.keys(pePcLayout).length - i - 1)];
                                                else {
                                                  pepc_layout_copy["div_" + (Object.keys(pePcLayout).length + i)] = {};
                                                  pepc_layout_copy["div_" + (Object.keys(pePcLayout).length + i)]["name"] = "";
                                                  pepc_layout_copy["div_" + (Object.keys(pePcLayout).length + i)]["nickname"] = "";
                                                  pepc_layout_copy["div_" + (Object.keys(pePcLayout).length + i)]["caudal_minimo"] = "";
                                                  pepc_layout_copy["div_" + (Object.keys(pePcLayout).length + i)]["caudal_total"] = "";
                                                  pepc_layout_copy["div_" + (Object.keys(pePcLayout).length + i)]["hoterway"] = "0";
                                                }
                                              }
                                              setPePcLayout(pepc_layout_copy);
                                            }

                                            setProject({
                                              ...project,
                                              house_parts_count: parseInt(e.target.value),
                                            });
                                          } else {
                                            setProject({
                                              ...project,
                                              house_parts_count: 0,
                                            });
                                          }
                                        }}
                                        value={values.house_parts_count}
                                        name="house_parts_count"
                                        error={!!touched.house_parts_count && !!errors.house_parts_count}
                                        helperText={touched.house_parts_count && errors.house_parts_count}
                                        inputProps={{ style: { fontSize: 16 }, type: "number" }}
                                        InputLabelProps={{ style: { fontSize: 16 } }}
                                        sx={style}
                                      />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={1.5}>
                                      <TextField
                                        fullWidth
                                        multiline
                                        variant="filled"
                                        type="number"
                                        label={t("edit_simulation.form.floors_count")}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                          handleChange(e);
                                          let value = parseInt(e.target.value);
                                          if (value > 0) setFloorsCount(value);
                                          if (value === 0) setFloorsCount(0);
                                        }}
                                        value={values.floors_count}
                                        name="floors_count"
                                        error={!!touched.floors_count && !!errors.floors_count}
                                        helperText={touched.floors_count && errors.floors_count}
                                        inputProps={{ style: { fontSize: 16 }, type: "number" }}
                                        InputLabelProps={{ style: { fontSize: 16 } }}
                                        sx={style}
                                      />
                                    </Grid>
                                    {changeType === true && (
                                      <Grid item xs={12} sm={6} md={6} lg={3.5} xl={1.5}>
                                        <Typography
                                          sx={{
                                            textDecoration: "none",
                                            marginTop: "-27px",
                                            paddingLeft: "6px",
                                            paddingBottom: "5px",
                                            justifyContent: "left",
                                          }}
                                          component="h1"
                                          variant="h5"
                                        >
                                          {t("edit_simulation.form.building_type.title")}
                                        </Typography>
                                        <Select
                                          fullWidth
                                          hiddenLabel
                                          variant="filled"
                                          labelId="building_type"
                                          id="building_type"
                                          value={values.building_type}
                                          onChange={(event) => {
                                            handleChange("building_type")(event);
                                            const selectedValue = event.target.value;
                                            setIsResidential(selectedValue === 0);
                                          }}
                                          sx={{
                                            fontSize: "15px",
                                          }}
                                          disabled={!canEdit}
                                        >
                                          <MenuItem value={0} sx={{ fontSize: "16px" }}>
                                            {t("edit_simulation.form.building_type.0")}
                                          </MenuItem>
                                          <MenuItem value={1} sx={{ fontSize: "16px" }}>
                                           {t("edit_simulation.form.building_type.1")}
                                          </MenuItem>
                                        </Select>
                                      </Grid>
                                    )}

                                    {defaults && Object.keys(defaults).length !== 0 && (
                                      <Grid item xs={6} sm={6} md={3.5} lg={4} xl={1.5} sx={{ maxWidth: "100% !important" }}>
                                        <Typography
                                          sx={{
                                            textDecoration: "none",
                                            marginTop: "-27px",
                                            paddingLeft: "6px",
                                            paddingBottom: "5px",
                                            justifyContent: "left",
                                          }}
                                          component="h1"
                                          variant="h5"
                                        >
                                          {t("edit_simulation.form.tipo_tubo.title")}
                                        </Typography>
                                        <Select
                                          fullWidth
                                          hiddenLabel
                                          variant="filled"
                                          labelId="tipo_tubo"
                                          id="tipo_tubo"
                                          value={values.tipo_tubo}
                                          onChange={handleChange("tipo_tubo")}
                                          sx={{
                                            fontSize: "15px",
                                          }}
                                        >
                                          {Object.keys(defaults["tipos_tubagem"]).map((tipo, index) => {
                                            return (
                                              <MenuItem key={"tipo_tubo_" + index} value={tipo} sx={{ fontSize: "16px" }}>
                                                {t(`edit_simulation.form.tipo_tubo.${tipo}`)}
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                      </Grid>
                                    )}
                                    <Grid item xs={6} sm={6} md={3.5} lg={4} xl={2.5} sx={{ maxWidth: "100% !important" }}>
                                      <Tooltip title={<h2 style={{}}>{t("edit_simulation.form.insolation_width.tooltip")}</h2>}>
                                        <Typography
                                          sx={{
                                            textDecoration: "none",
                                            paddingLeft: "6px",
                                            paddingBottom: "5px",
                                            justifyContent: "left",
                                            marginTop: "-27px",
                                          }}
                                          component="h1"
                                          variant="h5"
                                        >
                                          {t("edit_simulation.form.insolation_width.title")}
                                        </Typography>
                                      </Tooltip>
                                      <Select
                                        fullWidth
                                        hiddenLabel
                                        variant="filled"
                                        labelId="insolation_width"
                                        id="insolation_width"
                                        value={values.insolation_width}
                                        onChange={handleChange("insolation_width")}
                                        sx={{ fontSize: "15px" }}
                                        disabled={false}
                                      >
                                        <MenuItem value={0} sx={{ fontSize: "16px" }}>
                                          {t("edit_simulation.form.insolation_width.0")}
                                        </MenuItem>
                                        <MenuItem value={1} sx={{ fontSize: "16px" }}>
                                          {t("edit_simulation.form.insolation_width.1")}
                                        </MenuItem>
                                        <MenuItem value={2} sx={{ fontSize: "16px" }}>
                                          {t("edit_simulation.form.insolation_width.2")}
                                        </MenuItem>
                                      </Select>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={3.5} lg={4} xl={3} sx={{ maxWidth: "100% !important" }}>
                                      <Tooltip
                                        title={
                                          <h2 style={{}}>
                                            {t("edit_simulation.form.ccppt.tooltip")}
                                          </h2>
                                        }
                                      >
                                        <Typography
                                          sx={{
                                            textDecoration: "none",
                                            paddingLeft: "6px",
                                            paddingBottom: "5px",
                                            justifyContent: "left",
                                            marginTop: "-27px",
                                          }}
                                          component="h1"
                                          variant="h5"
                                        >
                                          {t("edit_simulation.form.ccppt.title")}
                                        </Typography>
                                      </Tooltip>
                                      <Select
                                        fullWidth
                                        hiddenLabel
                                        variant="filled"
                                        labelId="ccppt"
                                        id="ccppt"
                                        value={values.ccppt}
                                        onChange={handleChange("ccppt")}
                                        sx={{ fontSize: "15px" }}
                                        disabled={false}
                                      >
                                        <MenuItem value={1.15} sx={{ fontSize: "16px" }}>
                                         {t("edit_simulation.form.ccppt.1_15")}
                                        </MenuItem>
                                        <MenuItem value={1.35} sx={{ fontSize: "16px" }}>
                                         {t("edit_simulation.form.ccppt.1_35")}
                                        </MenuItem>
                                        <MenuItem value={1.65} sx={{ fontSize: "16px" }}>
                                          {t("edit_simulation.form.ccppt.1_65")}
                                        </MenuItem>
                                      </Select>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={3.5} lg={4} xl={4} sx={{ maxWidth: "100% !important" }}>
                                      <Typography
                                        sx={{
                                          textDecoration: "none",
                                          paddingLeft: "6px",
                                          paddingBottom: "5px",
                                          justifyContent: "left",
                                          marginTop: "-27px",
                                        }}
                                        component="h1"
                                        variant="h5"
                                      >
                                        {t("edit_simulation.form.cct")}
                                      </Typography>

                                      <Select
                                        fullWidth
                                        hiddenLabel
                                        variant="filled"
                                        labelId="cct"
                                        id="cct"
                                        value={values.cct}
                                        onChange={handleChange("cct")}
                                        sx={{ fontSize: "15px" }}
                                        disabled={false}
                                      >
                                        <MenuItem value={1.68} sx={{ fontSize: "16px" }}>
                                          20
                                        </MenuItem>
                                        <MenuItem value={1.36} sx={{ fontSize: "16px" }}>
                                          25
                                        </MenuItem>
                                        <MenuItem value={1.15} sx={{ fontSize: "16px" }}>
                                          30
                                        </MenuItem>
                                        <MenuItem value={1} sx={{ fontSize: "16px" }}>
                                          35
                                        </MenuItem>
                                        <MenuItem value={0.89} sx={{ fontSize: "16px" }}>
                                          40
                                        </MenuItem>
                                        <MenuItem value={0.8} sx={{ fontSize: "16px" }}>
                                          45
                                        </MenuItem>
                                      </Select>
                                    </Grid>

                                    {isResidential === false ? (
                                      <></>
                                    ) : (
                                      <Grid item xs={12} sm={6} md={6} lg={3.5} xl={1.5}>
                                        <TextField
                                          fullWidth
                                          multiline
                                          variant="filled"
                                          type="number"
                                          label={t("edit_simulation.form.total_habitants")}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          value={values.total_habitants}
                                          name="total_habitants"
                                          error={!!touched.total_habitants && !!errors.total_habitants}
                                          helperText={touched.total_habitants && errors.total_habitants}
                                          inputProps={{ style: { fontSize: 16 } }}
                                          InputLabelProps={{ style: { fontSize: 16 } }}
                                          sx={style}
                                          disabled={!canEdit}
                                        />
                                      </Grid>
                                    )}

                                    {isResidential === false ? (
                                      <></>
                                    ) : (
                                      <Grid item xs={12} sm={6} md={6} lg={3.5} xl={2.5}>
                                        <TextField
                                          fullWidth
                                          multiline
                                          variant="filled"
                                          type="number"
                                          label= {t("edit_simulation.form.pe_usage")}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          value={values.pe_usage}
                                          name="pe_usage"
                                          error={!!touched.pe_usage && !!errors.pe_usage}
                                          helperText={touched.pe_usage && errors.pe_usage}
                                          inputProps={{ style: { fontSize: 16 } }}
                                          InputLabelProps={{ style: { fontSize: 16 } }}
                                          sx={style}
                                          disabled={!canEdit}
                                        />
                                      </Grid>
                                    )}

                                    <Grid item xs={12} sm={6} md={6} lg={3.5} xl={3}>
                                      <TextField
                                        fullWidth
                                        multiline
                                        variant="filled"
                                        type="number"
                                        label= {t("edit_simulation.form.running_time")}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.running_time}
                                        name="running_time"
                                        error={!!touched.running_time && !!errors.running_time}
                                        helperText={touched.running_time && errors.running_time}
                                        inputProps={{ style: { fontSize: 16 } }}
                                        InputLabelProps={{ style: { fontSize: 16 } }}
                                        sx={style}
                                        disabled={!canEdit}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={3.5} xl={3}>
                                      <TextField
                                        fullWidth
                                        multiline
                                        variant="filled"
                                        type="number"
                                        label= {t("edit_simulation.form.running_time_hoterway")}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.running_time_hoterway}
                                        name="running_time_hoterway"
                                        error={!!touched.running_time_hoterway && !!errors.running_time_hoterway}
                                        helperText={touched.running_time_hoterway && errors.running_time_hoterway}
                                        inputProps={{ style: { fontSize: 16 } }}
                                        InputLabelProps={{ style: { fontSize: 16 } }}
                                        sx={style}
                                        disabled={!canEdit}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={3.5} xl={3}>
                                      <TextField
                                        fullWidth
                                        multiline
                                        variant="filled"
                                        type="number"
                                        label={t("edit_simulation.form.volume_aqs")}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.volume_aqs}
                                        name="volume_aqs"
                                        error={!!touched.volume_aqs && !!errors.volume_aqs}
                                        helperText={touched.volume_aqs && errors.volume_aqs}
                                        inputProps={{ style: { fontSize: 16 } }}
                                        InputLabelProps={{ style: { fontSize: 16 } }}
                                        sx={style}
                                        disabled={!canEdit}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={3.5} xl={2}>
                                      <TextField
                                        fullWidth
                                        multiline
                                        variant="filled"
                                        type="number"
                                        label= {t("edit_simulation.form.price_thermal")}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.price_thermal}
                                        name="price_thermal"
                                        error={!!touched.price_thermal && !!errors.price_thermal}
                                        helperText={touched.price_thermal && errors.price_thermal}
                                        inputProps={{ style: { fontSize: 16 } }}
                                        InputLabelProps={{ style: { fontSize: 16 } }}
                                        sx={style}
                                        disabled={!canEdit}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={3.5} xl={2}>
                                      <TextField
                                        fullWidth
                                        multiline
                                        variant="filled"
                                        type="number"
                                        label= {t("edit_simulation.form.price_energy")}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.price_energy}
                                        name="price_energy"
                                        error={!!touched.price_energy && !!errors.price_energy}
                                        helperText={touched.price_energy && errors.price_energy}
                                        inputProps={{ style: { fontSize: 16 } }}
                                        InputLabelProps={{ style: { fontSize: 16 } }}
                                        sx={style}
                                        disabled={!canEdit}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={3.5} xl={2.5}>
                                      <TextField
                                        fullWidth
                                        multiline
                                        variant="filled"
                                        type="number"
                                        label= {t("edit_simulation.form.water_consumption")}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.water_consumption}
                                        name="water_consumption"
                                        error={!!touched.water_consumption && !!errors.water_consumption}
                                        helperText={touched.water_consumption && errors.water_consumption}
                                        inputProps={{ style: { fontSize: 16 } }}
                                        InputLabelProps={{ style: { fontSize: 16 } }}
                                        sx={style}
                                        disabled={!canEdit}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={3.5} xl={2.5}>
                                      <TextField
                                        fullWidth
                                        multiline
                                        variant="filled"
                                        type="number"
                                        label= {t("edit_simulation.form.water_in_temp")}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.water_in_temp}
                                        name="water_in_temp"
                                        error={!!touched.water_in_temp && !!errors.water_in_temp}
                                        helperText={touched.water_in_temp && errors.water_in_temp}
                                        inputProps={{ style: { fontSize: 16 } }}
                                        InputLabelProps={{ style: { fontSize: 16 } }}
                                        sx={style}
                                        disabled={!canEdit}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={3.5} xl={2.5}>
                                      <TextField
                                        fullWidth
                                        multiline
                                        variant="filled"
                                        type="number"
                                        label= {t("edit_simulation.form.hot_water_temp")}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.hot_water_temp}
                                        name="hot_water_temp"
                                        error={!!touched.hot_water_temp && !!errors.hot_water_temp}
                                        helperText={touched.hot_water_temp && errors.hot_water_temp}
                                        inputProps={{ style: { fontSize: 16 } }}
                                        InputLabelProps={{ style: { fontSize: 16 } }}
                                        sx={style}
                                        disabled={!canEdit}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={3.5} xl={2}>
                                      <Tooltip
                                        title={
                                          <h2 style={{}}>
                                             {t("edit_simulation.form.average_power.tooltip")}
                                          </h2>
                                        }
                                      >
                                        <TextField
                                          fullWidth
                                          multiline
                                          variant="filled"
                                          type="number"
                                          label={t("edit_simulation.form.average_power.title")}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          value={values.average_power}
                                          name="average_power"
                                          error={!!touched.average_power && !!errors.average_power}
                                          helperText={touched.average_power && errors.average_power}
                                          inputProps={{ style: { fontSize: 16 } }}
                                          InputLabelProps={{ style: { fontSize: 16 } }}
                                          sx={style}
                                          disabled={!canEdit}
                                        />
                                      </Tooltip>
                                    </Grid>

                                    {isResidential === false ? (
                                      <></>
                                    ) : (
                                      <Grid item xs={12} sm={6} md={6} lg={3.5} xl={1.5}>
                                        <TextField
                                          fullWidth
                                          multiline
                                          variant="filled"
                                          type="number"
                                          label={t("edit_simulation.form.water_price")}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          value={values.water_price}
                                          name="water_price"
                                          error={!!touched.water_price && !!errors.water_price}
                                          helperText={touched.water_price && errors.water_price}
                                          inputProps={{ style: { fontSize: 16 } }}
                                          InputLabelProps={{ style: { fontSize: 16 } }}
                                          sx={style}
                                          disabled={!canEdit}
                                        />
                                      </Grid>
                                    )}
                                    {isResidential === false ? (
                                      <></>
                                    ) : (
                                      <Grid item xs={12} sm={6} md={6} lg={3.5} xl={1.5}>
                                        <TextField
                                          fullWidth
                                          multiline
                                          variant="filled"
                                          type="number"
                                          label={t("edit_simulation.form.building_area")}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          value={values.building_area}
                                          name="building_area"
                                          error={!!touched.building_area && !!errors.building_area}
                                          helperText={touched.building_area && errors.building_area}
                                          inputProps={{ style: { fontSize: 16 } }}
                                          InputLabelProps={{ style: { fontSize: 16 } }}
                                          sx={style}
                                          disabled={!canEdit}
                                        />
                                      </Grid>
                                    )}
                                    {isResidential === false ? (
                                      <></>
                                    ) : (
                                      <Grid item xs={12} sm={6} md={6} lg={3.5} xl={2}>
                                        <Typography
                                          sx={{
                                            textDecoration: "none",
                                            marginTop: "-27px",
                                            paddingLeft: "6px",
                                            paddingBottom: "5px",
                                            justifyContent: "left",
                                          }}
                                          component="h1"
                                          variant="h5"
                                        >
                                          {t("edit_simulation.form.certificate_class")}
                                        </Typography>
                                        <Select
                                          fullWidth
                                          hiddenLabel
                                          variant="filled"
                                          labelId="certificate_class"
                                          id="certificate_class"
                                          value={values.certificate_class}
                                          onChange={handleChange("certificate_class")}
                                          sx={{
                                            fontSize: "15px",
                                          }}
                                          disabled={!canEdit}
                                        >
                                          <MenuItem value={0} sx={{ fontSize: "16px" }}>
                                            A+
                                          </MenuItem>
                                          <MenuItem value={1} sx={{ fontSize: "16px" }}>
                                            A
                                          </MenuItem>
                                          <MenuItem value={2} sx={{ fontSize: "16px" }}>
                                            B
                                          </MenuItem>
                                          <MenuItem value={3} sx={{ fontSize: "16px" }}>
                                            B-
                                          </MenuItem>
                                          <MenuItem value={4} sx={{ fontSize: "16px" }}>
                                            C
                                          </MenuItem>
                                          <MenuItem value={5} sx={{ fontSize: "16px" }}>
                                            D
                                          </MenuItem>
                                          <MenuItem value={6} sx={{ fontSize: "16px" }}>
                                            E
                                          </MenuItem>
                                          <MenuItem value={7} sx={{ fontSize: "16px" }}>
                                            F
                                          </MenuItem>
                                        </Select>
                                      </Grid>
                                    )}

                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                      <TextField
                                        fullWidth
                                        multiline
                                        variant="filled"
                                        type="text"
                                        label={t("edit_simulation.form.obs")}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.obs}
                                        name="obs"
                                        error={!!touched.obs && !!errors.obs}
                                        helperText={touched.obs && errors.obs}
                                        inputProps={{ style: { fontSize: 16 } }}
                                        InputLabelProps={{ style: { fontSize: 16 } }}
                                        sx={style}
                                        disabled={!canEdit}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Typography component="h1" variant="h6" mt="-20px">
                                        {t("edit_simulation.form.required")}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </AccordionDetails>
                              </Accordion>
                            </Grid>

                            <Grid item xs={12}>
                              <Accordion sx={{ backgroundColor: colors.primary[600], borderRadius: "50px" }}>
                                <AccordionSummary
                                  sx={{ backgroundColor: colors.greenAccent[700] }}
                                  expandIcon={<ExpandMoreIconOutlined />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography component="h1" variant="h3">
                                    {t("edit_simulation.form.title4")}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Grid container spacing={4} mt="10px" justifyContent="left">
                                    <>
                                      <div
                                        className="projectSectionContainer"
                                        style={{
                                          flexDirection: "row",
                                          width: "90%",
                                          flexWrap: "wrap",
                                        }}
                                      >
                                        {Array.from(Array(project.house_parts_count).keys()).map((i) => {
                                          const setPe_PCValues = (items_in: any[]) => {
                                            let res: { [key: string]: any } = {};
                                            items_in.forEach((item: any, index) => {
                                              if (index === 0) res["name"] = item;
                                              else if (index === 1) res["nickname"] = item;
                                              else if (index === 2) res["caudal_minimo"] = item;
                                              else if (index === 3) res["caudal_total"] = item;
                                              else if (index === 4) res["hoterway"] = item;
                                              // else res["pc_" + (index - 1)] = item;
                                            });

                                            let pePcLayoutCopy = pePcLayout;

                                            pePcLayoutCopy["div_" + i] = res;
                                            setProject({
                                              ...project,
                                              pepc_layout: { ...pePcLayoutCopy },
                                            });
                                            setPePcLayout(pePcLayoutCopy);
                                            // console.log(pePcLayout);
                                          };
                                          return (
                                            <div key={"" + i} className="projectPePcContainer">
                                              <p className="projectSectionTitle">{t("edit_simulation.form.compartment") + " " + (i + 1)}</p>
                                              <div className="coolTablesContainer">
                                                <CoolTableSim
                                                  t={t}
                                                  editMode={1}
                                                  index={i}
                                                  pepcLayout={pePcLayout}
                                                  setPe_PCValues={setPe_PCValues}
                                                  className="coolTable"
                                                  pcCount={project.pc_count}
                                                />
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </>
                                    <>
                                      <div
                                        className="projectSectionContainer"
                                        style={{
                                          flexDirection: "row",
                                          width: "90%",
                                          flexWrap: "wrap",
                                        }}
                                      >
                                        {Array.from(Array(floorsCount).keys()).map((i) => {
                                          const setName = (name) => {
                                            const newNames = [...floorNames];
                                            newNames[i] = name;
                                            setFloorNames(newNames);
                                          };
                                          return (
                                            <div key={"" + i} className="projectPePcContainer">
                                              <p className="projectSectionTitle">{t("edit_simulation.form.floor") + " " + (i + 1)}</p>
                                              <div className="coolTablesContainer">
                                                <SimpleCoolTable
                                                  t={t}
                                                  index={i}
                                                  setName={setName}
                                                  editMode={0}
                                                  initialName={floorNames[i]}
                                                />
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </>
                                  </Grid>
                                </AccordionDetails>
                              </Accordion>
                            </Grid>

                            {canEdit ? (
                              <>
                                <Grid container justifyContent="flex-end" spacing={4}>
                                  <Grid item xs={12} sm={5} md={2.5} lg={3} xl={2} mt="40px">
                                    <Box
                                      p="5px 7px 5px 5px"
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center"
                                      borderRadius="4px"
                                      backgroundColor={colors.blueAccent[600]}
                                      sx={{
                                        cursor: "pointer",
                                        textDecoration: "none",
                                      }}
                                      onClick={() => {
                                        const complete_project = { ...project };

                                        if (isResidential === true) {
                                          handleFormSubmit({
                                            client_name: values.client_name,
                                            email: values.email,
                                            email_comercial: values.email_comercial,
                                            address: values.address,
                                            average_power: values.average_power,
                                            date: values.date,
                                            has_solar_panels: complete_project["has_solar_panels"],
                                            hot_water_temp: values.hot_water_temp,
                                            house_parts_count: values.house_parts_count,
                                            insolation_width: values.insolation_width,
                                            ccppt: values.ccppt,
                                            cct: values.cct,
                                            nif: values.nif,
                                            obs: values.obs,
                                            pe_usage: values.pe_usage,
                                            pepc_layout: complete_project["pepc_layout"],
                                            phone: values.phone,
                                            postal_code: values.postal_code,
                                            running_time: values.running_time,
                                            running_time_hoterway: values.running_time_hoterway,
                                            volume_aqs: values.volume_aqs,
                                            solar_heater_water: complete_project["solar_heater_water"],
                                            state: values.state,
                                            tipo_tubo: values.tipo_tubo,
                                            total_habitants: values.total_habitants,
                                            water_consumption: values.water_consumption,
                                            water_heating_system: complete_project["water_heating_system"],
                                            price_thermal: values.price_thermal,
                                            price_energy: values.price_energy,
                                            water_in_temp: values.water_in_temp,
                                            water_price: values.water_price,
                                            floors_count: values.floors_count,
                                            floors_names: floorNames,
                                            building_area: values.building_area,
                                            // energy_reference: complete_project["energy_reference"],
                                            energy_reference: 150,
                                            certificate_class: values.certificate_class,
                                            building_type: values.building_type,
                                            isShared: values.isShared,
                                            id: idSimulation,
                                          });
                                        } else {
                                          handleFormSubmit({
                                            client_name: values.client_name,
                                            email: values.email,
                                            email_comercial: values.email_comercial,
                                            address: values.address,
                                            average_power: values.average_power,
                                            date: values.date,
                                            has_solar_panels: complete_project["has_solar_panels"],
                                            hot_water_temp: values.hot_water_temp,
                                            house_parts_count: values.house_parts_count,
                                            insolation_width: values.insolation_width,
                                            ccppt: values.ccppt,
                                            cct: values.cct,
                                            nif: values.nif,
                                            obs: values.obs,
                                            pe_usage: complete_project["pe_usage"],
                                            pepc_layout: complete_project["pepc_layout"],
                                            phone: values.phone,
                                            postal_code: values.postal_code,
                                            running_time: values.running_time,
                                            running_time_hoterway: values.running_time_hoterway,
                                            volume_aqs: values.volume_aqs,
                                            solar_heater_water: complete_project["solar_heater_water"],
                                            state: values.state,
                                            tipo_tubo: values.tipo_tubo,
                                            total_habitants: complete_project["total_habitants"],
                                            water_consumption: values.water_consumption,
                                            water_heating_system: complete_project["water_heating_system"],
                                            price_thermal: values.price_thermal,
                                            price_energy: values.price_energy,
                                            water_in_temp: values.water_in_temp,
                                            water_price: complete_project["water_price"],
                                            floors_count: values.floors_count,
                                            floors_names: floorNames,
                                            building_area: complete_project["building_area"],
                                            // energy_reference: complete_project["energy_reference"],
                                            energy_reference: 150,
                                            certificate_class: complete_project["certificate_class"],
                                            building_type: values.building_type,
                                            isShared: values.isShared,
                                            id: idSimulation,
                                          });
                                        }
                                      }}
                                    >
                                      <LoupeOutlinedIcon
                                        sx={{
                                          textDecoration: "none",
                                          color: colors.grey[100],
                                          justifyContent: "center",
                                        }}
                                      />
                                      <Typography
                                        variant="h3"
                                        justifyContent="center"
                                        color={colors.grey[100]}
                                        sx={{
                                          ml: "5px",
                                          textDecoration: "none",
                                          fontWeight: "bold",
                                          justifyContent: "center",
                                          marginTop: "1px",
                                        }}
                                      >
                                        {t("edit_simulation.form.buttons.edit_simulation")}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={12} sm={5} md={2.5} lg={3} xl={2} mt="40px">
                                    <Box
                                      p="5px 7px 5px 5px"
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center"
                                      borderRadius="4px"
                                      backgroundColor={colors.blueAccent[600]}
                                      sx={{
                                        cursor: "pointer",
                                        textDecoration: "none",
                                      }}
                                      onClick={() => {
                                        const complete_project = { ...project };

                                        if (isResidential === true) {
                                          handleFormDuplicate({
                                            client_name: values.client_name + t("edit_simulation.duplicated"),
                                            email: values.email,
                                            email_comercial: values.email_comercial,
                                            address: values.address,
                                            average_power: values.average_power,
                                            date: values.date,
                                            has_solar_panels: complete_project["has_solar_panels"],
                                            hot_water_temp: values.hot_water_temp,
                                            house_parts_count: values.house_parts_count,
                                            insolation_width: values.insolation_width,
                                            ccppt: values.ccppt,
                                            cct: values.cct,
                                            nif: values.nif,
                                            obs: values.obs,
                                            pe_usage: values.pe_usage,
                                            pepc_layout: complete_project["pepc_layout"],
                                            phone: values.phone,
                                            postal_code: values.postal_code,
                                            running_time: values.running_time,
                                            running_time_hoterway: values.running_time_hoterway,
                                            volume_aqs: values.volume_aqs,
                                            solar_heater_water: complete_project["solar_heater_water"],
                                            state: values.state,
                                            tipo_tubo: values.tipo_tubo,
                                            total_habitants: values.total_habitants,
                                            water_consumption: values.water_consumption,
                                            water_heating_system: complete_project["water_heating_system"],
                                            price_thermal: values.price_thermal,
                                            price_energy: values.price_energy,
                                            water_in_temp: values.water_in_temp,
                                            water_price: values.water_price,
                                            floors_count: values.floors_count,
                                            floors_names: floorNames,
                                            building_area: values.building_area,
                                            // energy_reference: complete_project["energy_reference"],
                                            energy_reference: 150,
                                            certificate_class: values.certificate_class,
                                            building_type: values.building_type,
                                            isShared: values.isShared,
                                          });
                                        } else {
                                          handleFormDuplicate({
                                            client_name: values.client_name + t("edit_simulation.duplicated"),
                                            email: values.email,
                                            email_comercial: values.email_comercial,
                                            address: values.address,
                                            average_power: values.average_power,
                                            date: values.date,
                                            has_solar_panels: complete_project["has_solar_panels"],
                                            hot_water_temp: values.hot_water_temp,
                                            house_parts_count: values.house_parts_count,
                                            insolation_width: values.insolation_width,
                                            ccppt: values.ccppt,
                                            cct: values.cct,
                                            nif: values.nif,
                                            obs: values.obs,
                                            pe_usage: complete_project["pe_usage"],
                                            pepc_layout: complete_project["pepc_layout"],
                                            phone: values.phone,
                                            postal_code: values.postal_code,
                                            running_time: values.running_time,
                                            running_time_hoterway: values.running_time_hoterway,
                                            volume_aqs: values.volume_aqs,
                                            solar_heater_water: complete_project["solar_heater_water"],
                                            state: values.state,
                                            tipo_tubo: values.tipo_tubo,
                                            total_habitants: complete_project["total_habitants"],
                                            water_consumption: values.water_consumption,
                                            water_heating_system: complete_project["water_heating_system"],
                                            price_thermal: values.price_thermal,
                                            price_energy: values.price_energy,
                                            water_in_temp: values.water_in_temp,
                                            water_price: complete_project["water_price"],
                                            floors_count: values.floors_count,
                                            floors_names: floorNames,
                                            building_area: complete_project["building_area"],
                                            // energy_reference: complete_project["energy_reference"],
                                            energy_reference: 150,
                                            certificate_class: complete_project["certificate_class"],
                                            building_type: values.building_type,
                                            isShared: values.isShared,
                                          });
                                        }
                                      }}
                                    >
                                      <LoupeOutlinedIcon
                                        sx={{
                                          textDecoration: "none",
                                          color: colors.grey[100],
                                          justifyContent: "center",
                                        }}
                                      />
                                      <Typography
                                        variant="h3"
                                        justifyContent="center"
                                        color={colors.grey[100]}
                                        sx={{
                                          ml: "5px",
                                          textDecoration: "none",
                                          fontWeight: "bold",
                                          justifyContent: "center",
                                          marginTop: "1px",
                                        }}
                                      >
                                        {t("edit_simulation.form.buttons.duplicate_simulation")}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={12} sm={5} md={2.5} lg={3} xl={2} mt="40px">
                                    <Box
                                      p="5px 7px 5px 5px"
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center"
                                      borderRadius="4px"
                                      backgroundColor={colors.blueAccent[600]}
                                      sx={{
                                        cursor: "pointer",
                                        textDecoration: "none",
                                      }}
                                      onClick={() => {
                                        navigate(`/compare_scenario/${idSimulation}`);
                                        // window.location.reload();
                                      }}
                                    >
                                      <LoupeOutlinedIcon
                                        sx={{
                                          textDecoration: "none",
                                          color: colors.grey[100],
                                          justifyContent: "center",
                                        }}
                                      />
                                      <Typography
                                        variant="h3"
                                        justifyContent="center"
                                        color={colors.grey[100]}
                                        sx={{
                                          ml: "5px",
                                          textDecoration: "none",
                                          fontWeight: "bold",
                                          justifyContent: "center",
                                          marginTop: "1px",
                                        }}
                                      >
                                        {t("edit_simulation.form.buttons.compare_scenarios")}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </>
                            ) : (
                              <></>
                            )}
                          </Grid>
                        </form>
                      )}
                    </Formik>
                  ) : (
                    <LoadingScreen />
                  )}
                </Box>
              </Container>

              {hide === false ? (
                <Box ml="20px" mb="20px" mr="20px" mt="40px">
                  <Header
                    title={t("edit_simulation.table_title") + " " + idSimulation}
                    subtitle={t("edit_simulation.table_subtitle") + " " + idSimulation + "!"}
                  />

                  {canCreateScenario ? (
                    <>
                      <Grid container justifyContent="flex-end" spacing={4}>
                        <Grid item xs={12} sm={5} md={2.5} lg={3} xl={2} mt="40px">
                          <Box
                            p="5px 7px 5px 5px"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            borderRadius="4px"
                            backgroundColor={colors.blueAccent[600]}
                            sx={{
                              cursor: "pointer",
                              textDecoration: "none",
                            }}
                            onClick={() => {
                              window.scrollTo(0, 0);
                              setHide(true);
                              setIsEditedScenario(true);
                            }}
                          >
                            <LoupeOutlinedIcon
                              sx={{
                                textDecoration: "none",
                                color: colors.grey[100],
                                justifyContent: "center",
                              }}
                            />
                            <Typography
                              variant="h3"
                              justifyContent="center"
                              color={colors.grey[100]}
                              sx={{
                                ml: "5px",
                                textDecoration: "none",
                                fontWeight: "bold",
                                justifyContent: "center",
                                marginTop: "1px",
                              }}
                            >
                              {t("edit_simulation.table_button")}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}

                  {currentUser && currentUser.user_type === 2 ? (
                    <Box display="flex" flexDirection="column" alignItems="center" mt="30px">
                      <Fade
                        in={pressDelete}
                        timeout={{ enter: 1000, exit: 1000 }}
                        unmountOnExit={true}
                        addEndListener={() => {
                          setTimeout(() => {
                            setPressDelete(false);
                          }, 3000);
                        }}
                      >
                        <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="warning">
                          <AlertTitle sx={{ fontSize: "16px" }}>{t("edit_simulation.alert_fields.press_delete1.title")}</AlertTitle>
                          {t("edit_simulation.alert_fields.press_delete1.description")}
                        </Alert>
                      </Fade>
                    </Box>
                  ) : (
                    <Box display="flex" flexDirection="column" alignItems="center" mt="30px">
                      <Fade
                        in={pressDelete}
                        timeout={{ enter: 1000, exit: 1000 }}
                        unmountOnExit={true}
                        addEndListener={() => {
                          setTimeout(() => {
                            setPressDelete(false);
                          }, 3000);
                        }}
                      >
                        <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="warning">
                          <AlertTitle sx={{ fontSize: "16px" }}>{t("edit_simulation.alert_fields.press_delete2.title")}</AlertTitle>
                          {t("edit_simulation.alert_fields.press_delete2.description")}
                        </Alert>
                      </Fade>
                    </Box>
                  )}

                  <Box
                    m="40px 0 0 0"
                    height="75vh"
                    sx={{
                      "& .MuiDataGrid-root": {
                        border: "none",
                      },
                      "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                      },
                      "& .name-column--cell": {
                        color: colors.greenAccent[300],
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                      },
                      "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                      },
                      "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                      },
                      "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.greenAccent[100]} !important`,
                      },
                      "& .MuiButtonBase-root .MuiButton-root .MuiButton-text .MuiButton-textPrimary .MuiButton-sizeMedium .MuiButton-textSizeMedium .MuiButton-root .MuiButton-text .MuiButton-textPrimary .MuiButton-sizeMedium .MuiButton-textSizeMedium .css-13qp4b7-MuiButtonBase-root-MuiButton-root":
                        {
                          color: `${colors.blueAccent[700]} !important`,
                        },
                      "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                      },
                    }}
                  >
                    <DataGrid
                      rowsPerPageOptions={[50, 100, 250, 500, 1000]}
                      hideFooterSelectedRowCount
                      componentsProps={{
                pagination: {
                  labelRowsPerPage: t("table_custom_translation.label_rows_per_page"),
                },
              }}
              localeText={{
                MuiTablePagination: {
                  labelDisplayedRows: ({ from, to, count }) => `${count} ` + t("table_custom_translation.label_displayed_rows"),
                },
                // Root
                noRowsLabel: t("table_custom_translation.no_rows_label"),
                noResultsOverlayLabel: t("table_custom_translation.no_results_overlay_label"),
                errorOverlayDefaultLabel: t("table_custom_translation.error_overlay_default_label"),
                // Density selector toolbar button text
                toolbarDensity: t("table_custom_translation.toolbar_density"),
                toolbarDensityLabel: t("table_custom_translation.toolbar_density_label"),
                toolbarDensityCompact: t("table_custom_translation.toolbar_density_compact"),
                toolbarDensityStandard: t("table_custom_translation.toolbar_density_standard"),
                toolbarDensityComfortable: t("table_custom_translation.toolbar_density_comfortable"),
                // Columns selector toolbar button text
                toolbarColumns: t("table_custom_translation.toolbar_columns"),
                toolbarColumnsLabel: t("table_custom_translation.toolbar_columns_label"),
                // Filters toolbar button text
                toolbarFilters: t("table_custom_translation.toolbar_filters"),
                toolbarFiltersLabel: t("table_custom_translation.toolbar_filters_label"),
                toolbarFiltersTooltipHide: t("table_custom_translation.toolbar_filters_tooltip_hide"),
                toolbarFiltersTooltipShow: t("table_custom_translation.toolbar_filters_tooltip_show"),
                toolbarFiltersTooltipActive: (count) =>
                  `${count} ${
                    count !== 1
                      ? t("table_custom_translation.toolbar_filters_tooltip_active.filter_plural")
                      : t("table_custom_translation.toolbar_filters_tooltip_active.filter_singular")
                  } ${
                    count !== 1
                      ? t("table_custom_translation.toolbar_filters_tooltip_active.active_plural")
                      : t("table_custom_translation.toolbar_filters_tooltip_active.active_singular")
                  }`,
                // Quick filter toolbar field
                toolbarQuickFilterPlaceholder: t("table_custom_translation.toolbar_quick_filter_placeholder"),
                toolbarQuickFilterLabel: t("table_custom_translation.toolbar_quick_filter_label"),
                toolbarQuickFilterDeleteIconLabel: t("table_custom_translation.toolbar_quick_filter_delete_icon_label"),
                // Export selector toolbar button text
                toolbarExport: t("table_custom_translation.toolbar_export"),
                toolbarExportLabel: t("table_custom_translation.toolbar_export_label"),
                toolbarExportCSV: t("table_custom_translation.toolbar_export_CSV"),
                toolbarExportPrint: t("table_custom_translation.toolbar_export_print"),
                toolbarExportExcel: t("table_custom_translation.toolbar_export_excel"),
                // Columns panel text
                columnsPanelTextFieldLabel: t("table_custom_translation.columns_panel_text_field_label"),
                columnsPanelTextFieldPlaceholder: t("table_custom_translation.columns_panel_text_field_placeholder"),
                columnsPanelDragIconLabel: t("table_custom_translation.columns_panel_drag_icon_label"),
                columnsPanelShowAllButton: t("table_custom_translation.columns_panel_showAll_button"),
                columnsPanelHideAllButton: t("table_custom_translation.columns_panel_hideAll_button"),
                // Filter panel text
                filterPanelAddFilter: t("table_custom_translation.filter_panel_add_filter"),
                filterPanelDeleteIconLabel: t("table_custom_translation.filter_panel_delete_icon_label"),
                filterPanelLinkOperator: t("table_custom_translation.filter_panel_link_operator"),
                filterPanelOperators: t("table_custom_translation.filter_panel_operators"),
                // TODO v6: rename to filterPanelOperator
                filterPanelOperatorAnd: t("table_custom_translation.filter_panel_operator_and"),
                filterPanelOperatorOr: t("table_custom_translation.filter_panel_operator_or"),
                filterPanelColumns: t("table_custom_translation.filter_panel_columns"),
                filterPanelInputLabel: t("table_custom_translation.filter_panel_input_label"),
                filterPanelInputPlaceholder: t("table_custom_translation.filter_panel_input_placeholder"),
                // Filter operators text
                filterOperatorContains: t("table_custom_translation.filter_operator_contains"),
                filterOperatorEquals: t("table_custom_translation.filter_operator_equals"),
                filterOperatorStartsWith: t("table_custom_translation.filter_operator_starts_with"),
                filterOperatorEndsWith: t("table_custom_translation.filter_operator_ends_with"),
                filterOperatorIs: t("table_custom_translation.filter_operator_is"),
                filterOperatorNot: t("table_custom_translation.filter_operator_not"),
                filterOperatorAfter: t("table_custom_translation.filter_operator_after"),
                filterOperatorOnOrAfter: t("table_custom_translation.filter_operator_on_or_after"),
                filterOperatorBefore: t("table_custom_translation.filter_operator_before"),
                filterOperatorOnOrBefore: t("table_custom_translation.filter_operator_on_or_before"),
                filterOperatorIsEmpty: t("table_custom_translation.filter_operator_is_empty"),
                filterOperatorIsNotEmpty: t("table_custom_translation.filter_operator_is_not_empty"),
                filterOperatorIsAnyOf: t("table_custom_translation.filter_operator_is_any_of"),
                // Filter values text
                filterValueAny: t("table_custom_translation.filter_value_any"),
                filterValueTrue: t("table_custom_translation.filter_value_true"),
                filterValueFalse: t("table_custom_translation.filter_value_false"),
                // Column menu text
                columnMenuLabel: t("table_custom_translation.column_menu_label"),
                columnMenuShowColumns: t("table_custom_translation.column_menu_show_columns"),
                columnMenuFilter: t("table_custom_translation.column_menu_filter"),
                columnMenuHideColumn: t("table_custom_translation.column_menu_hide_column"),
                columnMenuUnsort: t("table_custom_translation.column_menu_unsort"),
                columnMenuSortAsc: t("table_custom_translation.column_menu_sort_asc"),
                columnMenuSortDesc: t("table_custom_translation.column_menu_sort_desc"),
                // Column header text
                columnHeaderFiltersTooltipActive: (count) =>
                  `${count} ${
                    count !== 1
                      ? t("table_custom_translation.column_header_filters_tooltip_active.filter_plural")
                      : t("table_custom_translation.column_header_filters_tooltip_active.filter_singular")
                  } ${
                    count !== 1
                      ? t("table_custom_translation.column_header_filters_tooltip_active.active_plural")
                      : t("table_custom_translation.column_header_filters_tooltip_active.active_singular")
                  }`,
                columnHeaderFiltersLabel: t("table_custom_translation.column_header_filters_label"),
                columnHeaderSortIconLabel: t("table_custom_translation.column_header_sort_icon_label"),
                // Rows selected footer text
                footerRowSelected: (count) =>
                  count !== 1
                    ? `${count.toLocaleString()} +` + t("table_custom_translation.footer_row_selected.plural")
                    : `${count.toLocaleString()} ` + t("table_custom_translation.footer_row_selected.singular"),
                // Total row amount footer text
                footerTotalRows: t("table_custom_translation.footer_total_rows"),
                // Total visible row amount footer text
                footerTotalVisibleRows: (visibleCount, totalCount) =>
                  `${visibleCount.toLocaleString()} ` +
                  t("table_custom_translation.footer_total_visible_rows") +
                  `${totalCount.toLocaleString()}`,
                // Checkbox selection text
                checkboxSelectionHeaderName: t("table_custom_translation.checkbox_selection_header_name"),
                checkboxSelectionSelectAllRows: t("table_custom_translation.checkbox_selection_select_all_rows"),
                checkboxSelectionUnselectAllRows: t("table_custom_translation.checkbox_selection_unselect_all_rows"),
                checkboxSelectionSelectRow: t("table_custom_translation.checkbox_selection_select_row"),
                checkboxSelectionUnselectRow: t("table_custom_translation.checkbox_selection_unselect_row"),
                // Boolean cell text
                booleanCellTrueLabel: t("table_custom_translation.boolean_cell_true_label"),
                booleanCellFalseLabel: t("table_custom_translation.boolean_cell_false_label"),
                // Actions cell more text
                actionsCellMore: t("table_custom_translation.actions_cell_more"),
                // Column pinning text
                pinToLeft: t("table_custom_translation.pin_to_left"),
                pinToRight: t("table_custom_translation.pin_to_right"),
                unpin: t("table_custom_translation.unpin"),
                // Tree Data
                treeDataGroupingHeaderName: t("table_custom_translation.tree_data_grouping_header_name"),
                treeDataExpand: t("table_custom_translation.tree_data_expand"),
                treeDataCollapse: t("table_custom_translation.tree_data_collapse"),
                // Grouping columns
                groupingColumnHeaderName: t("table_custom_translation.grouping_column_header_name"),
                groupColumn: (name) => t("table_custom_translation.group_column") + ` ${name}`,
                unGroupColumn: (name) => t("table_custom_translation.un_group_column") + ` ${name}`,
                // Master/detail
                detailPanelToggle: t("table_custom_translation.detail_panel_toggle"),
                expandDetailPanel: t("table_custom_translation.expand_detail_panel"),
                collapseDetailPanel: t("table_custom_translation.collapse_detail_panel"),
                // Row reordering text
                rowReorderingHeaderName: t("table_custom_translation.row_reordering_header_name"),
                // Aggregation
                // aggregationMenuItemHeader: 'Aggregation',
                // aggregationFunctionLabelSum: 'sum',
                // aggregationFunctionLabelAvg: 'avg',
                // aggregationFunctionLabelMin: 'min',
                // aggregationFunctionLabelMax: 'max',
                // aggregationFunctionLabelSize: 'size',
              }}
                      rows={scenarios}
                      columns={columns}
                      components={{ Toolbar: GridToolbar }}
                    />
                  </Box>
                </Box>
              ) : (
                <LoadingScreen />
              )}
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
}

export default EditSimulation;
