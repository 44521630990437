import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  useTheme,
  Grid,
  Fade,
  Alert,
  AlertTitle,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import axios from "axios";
import LoadingScreen from "../global/LoadingScreen";
import { AuthContext } from "../../context/authContext";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ExpandMoreIconOutlined from "@mui/icons-material/ExpandMoreOutlined";
import { useTranslation } from "react-i18next";

const Downloads = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [generalError, setGeneralError] = useState(false);
  const [sessionError, setSessionError] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();

  const { currentUser, verifyUser, logout } = useContext(AuthContext);
  const [currentLicense, setCurrentLicense] = useState(null);

  const config = {
    headers: {
      "access-token": process.env.REACT_APP_ACCESS_TOKEN,
      "Content-Type": "application/json", // Adjust the content type if needed
    },
  };

  const verifyLicense = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + `licenses/email/${currentUser?.email}`, config);

      if (response.data.success === 0) {
        setCurrentLicense(0);
      } else if (response.data.data[0].time === 0) {
        setCurrentLicense(1);
      } else if (response.data.data[0].active === 1) {
        setCurrentLicense(2);
      }
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
        logout();
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    verifyUser(currentUser);
    if (!currentUser) {
      navigate("/login");
    } else {
      verifyLicense();
      if (currentLicense !== null && currentLicense !== 2) {
        navigate("/login");
      } else if (currentUser.user_type === 1 || currentUser.user_type === 2 || currentUser.user_type === 3) {
        navigate("/");
      }
    }
  }, [currentLicense, currentUser]);

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <Box m="20px">
          <Header title={t("downloads.title")} subtitle={t("downloads.subtitle")} />

          <Grid container xs={1} sm={1} md={1} lg={1} xl={1}>
            <Box
              onClick={() => navigate(-1)}
              p="5px 7px 5px 5px"
              display="flex"
              justifyContent="center"
              borderRadius="4px"
              alignItems="center"
              backgroundColor={colors.blueAccent[600]}
              sx={{
                cursor: "pointer",
                textDecoration: "none",
              }}
            >
              <ArrowBackOutlinedIcon
                sx={{
                  textDecoration: "none",
                  color: colors.primary[100],
                  justifyContent: "center",
                }}
              />
              <Typography
                justifyContent="center"
                color={colors.primary[100]}
                sx={{
                  ml: "5px",
                  textDecoration: "none",
                  fontWeight: "bold",
                  justifyContent: "center",
                  marginTop: "1px",
                }}
              >
                {t("general.back_button")}
              </Typography>
            </Box>
          </Grid>

          <Box m="20px">
            <Fade
              in={generalError}
              timeout={{ enter: 500, exit: 500 }}
              unmountOnExit={true}
              addEndListener={() => {
                window.scrollTo(0, 0);
                setTimeout(() => {
                  setGeneralError(false);
                }, 3000);
              }}
            >
              <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                <AlertTitle sx={{ fontSize: "16px" }}>{t("downloads.alert_fields.general_error.title")}</AlertTitle>
                {t("downloads.alert_fields.general_error.description")}
              </Alert>
            </Fade>

            <Fade
              in={sessionError}
              timeout={{ enter: 500, exit: 500 }}
              unmountOnExit={true}
              addEndListener={() => {
                window.scrollTo(0, 0);
                setTimeout(() => {
                  setSessionError(false);
                  navigate("/login");
                  logout();
                }, 3000);
              }}
            >
              <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                <AlertTitle sx={{ fontSize: "16px" }}>{t("downloads.alert_fields.session_error.title")}</AlertTitle>
                {t("downloads.alert_fields.session_error.description")}
              </Alert>
            </Fade>
          </Box>

          <Box display="flex" flexDirection="column" alignItems="center" mt="30px">
            <Grid container spacing={4} mt="10px" justifyContent="left">
              <Grid item xs={12}>
                <Accordion defaultExpanded={true} sx={{ backgroundColor: colors.primary[600], borderRadius: "50px" }}>
                  <AccordionSummary
                    sx={{ backgroundColor: colors.greenAccent[700] }}
                    expandIcon={<ExpandMoreIconOutlined />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography component="h1" variant="h3">
                      {t("downloads.tabs.files.title")}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid item sx={{ mt: "15px", mb: "5px" }}>
                      <Link
                        download={true}
                        color="inherit"
                        href="https://heaboo2.sharepoint.com/:b:/s/vendas_pt/EeBZrSrJ6KtPkfPm0VR3nAEBaUt8lBZgwCxHWIzQN--QwA?e=L7qG1H"
                      >
                        {t("downloads.tabs.files.file1")}
                      </Link>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12}>
                <Accordion defaultExpanded={true} sx={{ backgroundColor: colors.primary[600], borderRadius: "50px" }}>
                  <AccordionSummary
                    sx={{ backgroundColor: colors.greenAccent[700] }}
                    expandIcon={<ExpandMoreIconOutlined />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography component="h1" variant="h3">
                      {t("downloads.tabs.images_video.title")}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid item sx={{ mt: "15px", mb: "5px" }}>
                      <Link color="inherit" href="https://vimeo.com/869554634">
                        {t("downloads.tabs.images_video.file1")}
                      </Link>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Downloads;
