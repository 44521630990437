import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  useTheme,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Grid,
  Fade,
  Alert,
  AlertTitle,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import { DataGrid, GridToolbarContainer, GridToolbarFilterButton, GridToolbarDensitySelector } from "@mui/x-data-grid";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import HouseOutlinedIcon from "@mui/icons-material/HouseOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import DoNotDisturbAltOutlinedIcon from "@mui/icons-material/DoNotDisturbAltOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CasinoOutlinedIcon from "@mui/icons-material/CasinoOutlined";
import KeyOutlinedIcon from "@mui/icons-material/KeyOutlined";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import axios from "axios";
import LoadingScreen from "../global/LoadingScreen";
import { AuthContext } from "../../context/authContext";
import * as yup from "yup";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";

const LicenseManager = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [licenses, setLicenses] = useState([]);
  const [currentLicense, setCurrentLicense] = useState(null);
  const [isCreated, setIsCreated] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [open, setOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState();
  const [generalError, setGeneralError] = useState(false);
  const [sessionError, setSessionError] = useState(false);
  const [isEliminated, setIsEliminated] = useState(false);
  const [newKey, setNewKey] = useState("");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  let initialValues = {
    license: "",
    date: "",
    type: "0",
    time: "",
    active: 0,
  };

  const navigate = useNavigate();

  const { currentUser, verifyUser, logout } = useContext(AuthContext);

  const config = {
    headers: {
      "access-token": process.env.REACT_APP_ACCESS_TOKEN,
      "Content-Type": "application/json", // Adjust the content type if needed
    },
    withCredentials: true,
  };

  const licenseSchema = yup.object().shape({
    license: yup.string().required(t("license_manager.schema.license")).default(newKey),
    type: yup.string().required(t("license_manager.schema.type")),
  });

  const verifyLicense = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + `licenses/email/${currentUser?.email}`, config);

      if (response.data.success === 0) {
        setCurrentLicense(0);
      } else if (response.data.data[0].time === 0) {
        setCurrentLicense(1);
      } else if (response.data.data[0].active === 1) {
        setCurrentLicense(2);
      }
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
        logout();
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    verifyUser(currentUser);
    if (!currentUser) {
      navigate("/login");
    } else {
      verifyLicense();
      if (currentLicense !== null && currentLicense !== 2) {
        navigate("/login");
      } else if (currentUser.user_type === 2 || currentUser.user_type === 3) {
        navigate("/");
      }
    }
  }, [currentLicense, currentUser]);

  const handleClose = () => {
    deleteLicense(idToDelete);
    setIdToDelete(null);
    setOpen(false);
    return;
  };

  const deleteLicense = async (id) => {
    try {
      await axios.delete(process.env.REACT_APP_API_CALL + `licenses/${id}`, config);
      getLicenses();
      setIsEliminated(true);
      setOpen(false);
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
      }
    }
  };

  // Function to generate a random uppercase letter or digit
  function getRandomCharacter() {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    const randomIndex = Math.floor(Math.random() * characters.length);
    return characters[randomIndex];
  }

  // Function to generate a random license key
  function generateLicenseKey() {
    const keyParts = [];
    for (let i = 0; i < 4; i++) {
      let part = "";
      for (let j = 0; j < 4; j++) {
        part += getRandomCharacter();
      }
      keyParts.push(part);
    }
    return keyParts.join("-");
  }

  // Function to check if the generated key already exists in the database (pseudo-code)
  const verifyKey = async (key) => {
    let licenses;
    let licenseID = 0;

    const response = await axios.get(process.env.REACT_APP_API_CALL + "licenses", config);

    licenses = response.data.data;

    // The key you're searching for
    const searchKey = key;

    // Function to find the key in the object
    function findLicenseByKey(key2) {
      for (const licenseIndex in licenses) {
        if (licenses[licenseIndex].license === key2) {
          licenseID = parseInt(licenseIndex + 1);
          return licenses[licenseIndex];
        }
      }
      return null; // Key not found
    }

    // Find the license with the searchKey
    const foundLicense = findLicenseByKey(searchKey);

    if (foundLicense) {
      return true;
    } else {
      return false;
    }
  };

  // Generate a unique license key
  function generateUniqueLicenseKey() {
    let key;
    do {
      key = generateLicenseKey();
    } while (!verifyKey(key));
    setNewKey(key);
    initialValues = {
      license: newKey,
      date: "",
      type: "0",
      time: "",
      active: 0,
    };
  }

  const handleClickOpen = (id) => {
    setOpen(true);
    setIdToDelete(id);
  };

  const handleCloseDialog = () => {
    closeMaybe();
    setIdToDelete(null);
    setOpen(false);
    return;
  };

  // const handleClose = () => {
  //   deleteUser(idToDelete);
  //   setIdToDelete(null);
  //   setOpen(false);
  //   return;
  // };

  const closeMaybe = async () => {
    try {
      await axios.get(process.env.REACT_APP_API_CALL + "licenses", config);
      setOpen(false);
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
      }
    }
  };

  const getLicenses = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + "licenses", config);
      setLicenses(response.data.data);
      setIsLoading(false);
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
      }
    }
  };

  const handleFormSubmit = async (values) => {
    const currentDate = new Date();
    const expirationDate = new Date(currentDate);
    expirationDate.setMonth(expirationDate.getMonth() + 6);
    let licenseValues;
    setIsLoading(true);

    if (values.license === "" || values.type === "") {
      setIsLoading(false);
      window.scrollTo(0, 0);
      setIsEmpty(true);
      return;
    } else {
      if (values.type === "0" || values.type === "3") {
        licenseValues = {
          license: newKey,
          date: currentDate,
          type: values.type,
          email: "",
          time: "Perpétua",
          active: 0,
        };
      } else {
        licenseValues = {
          license: newKey,
          date: currentDate,
          type: values.type,
          email: "",
          time: expirationDate,
          active: 0,
        };
      }

      try {
        await axios.post(process.env.REACT_APP_API_CALL + "licenses", licenseValues, config);
        window.scrollTo(0, 0);
        setIsCreated(true);
        setIsLoading(false);
      } catch (error) {
        if (error.response.data.auth === false) {
          setSessionError(true);
        } else {
          setGeneralError(true);
          console.log(error);
        }
      }
    }
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  };

  const style = {
    "& label.Mui-focused": {
      color: colors.primary[100],
      fontSize: "16px",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: colors.primary[100],
      },
    },
    "& .MuiFormHelperText-root": {
      fontSize: "14px",
    },
  };

  useEffect(() => {
    getLicenses();
  }, []);

  const columns = [
    {
      field: "id",
      headerName: t("license_manager.table_fields.id"),
      headerAlign: "left",
      align: "left",
      minWidth: 40,
      maxWidth: 40,
      flex: 1,
    },
    {
      field: "license",
      headerName: t("license_manager.table_fields.license"),
      headerAlign: "left",
      align: "left",
      minWidth: 150,
      maxWidth: 150,
      flex: 1,
    },
    {
      field: "date",
      headerName: t("license_manager.table_fields.date"),
      headerAlign: "left",
      align: "left",
      minWidth: 165,
      maxWidth: 165,
      flex: 1,
    },
    {
      field: "time",
      headerName: t("license_manager.table_fields.time.title"),
      headerAlign: "left",
      align: "left",
      minWidth: 200,
      maxWidth: 200,
      flex: 1,
      renderCell: (params) => {
        if (params.row.time === t("license_manager.table_fields.time.permanent")) {
          // console.log(params.row.time);
          return t("license_manager.table_fields.time.permanent");
        } else if (params.row.time === 0) {
          return t("license_manager.table_fields.time.expired");
        } else {
          const now = new Date();
          const expiry = new Date(params.row.time);
          const timeDifference = expiry - now;

          // console.log(now);
          // console.log(expiry);

          const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
          const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

          return `${days} ${t("license_manager.table_fields.time.days")}, ${hours} ${t("license_manager.table_fields.time.hours")}, ${minutes} ${t("license_manager.table_fields.time.minutes")}, ${seconds} ${t("license_manager.table_fields.time.seconds")}`;
        }
      },
    },
    {
      field: "email",
      headerName: t("license_manager.table_fields.email"),
      headerAlign: "left",
      align: "left",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "type",
      headerName: t("license_manager.table_fields.type.title"),
      headerAlign: "center",
      align: "center",
      minWidth: 300,
      maxWidth: 300,
      flex: 1,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      sortable: false,
      renderCell: ({ row: { type } }) => {
        return (
          <Box
            width="100%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            borderRadius="4px"
            backgroundColor={type === "0" || type === "1" || type === "2" ? colors.redAccent[500] : colors.greenAccent[600]}
          >
            {type === "5" && <HouseOutlinedIcon />}
            {type === "4" && <HouseOutlinedIcon />}
            {type === "3" && <HouseOutlinedIcon />}
            {type === "2" && <ApartmentOutlinedIcon />}
            {type === "1" && <ApartmentOutlinedIcon />}
            {type === "0" && <ApartmentOutlinedIcon />}
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {type === "5" && <>{t("license_manager.table_fields.type.5")}</>}
              {type === "4" && <>{t("license_manager.table_fields.type.4")}</>}
              {type === "3" && <>{t("license_manager.table_fields.type.3")}</>}
              {type === "2" && <>{t("license_manager.table_fields.type.2")}</>}
              {type === "1" && <>{t("license_manager.table_fields.type.1")}</>}
              {type === "0" && <>{t("license_manager.table_fields.type.0")}</>}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "active",
      headerName: t("license_manager.table_fields.active.title"),
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      sortable: false,
      renderCell: ({ row: { active } }) => {
        return (
          <Box
            width="100%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            borderRadius="4px"
            backgroundColor={active === 1 ? colors.greenAccent[600] : colors.redAccent[800]}
          >
            {active === 1 && <CheckCircleOutlineOutlinedIcon />}
            {active === 0 && <DoNotDisturbAltOutlinedIcon />}
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {active === 1 && <>{t("license_manager.table_fields.active.yes")}</>}
              {active === 0 && <>{t("license_manager.table_fields.active.no")}</>}
            </Typography>
          </Box>
        );
      },
    },
    // {
    //   field: "edit",
    //   headerName: "",
    //   minWidth: 90,
    //   maxWidth: 90,
    //   flex: 1,
    //   disableColumnMenu: true,
    //   disableColumnFilter: true,
    //   disableColumnSelector: true,
    //   sortable: false,
    //   renderCell: (params) => {
    //     return (
    //       <Box
    //         width="100%"
    //         m="0 auto"
    //         p="5px"
    //         display="flex"
    //         justifyContent="center"
    //         borderRadius="4px"
    //         backgroundColor={colors.blueAccent[600]}
    //         component={Link}
    //         to={`/users/${params.row.id}`}
    //         sx={{
    //           cursor: "pointer",
    //           textDecoration: "none",
    //         }}
    //       >
    //         <AutoFixHighOutlinedIcon sx={{ textDecoration: "none", color: colors.grey[100] }} />
    //         <Typography color={colors.grey[100]} sx={{ ml: "5px", textDecoration: "none" }}>
    //           Editar
    //         </Typography>
    //       </Box>
    //     );
    //   },
    // },
    {
      field: "delete",
      headerName: "",
      minWidth: 105,
      maxWidth: 105,
      flex: 1,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box
            width="100%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            borderRadius="4px"
            backgroundColor={colors.redAccent[600]}
            onClick={() => {
              handleClickOpen(params.row.id);
            }}
            sx={{ cursor: "pointer" }}
          >
            <DeleteOutlineOutlinedIcon />
            <Typography color={colors.grey[100]} sx={{ ml: "5px", textDecoration: "none" }}>
              {t("license_manager.table_fields.delete")}
            </Typography>
            <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title">
                <Typography
                  justifyContent="center"
                  color={colors.primary[100]}
                  sx={{
                    textDecoration: "none",
                    justifyContent: "center",
                    mt: "5px",
                  }}
                >
                  {t("license_manager.table_fields.delete_warnings.alert_dialog_title")}
                </Typography>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Typography
                    justifyContent="center"
                    color={colors.primary[100]}
                    sx={{
                      textDecoration: "none",
                      fontWeight: "bold",
                      justifyContent: "center",
                    }}
                  >
                    {t("license_manager.table_fields.delete_warnings.alert_dialog_description")}
                  </Typography>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Grid item>
                  <Box
                    m="0 auto"
                    p="5px 7px 5px 5px"
                    display="flex"
                    justifyContent="center"
                    borderRadius="4px"
                    alignItems="center"
                    backgroundColor={colors.redAccent[600]}
                    sx={{
                      cursor: "pointer",
                      textDecoration: "none",
                    }}
                    onClick={handleCloseDialog}
                  >
                    <CloseOutlinedIcon
                      sx={{
                        textDecoration: "none",
                        color: colors.primary[100],
                        justifyContent: "center",
                      }}
                    />
                    <Typography
                      justifyContent="center"
                      color={colors.primary[100]}
                      sx={{
                        ml: "5px",
                        textDecoration: "none",
                        fontWeight: "bold",
                        justifyContent: "center",
                        marginTop: "1px",
                      }}
                    >
                      {t("license_manager.table_fields.delete_warnings.alert_deny")}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item>
                  <Box
                    m="0 auto"
                    p="5px 7px 5px 5px"
                    display="flex"
                    justifyContent="center"
                    borderRadius="4px"
                    alignItems="center"
                    backgroundColor={colors.greenAccent[600]}
                    sx={{
                      cursor: "pointer",
                      textDecoration: "none",
                    }}
                    onClick={handleClose}
                  >
                    <CheckOutlinedIcon
                      sx={{
                        textDecoration: "none",
                        color: colors.primary[100],
                        justifyContent: "center",
                      }}
                    />
                    <Typography
                      justifyContent="center"
                      color={colors.primary[100]}
                      sx={{
                        ml: "5px",
                        textDecoration: "none",
                        fontWeight: "bold",
                        justifyContent: "center",
                        marginTop: "1px",
                      }}
                    >
                     {t("license_manager.table_fields.delete_warnings.alert_confirm")}
                    </Typography>
                  </Box>
                </Grid>
              </DialogActions>
            </Dialog>
          </Box>
        );
      },
    },
  ];

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <Box m="20px">
          <Header title={t("license_manager.title")} subtitle={t("license_manager.subtitle")} />

          <Grid container xs={1} sm={1} md={1} lg={1} xl={1}>
            <Box
              onClick={() => navigate(-1)}
              p="5px 7px 5px 5px"
              display="flex"
              justifyContent="center"
              borderRadius="4px"
              alignItems="center"
              backgroundColor={colors.blueAccent[600]}
              sx={{
                cursor: "pointer",
                textDecoration: "none",
              }}
            >
              <ArrowBackOutlinedIcon
                sx={{
                  textDecoration: "none",
                  color: colors.primary[100],
                  justifyContent: "center",
                }}
              />
              <Typography
                justifyContent="center"
                color={colors.primary[100]}
                sx={{
                  ml: "5px",
                  textDecoration: "none",
                  fontWeight: "bold",
                  justifyContent: "center",
                  marginTop: "1px",
                }}
              >
                {t("general.back_button")}
              </Typography>
            </Box>
          </Grid>
          <Box m="20px">
            <Fade
              in={generalError}
              timeout={{ enter: 500, exit: 500 }}
              unmountOnExit={true}
              addEndListener={() => {
                window.scrollTo(0, 0);
                setTimeout(() => {
                  setGeneralError(false);
                }, 3000);
              }}
            >
              <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                <AlertTitle sx={{ fontSize: "16px" }}>{t("license_manager.alert_fields.general_error.title")}</AlertTitle>
                {t("license_manager.alert_fields.general_error.description")}
              </Alert>
            </Fade>

            <Fade
              in={sessionError}
              timeout={{ enter: 500, exit: 500 }}
              unmountOnExit={true}
              addEndListener={() => {
                window.scrollTo(0, 0);
                setTimeout(() => {
                  setSessionError(false);
                  navigate("/login");
                  logout();
                }, 3000);
              }}
            >
              <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                <AlertTitle sx={{ fontSize: "16px" }}>{t("license_manager.alert_fields.session_error.title")}</AlertTitle>
                {t("license_manager.alert_fields.session_error.description")}
              </Alert>
            </Fade>

            <Fade
              in={isCreated}
              timeout={{ enter: 1000, exit: 1000 }}
              unmountOnExit={true}
              addEndListener={() => {
                setTimeout(() => {
                  setIsCreated(false);
                  window.location.reload();
                }, 3000);
              }}
            >
              <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="success">
                <AlertTitle sx={{ fontSize: "16px" }}>{t("license_manager.alert_fields.is_created.title")}</AlertTitle>
                {t("license_manager.alert_fields.is_created.description")}
              </Alert>
            </Fade>

            <Fade
              in={isEliminated}
              timeout={{ enter: 1000, exit: 1000 }}
              unmountOnExit={true}
              addEndListener={() => {
                setTimeout(() => {
                  setIsEliminated(false);
                }, 3000);
              }}
            >
              <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="success">
                <AlertTitle sx={{ fontSize: "16px" }}>{t("license_manager.alert_fields.is_eliminated.title")}</AlertTitle>
                {t("license_manager.alert_fields.is_eliminated.description")}
              </Alert>
            </Fade>
          </Box>

          <Box>
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={licenseSchema}
              validateOnChange={false}
              validateOnBlur={true}
            >
              {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={4} mt="10px" justifyContent="left">
                    <Grid item xs={12} sm={5} md={2.5} lg={3} xl={1.5} justifyContent="flex-end">
                      <Box
                        p="12px 12px 12px 12px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        borderRadius="4px"
                        backgroundColor={colors.blueAccent[600]}
                        sx={{
                          cursor: "pointer",
                          textDecoration: "none",
                        }}
                        onClick={() => {
                          generateUniqueLicenseKey();
                          // console.log(newKey);
                        }}
                      >
                        <CasinoOutlinedIcon
                          sx={{
                            textDecoration: "none",
                            color: colors.grey[100],
                            justifyContent: "center",
                          }}
                        />
                        <Typography
                          variant="h3"
                          justifyContent="center"
                          color={colors.grey[100]}
                          sx={{
                            ml: "5px",
                            textDecoration: "none",
                            fontWeight: "bold",
                            justifyContent: "center",
                            marginTop: "1px",
                          }}
                        >
                          {t("license_manager.form.buttons.generate_license")}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3.5} xl={3.5}>
                      <TextField
                        fullWidth
                        multiline
                        variant="filled"
                        type="text"
                        label={t("license_manager.form.license")}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={newKey}
                        name="license"
                        error={!!touched.license && !!errors.license}
                        helperText={touched.license && errors.license}
                        inputProps={{ style: { fontSize: 16 } }}
                        InputLabelProps={{ style: { fontSize: 16 } }}
                        sx={style}
                      />
                    </Grid>

                    <Grid item xs={6} sm={6} md={3.5} lg={4} xl={3}>
                      <Typography
                        sx={{
                          textDecoration: "none",
                          marginTop: "-27px",
                          paddingLeft: "6px",
                          paddingBottom: "5px",
                          justifyContent: "left",
                        }}
                        component="h1"
                        variant="h5"
                      >
                        {t("license_manager.form.type.title")}
                      </Typography>
                      <Select
                        fullWidth
                        hiddenLabel
                        variant="filled"
                        labelId="type"
                        id="type"
                        value={values.type}
                        onChange={handleChange("type")}
                        sx={{
                          fontSize: "15px",
                        }}
                      >
                        <MenuItem value={"0"} sx={{ fontSize: "16px" }}>
                          {t("license_manager.form.type.0")}
                        </MenuItem>
                        <MenuItem value={"1"} sx={{ fontSize: "16px" }}>
                          {t("license_manager.form.type.1")}
                        </MenuItem>
                        <MenuItem value={"2"} sx={{ fontSize: "16px" }}>
                          {t("license_manager.form.type.2")}
                        </MenuItem>
                        <MenuItem value={"3"} sx={{ fontSize: "16px" }}>
                          {t("license_manager.form.type.3")}
                        </MenuItem>
                        <MenuItem value={"4"} sx={{ fontSize: "16px" }}>
                          {t("license_manager.form.type.4")}
                        </MenuItem>
                        <MenuItem value={"5"} sx={{ fontSize: "16px" }}>
                          {t("license_manager.form.type.5")}
                        </MenuItem>
                      </Select>
                    </Grid>

                    <Grid item xs={12} sm={5} md={2.5} lg={3} xl={2} justifyContent="flex-end">
                      <Box
                        p="12px 12px 12px 12px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        borderRadius="4px"
                        backgroundColor={colors.blueAccent[600]}
                        sx={{
                          cursor: "pointer",
                          textDecoration: "none",
                        }}
                        onClick={() => {
                          handleFormSubmit({
                            license: values.email,
                            type: values.type,
                          });
                        }}
                      >
                        <KeyOutlinedIcon
                          sx={{
                            textDecoration: "none",
                            color: colors.grey[100],
                            justifyContent: "center",
                          }}
                        />
                        <Typography
                          variant="h3"
                          justifyContent="center"
                          color={colors.grey[100]}
                          sx={{
                            ml: "5px",
                            textDecoration: "none",
                            fontWeight: "bold",
                            justifyContent: "center",
                            marginTop: "1px",
                          }}
                        >
                          {t("license_manager.form.buttons.create_license")}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Box>

          <Box
            m="40px 0 0 0"
            height="70vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.greenAccent[100]} !important`,
              },
              "& .MuiButtonBase-root .MuiButton-root .MuiButton-text .MuiButton-textPrimary .MuiButton-sizeMedium .MuiButton-textSizeMedium .MuiButton-root .MuiButton-text .MuiButton-textPrimary .MuiButton-sizeMedium .MuiButton-textSizeMedium .css-13qp4b7-MuiButtonBase-root-MuiButton-root":
                {
                  color: `${colors.blueAccent[700]} !important`,
                },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
            }}
          >
            <DataGrid
              rowsPerPageOptions={[50, 100, 250, 500, 1000]}
              hideFooterSelectedRowCount
              componentsProps={{
                pagination: {
                  labelRowsPerPage: t("table_custom_translation.label_rows_per_page"),
                },
              }}
              localeText={{
                MuiTablePagination: {
                  labelDisplayedRows: ({ from, to, count }) => `${count} ` + t("table_custom_translation.label_displayed_rows"),
                },
                // Root
                noRowsLabel: t("table_custom_translation.no_rows_label"),
                noResultsOverlayLabel: t("table_custom_translation.no_results_overlay_label"),
                errorOverlayDefaultLabel: t("table_custom_translation.error_overlay_default_label"),
                // Density selector toolbar button text
                toolbarDensity: t("table_custom_translation.toolbar_density"),
                toolbarDensityLabel: t("table_custom_translation.toolbar_density_label"),
                toolbarDensityCompact: t("table_custom_translation.toolbar_density_compact"),
                toolbarDensityStandard: t("table_custom_translation.toolbar_density_standard"),
                toolbarDensityComfortable: t("table_custom_translation.toolbar_density_comfortable"),
                // Columns selector toolbar button text
                toolbarColumns: t("table_custom_translation.toolbar_columns"),
                toolbarColumnsLabel: t("table_custom_translation.toolbar_columns_label"),
                // Filters toolbar button text
                toolbarFilters: t("table_custom_translation.toolbar_filters"),
                toolbarFiltersLabel: t("table_custom_translation.toolbar_filters_label"),
                toolbarFiltersTooltipHide: t("table_custom_translation.toolbar_filters_tooltip_hide"),
                toolbarFiltersTooltipShow: t("table_custom_translation.toolbar_filters_tooltip_show"),
                toolbarFiltersTooltipActive: (count) =>
                  `${count} ${
                    count !== 1
                      ? t("table_custom_translation.toolbar_filters_tooltip_active.filter_plural")
                      : t("table_custom_translation.toolbar_filters_tooltip_active.filter_singular")
                  } ${
                    count !== 1
                      ? t("table_custom_translation.toolbar_filters_tooltip_active.active_plural")
                      : t("table_custom_translation.toolbar_filters_tooltip_active.active_singular")
                  }`,
                // Quick filter toolbar field
                toolbarQuickFilterPlaceholder: t("table_custom_translation.toolbar_quick_filter_placeholder"),
                toolbarQuickFilterLabel: t("table_custom_translation.toolbar_quick_filter_label"),
                toolbarQuickFilterDeleteIconLabel: t("table_custom_translation.toolbar_quick_filter_delete_icon_label"),
                // Export selector toolbar button text
                toolbarExport: t("table_custom_translation.toolbar_export"),
                toolbarExportLabel: t("table_custom_translation.toolbar_export_label"),
                toolbarExportCSV: t("table_custom_translation.toolbar_export_CSV"),
                toolbarExportPrint: t("table_custom_translation.toolbar_export_print"),
                toolbarExportExcel: t("table_custom_translation.toolbar_export_excel"),
                // Columns panel text
                columnsPanelTextFieldLabel: t("table_custom_translation.columns_panel_text_field_label"),
                columnsPanelTextFieldPlaceholder: t("table_custom_translation.columns_panel_text_field_placeholder"),
                columnsPanelDragIconLabel: t("table_custom_translation.columns_panel_drag_icon_label"),
                columnsPanelShowAllButton: t("table_custom_translation.columns_panel_showAll_button"),
                columnsPanelHideAllButton: t("table_custom_translation.columns_panel_hideAll_button"),
                // Filter panel text
                filterPanelAddFilter: t("table_custom_translation.filter_panel_add_filter"),
                filterPanelDeleteIconLabel: t("table_custom_translation.filter_panel_delete_icon_label"),
                filterPanelLinkOperator: t("table_custom_translation.filter_panel_link_operator"),
                filterPanelOperators: t("table_custom_translation.filter_panel_operators"),
                // TODO v6: rename to filterPanelOperator
                filterPanelOperatorAnd: t("table_custom_translation.filter_panel_operator_and"),
                filterPanelOperatorOr: t("table_custom_translation.filter_panel_operator_or"),
                filterPanelColumns: t("table_custom_translation.filter_panel_columns"),
                filterPanelInputLabel: t("table_custom_translation.filter_panel_input_label"),
                filterPanelInputPlaceholder: t("table_custom_translation.filter_panel_input_placeholder"),
                // Filter operators text
                filterOperatorContains: t("table_custom_translation.filter_operator_contains"),
                filterOperatorEquals: t("table_custom_translation.filter_operator_equals"),
                filterOperatorStartsWith: t("table_custom_translation.filter_operator_starts_with"),
                filterOperatorEndsWith: t("table_custom_translation.filter_operator_ends_with"),
                filterOperatorIs: t("table_custom_translation.filter_operator_is"),
                filterOperatorNot: t("table_custom_translation.filter_operator_not"),
                filterOperatorAfter: t("table_custom_translation.filter_operator_after"),
                filterOperatorOnOrAfter: t("table_custom_translation.filter_operator_on_or_after"),
                filterOperatorBefore: t("table_custom_translation.filter_operator_before"),
                filterOperatorOnOrBefore: t("table_custom_translation.filter_operator_on_or_before"),
                filterOperatorIsEmpty: t("table_custom_translation.filter_operator_is_empty"),
                filterOperatorIsNotEmpty: t("table_custom_translation.filter_operator_is_not_empty"),
                filterOperatorIsAnyOf: t("table_custom_translation.filter_operator_is_any_of"),
                // Filter values text
                filterValueAny: t("table_custom_translation.filter_value_any"),
                filterValueTrue: t("table_custom_translation.filter_value_true"),
                filterValueFalse: t("table_custom_translation.filter_value_false"),
                // Column menu text
                columnMenuLabel: t("table_custom_translation.column_menu_label"),
                columnMenuShowColumns: t("table_custom_translation.column_menu_show_columns"),
                columnMenuFilter: t("table_custom_translation.column_menu_filter"),
                columnMenuHideColumn: t("table_custom_translation.column_menu_hide_column"),
                columnMenuUnsort: t("table_custom_translation.column_menu_unsort"),
                columnMenuSortAsc: t("table_custom_translation.column_menu_sort_asc"),
                columnMenuSortDesc: t("table_custom_translation.column_menu_sort_desc"),
                // Column header text
                columnHeaderFiltersTooltipActive: (count) =>
                  `${count} ${
                    count !== 1
                      ? t("table_custom_translation.column_header_filters_tooltip_active.filter_plural")
                      : t("table_custom_translation.column_header_filters_tooltip_active.filter_singular")
                  } ${
                    count !== 1
                      ? t("table_custom_translation.column_header_filters_tooltip_active.active_plural")
                      : t("table_custom_translation.column_header_filters_tooltip_active.active_singular")
                  }`,
                columnHeaderFiltersLabel: t("table_custom_translation.column_header_filters_label"),
                columnHeaderSortIconLabel: t("table_custom_translation.column_header_sort_icon_label"),
                // Rows selected footer text
                footerRowSelected: (count) =>
                  count !== 1
                    ? `${count.toLocaleString()} +` + t("table_custom_translation.footer_row_selected.plural")
                    : `${count.toLocaleString()} ` + t("table_custom_translation.footer_row_selected.singular"),
                // Total row amount footer text
                footerTotalRows: t("table_custom_translation.footer_total_rows"),
                // Total visible row amount footer text
                footerTotalVisibleRows: (visibleCount, totalCount) =>
                  `${visibleCount.toLocaleString()} ` +
                  t("table_custom_translation.footer_total_visible_rows") +
                  `${totalCount.toLocaleString()}`,
                // Checkbox selection text
                checkboxSelectionHeaderName: t("table_custom_translation.checkbox_selection_header_name"),
                checkboxSelectionSelectAllRows: t("table_custom_translation.checkbox_selection_select_all_rows"),
                checkboxSelectionUnselectAllRows: t("table_custom_translation.checkbox_selection_unselect_all_rows"),
                checkboxSelectionSelectRow: t("table_custom_translation.checkbox_selection_select_row"),
                checkboxSelectionUnselectRow: t("table_custom_translation.checkbox_selection_unselect_row"),
                // Boolean cell text
                booleanCellTrueLabel: t("table_custom_translation.boolean_cell_true_label"),
                booleanCellFalseLabel: t("table_custom_translation.boolean_cell_false_label"),
                // Actions cell more text
                actionsCellMore: t("table_custom_translation.actions_cell_more"),
                // Column pinning text
                pinToLeft: t("table_custom_translation.pin_to_left"),
                pinToRight: t("table_custom_translation.pin_to_right"),
                unpin: t("table_custom_translation.unpin"),
                // Tree Data
                treeDataGroupingHeaderName: t("table_custom_translation.tree_data_grouping_header_name"),
                treeDataExpand: t("table_custom_translation.tree_data_expand"),
                treeDataCollapse: t("table_custom_translation.tree_data_collapse"),
                // Grouping columns
                groupingColumnHeaderName: t("table_custom_translation.grouping_column_header_name"),
                groupColumn: (name) => t("table_custom_translation.group_column") + ` ${name}`,
                unGroupColumn: (name) => t("table_custom_translation.un_group_column") + ` ${name}`,
                // Master/detail
                detailPanelToggle: t("table_custom_translation.detail_panel_toggle"),
                expandDetailPanel: t("table_custom_translation.expand_detail_panel"),
                collapseDetailPanel: t("table_custom_translation.collapse_detail_panel"),
                // Row reordering text
                rowReorderingHeaderName: t("table_custom_translation.row_reordering_header_name"),
                // Aggregation
                // aggregationMenuItemHeader: 'Aggregation',
                // aggregationFunctionLabelSum: 'sum',
                // aggregationFunctionLabelAvg: 'avg',
                // aggregationFunctionLabelMin: 'min',
                // aggregationFunctionLabelMax: 'max',
                // aggregationFunctionLabelSize: 'size',
              }}
              loading={isLoading}
              rows={licenses}
              columns={columns}
              components={{ Toolbar: CustomToolbar }}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default LicenseManager;
