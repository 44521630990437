import React from 'react';
function checkKey(key) {
    if (key.includes('#')) return false
    if (key.includes('$')) return false
    if (key.includes('/')) return false
    if (key.includes('[')) return false
    if (key.includes(']')) return false
    return true
}
export default class DynamicTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            items: ['', '', 4.5, 20],
            t: this.props.t,
            index: this.props.index,
            pcCount: props.pcCount,
            editMode: props.editMode,
            hoterway: props.hoterway ? props.hoterway : "0"
        }
        if (props.pepcLayout) {
            this.state.items[0] = props.pepcLayout["div_" + this.state.index]["name"]
            this.state.items[1] = props.pepcLayout["div_" + this.state.index]["nickname"]
            this.state.items[2] = props.pepcLayout["div_" + this.state.index]["caudal_minimo"] || 4.5
            this.state.items[3] = props.pepcLayout["div_" + this.state.index]["caudal_total"] || 20
            this.state.items[4] = props.pepcLayout["div_" + this.state.index]["hoterway"]
            // for (let i = 4; i < props.pcCount; i++)
            //     if (parseFloat(props.pepcLayout["div_" + this.state.index]["pc_" + i]))
            //         this.state.items.push(parseFloat(props.pepcLayout["div_" + this.state.index]["pc_" + i]))
            //     else
            //         this.state.items.push(0)

        }
        else {
            this.state.items.push("")
            this.state.items[2] = 4.5
            this.state.items[3] = 20

            for (let i = 0; i < 3; i++)
                this.state.items.push("0")

        }
        console.log(this.state.items)


    }


    increaseItems() {
        this.state.items.push(0)
        this.handleItemChanged(this.state.items.length - 1, { target: { value: 0 } })
    }

    decreaseItems() {
        this.state.items.pop()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.editMode !== this.props.editMode) {
            this.setState({ ...this.state, editMode: this.props.editMode })
        }
        if (prevProps.pcCount !== this.props.pcCount) {
            if (this.props.pcCount > this.state.items.length - 1)
                for (let i = this.state.items.length - 1; i < this.props.pcCount; i++)
                    this.increaseItems();

            else if (this.props.pcCount < this.state.items.length - 1)
                for (let i = this.state.items.length - 1; i > this.props.pcCount; i--)
                    this.decreaseItems()

            this.setState({ ...this.state, pcCount: this.props.pcCount });
        }
        else return null;
    }
    updateMessage(event) {
        this.setState({
            message: event.target.value
        });
    }

    handleClick() {
        var items = this.state.items;
        items.push(this.state.message);

        this.setState({
            items: items,
            message: ""
        });

    }

    handleCheckChange() {
        this.setState({ ...this.state, hoterway: !this.state.hoterway }, () => {
            this.handleItemChanged(this.state.items.length, null)
        })
    }

    handleItemChanged(i, event) {
        var items = this.state.items;
        if (i === 0 || i === 1 || i === 2 || i === 3) {
            if (checkKey(event.target.value))
                items[i] = event.target.value;
        } else if (i < this.state.items.length) {
            // Convert commas to dots and then parse the value as a float
            items[i] = parseFloat(event.target.value.replace(',', '.'));
        }
        this.setState({
            items: items
        });
        this.props.setPe_PCValues(items.concat(this.state.hoterway))
    }

    handleItemDeleted(i) {
        var items = this.state.items;

        items.splice(i, 1);

        this.setState({
            items: items
        });
    }

    renderRows() {
        var context = this;
        return (
            <>
                {this.state.items.map(function (o, i) {
                    return (
                        <tr key={"item-" + i} className="projectFieldContainerContainer">
                            {/* {i === 0 && <td>
                                <p className='projectFieldTitle'>{context.state.t('manage_project.project.room.name')}</p>
                            </td>} */}
                            {i === 1 && <td>
                                <p className='projectFieldTitle'>{context.state.t('components.cool_table_sim.compartment_name')}</p>
                            </td>}
                            {i === 2 && <td>
                                <p className='projectFieldTitle'>{context.state.t('components.cool_table_sim.caudal_minimo')}</p>
                            </td>}
                            {i === 3 && <td>
                                <p className='projectFieldTitle'>{context.state.t('components.cool_table_sim.caudal_total')}</p>
                            </td>}
                            {/* {i > 1 && <td>
                                <p className='projectFieldTitle'>
                                    {context.state.t('manage_project.project.room.pc_flow.flow') + (i - 1) + " " + context.state.t('manage_project.project.room.pc_flow.unit')}
                                </p>
                            </td>} */}
                            {/* {i === 0 && <td>
                                <input
                                    type="text"
                                    className='projectFieldInput'
                                    value={o}
                                    disabled={true}
                                    onChange={context.handleItemChanged.bind(context, i)}
                                />
                            </td>} */}
                            {i === 1 && <td>
                                <input
                                    type="text"
                                    className='projectFieldInput'
                                    value={o}
                                    disabled={false}
                                    onChange={context.handleItemChanged.bind(context, i)}
                                    maxLength={4}
                                />
                            </td>}
                            {i === 2 && <td>
                                <input
                                    type="number"
                                    step="0.1"
                                    className='projectFieldInput'
                                    disabled={context.state.editMode >= 2 ? true : false}
                                    value={o}
                                    onChange={context.handleItemChanged.bind(context, i)}
                                />
                            </td>}
                            {i === 3 && <td>
                                <input
                                    type="number"
                                    step="0.1"
                                    className='projectFieldInput'
                                    disabled={context.state.editMode >= 2 ? true : false}
                                    value={o}
                                    onChange={context.handleItemChanged.bind(context, i)}
                                />
                            </td>}
                            {/* {i > 1 && <td>
                                <input
                                    type="number"
                                    className='projectFieldInput'
                                    disabled={context.state.editMode >= 2 ? true : false}
                                    value={o}
                                    onChange={context.handleItemChanged.bind(context, i)}
                                />
                            </td>} */}
                        </tr>
                    );
                })}
                {/* <tr style={{ display: 'flex', width: '100%' }}>
                    <td style={{ display: 'flex', width: '100%', flexDirection: 'row-reverse', alignItems: 'center' }}>
                        <input type="checkbox" className='projectFieldInput' defaultChecked={this.state.hoterway} onChange={() => { this.handleCheckChange() }} />
                        <p className='projectFieldTitle'>{context.state.t('manage_project.project.room.not_include_hoterway')}</p>
                    </td>
                </tr> */}
            </>
        )

    }

    render() {
        return (

            <table style={{ display: 'flex', width: '100%' }}>
                <thead>
                    <tr>
                    </tr>
                </thead>
                <tbody>
                    {this.renderRows()}
                </tbody>
            </table>
        );
    }
}