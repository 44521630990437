import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Box, IconButton, Typography, useTheme, Avatar } from "@mui/material";
import { Link } from "react-router-dom";
import { tokens } from "../../theme";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import SupervisedUserCircleOutlinedIcon from "@mui/icons-material/SupervisedUserCircleOutlined";
import QuestionMarkOutlinedIcon from "@mui/icons-material/QuestionMarkOutlined";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import SnippetFolderOutlinedIcon from "@mui/icons-material/SnippetFolderOutlined";
import SourceOutlinedIcon from "@mui/icons-material/SourceOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DisplaySettingsOutlinedIcon from "@mui/icons-material/DisplaySettingsOutlined";
import { AuthContext } from "../../context/authContext";
import axios from "axios";
import { useTranslation } from "react-i18next";

const Item = ({ title, to, icon, selected, setSelected }) => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      marginBottom="20px"
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
      sx={{ marginBottom: "20px" }}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const [currentLicense, setCurrentLicense] = useState(null);
  const [generalError, setGeneralError] = useState(false);
  const [sessionError, setSessionError] = useState(false);

  const { currentUser, logout, verifyUser, calculateRemainingTime } = useContext(AuthContext);
  const navigate = useNavigate();

  const config = {
    headers: {
      "access-token": process.env.REACT_APP_ACCESS_TOKEN,
      "Content-Type": "application/json", // Adjust the content type if needed
    },
    withCredentials: true,
  };

  let imgSize = "150px";

  const verifyLicense = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + `licenses/email/${currentUser?.email}`, config);

      if (response.data.success === 0) {
        setCurrentLicense(0);
      } else if (response.data.data[0].time === 0) {
        setCurrentLicense(1);
      } else if (response.data.data[0].active === 1) {
        setCurrentLicense(2);
      }
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
        logout();
      }
    }
  };

  useEffect(() => {
    verifyUser(currentUser);
    if (!currentUser) {
      navigate("/login");
    } else {
      verifyLicense();
      if (currentLicense !== null && currentLicense !== 2) {
        navigate("/login");
      }
      calculateRemainingTime(currentUser);
    }
  }, [currentLicense, currentUser]);

  return (
    <>
      <Box
        sx={{
          "& .pro-sidebar-inner": {
            background: `${colors.primary[400]} !important`,
          },
          "& .pro-icon-wrapper": {
            backgroundColor: "transparent !important",
          },
          "& .pro-inner-item": {
            padding: "5px 35px 5px 20px !important",
          },
          "& .pro-inner-item:hover": {
            color: "#868dfb !important",
          },
          "& .pro-menu-item.active": {
            color: "#6870fa !important",
          },
        }}
      >
        <ProSidebar collapsedWidth collapsed={isCollapsed}>
          <Menu iconShape="square">
            {/* LOGO AND MENU ICON */}
            <MenuItem
              onClick={() => setIsCollapsed(!isCollapsed)}
              icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
              style={{
                margin: "10px 0 20px 0",
                color: colors.grey[100],
              }}
            >
              {!isCollapsed && (
                <Box display="flex" justifyContent="space-between" alignItems="center" ml="15px">
                  <img alt="company-logo" width="75%" height="100%" src={`../../assets/company_logo.png`} />
                  <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                    <MenuOutlinedIcon />
                  </IconButton>
                </Box>
              )}
            </MenuItem>

            {/* USER */}

            {!isCollapsed && (
              <Box mb="25px">
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Avatar
                    sx={{
                      width: imgSize,
                      height: imgSize,
                    }}
                  >
                    {(() => {
                      if ((currentUser && currentUser.profile_image === "") || (currentUser && currentUser.profile_image === null)) {
                        return (
                          <Avatar
                            sx={{
                              bgcolor: colors.blueAccent[500],
                              width: imgSize,
                              height: imgSize,
                            }}
                          >
                            <Typography variant="h1" color={colors.primary[500]} fontWeight="bold">
                              {Array.from(currentUser.first_name)[0]} {Array.from(currentUser.last_name)[0]}
                            </Typography>
                          </Avatar>
                        );
                      } else {
                        return (
                          <img
                            style={{ borderRadius: "50%", objectFit: "cover" }}
                            alt="profile"
                            width={imgSize}
                            height={imgSize}
                            src={process.env.REACT_APP_API_CALL + "files/" + currentUser.profile_image}
                          />
                        );
                      }
                    })()}
                  </Avatar>
                </Box>
                <Box textAlign="center">
                  {currentUser.user_type === 0 ? (
                    <Typography variant="h4" color={colors.greenAccent[500]} sx={{ m: "10px 0 0 0" }}>
                      {t("sidebar.user_type.0")}
                    </Typography>
                  ) : currentUser.user_type === 1 ? (
                    <Typography variant="h4" color={colors.greenAccent[500]} sx={{ m: "10px 0 0 0" }}>
                      {t("sidebar.user_type.1")}
                    </Typography>
                  ) : currentUser.user_type === 2 ? (
                    <Typography variant="h4" color={colors.greenAccent[500]} sx={{ m: "10px 0 0 0" }}>
                      {t("sidebar.user_type.2")}
                    </Typography>
                  ) : (
                    <></>
                  )}
                  <Typography variant="h2" color={colors.grey[100]} fontWeight="bold" sx={{ m: "5px 0 0 0" }}>
                    {currentUser.first_name + " " + currentUser.last_name}
                  </Typography>
                  <Typography variant="h5" color={colors.blueAccent[500]}>
                    {currentUser.email}
                  </Typography>
                  {currentUser.user_type !== 0 ? (
                    <>
                      <Typography variant="h3" color={colors.grey[100]} sx={{ m: "5px 0 0 0" }}>
                        {t("sidebar.email_supervisor")}
                      </Typography>
                      <Typography variant="h5" color={colors.blueAccent[500]}>
                        {currentUser.email_supervisor}
                      </Typography>
                    </>
                  ) : (
                    <></>
                  )}
                </Box>
              </Box>
            )}

            {/* MENU ITEMS*/}

            {(currentUser && currentUser.user_type === 0) || (currentUser && currentUser.user_type === 1) ? (
              <>
                <Box paddingLeft={isCollapsed ? undefined : "10%"} paddingBottom={isCollapsed ? "475px" : "173px"}>
                  <>
                    <Item title="Dashboard" to="/" icon={<DashboardOutlinedIcon />} selected={selected} setSelected={setSelected} />
                    <Typography variant="h6" color={colors.grey[300]} sx={{ m: "15px 0 5px 20px" }}>
                      {t("sidebar.tabs.management.title")}
                    </Typography>
                    {/* <Item title="Defaults" to="/defaults" icon={<StorageOutlinedIcon />} selected={selected} setSelected={setSelected} /> */}
                    <Item
                      title={t("sidebar.tabs.management.license_manager")}
                      to="/license_manager"
                      icon={<VpnKeyOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <Item
                      title={t("sidebar.tabs.management.create_user")}
                      to="/create_user"
                      icon={<PersonAddAltOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <Item
                      title={t("sidebar.tabs.management.aws_users")}
                      to="/aws_users"
                      icon={<SupervisedUserCircleOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <Typography variant="h6" color={colors.grey[300]} sx={{ m: "15px 0 5px 20px" }}>
                      {t("sidebar.tabs.simulations.title")}
                    </Typography>
                    <Item
                      title={t("sidebar.tabs.simulations.create_simulation")}
                      to="/create_simulation"
                      icon={<NoteAddOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <Item
                      title={t("sidebar.tabs.simulations.my_simulations")}
                      to="/my_simulations"
                      icon={<SnippetFolderOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <Item
                      title={t("sidebar.tabs.simulations.simulations")}
                      to="/simulations"
                      icon={<SourceOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <Typography variant="h6" color={colors.grey[300]} sx={{ m: "15px 0 5px 20px" }}>
                      {t("sidebar.tabs.help.title")}
                    </Typography>
                    <Item
                      title={t("sidebar.tabs.help.frequent_questions")}
                      to="/frequent_questions"
                      icon={<QuestionMarkOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <Item
                      title={t("sidebar.tabs.help.glossary")}
                      to="/glossary"
                      icon={<ListAltOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <Item
                      title={t("sidebar.tabs.help.downloads")}
                      to="/downloads"
                      icon={<FileDownloadOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <Item
                      title={t("sidebar.tabs.help.changelog")}
                      to="/changelog"
                      icon={<DisplaySettingsOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  </>
                </Box>
              </>
            ) : currentUser && currentUser.user_type === 2 ? (
              <>
                <Box paddingLeft={isCollapsed ? undefined : "10%"} paddingBottom={isCollapsed ? "475px" : "173px"}>
                  <Item title="Dashboard" to="/" icon={<DashboardOutlinedIcon />} selected={selected} setSelected={setSelected} />
                  <>
                    <Item title="Dashboard" to="/" icon={<DashboardOutlinedIcon />} selected={selected} setSelected={setSelected} />
                    <Typography variant="h6" color={colors.grey[300]} sx={{ m: "15px 0 5px 20px" }}>
                      {t("sidebar.tabs.management.title")}
                    </Typography>
                    <Item
                      title="Registar Utilizador"
                      to="/create_user"
                      icon={<PersonAddAltOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <Typography variant="h6" color={colors.grey[300]} sx={{ m: "15px 0 5px 20px" }}>
                      {t("sidebar.tabs.simulations.title")}
                    </Typography>
                    <Item
                      title={t("sidebar.tabs.simulations.create_simulation")}
                      to="/create_simulation"
                      icon={<NoteAddOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <Item
                      title={t("sidebar.tabs.simulations.my_simulations")}
                      to="/my_simulations"
                      icon={<SnippetFolderOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <Typography variant="h6" color={colors.grey[300]} sx={{ m: "15px 0 5px 20px" }}>
                      {t("sidebar.tabs.help.title")}
                    </Typography>
                    <Item
                      title={t("sidebar.tabs.help.frequent_questions")}
                      to="/frequent_questions"
                      icon={<QuestionMarkOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <Item
                      title={t("sidebar.tabs.help.glossary")}
                      to="/glossary"
                      icon={<ListAltOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <Item
                      title={t("sidebar.tabs.help.downloads")}
                      to="/downloads"
                      icon={<FileDownloadOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <Item
                      title={t("sidebar.tabs.help.changelog")}
                      to="/changelog"
                      icon={<DisplaySettingsOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  </>
                </Box>
              </>
            ) : (
              <>
                <Box paddingLeft={isCollapsed ? undefined : "10%"} paddingBottom={isCollapsed ? "652px" : "385px"}>
                  <>
                    <Item title="Dashboard" to="/" icon={<DashboardOutlinedIcon />} selected={selected} setSelected={setSelected} />
                    <Typography variant="h6" color={colors.grey[300]} sx={{ m: "15px 0 5px 20px" }}>
                      {t("sidebar.tabs.simulations.title")}
                    </Typography>
                    <Item
                      title={t("sidebar.tabs.simulations.create_simulation")}
                      to="/create_simulation"
                      icon={<NoteAddOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <Item
                      title={t("sidebar.tabs.simulations.my_simulations")}
                      to="/my_simulations"
                      icon={<SnippetFolderOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <Typography variant="h6" color={colors.grey[300]} sx={{ m: "15px 0 5px 20px" }}>
                      {t("sidebar.tabs.help.title")}
                    </Typography>
                    <Item
                      title={t("sidebar.tabs.help.frequent_questions")}
                      to="/frequent_questions"
                      icon={<QuestionMarkOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <Item
                      title={t("sidebar.tabs.help.glossary")}
                      to="/glossary"
                      icon={<ListAltOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <Item
                      title={t("sidebar.tabs.help.downloads")}
                      to="/downloads"
                      icon={<FileDownloadOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <Item
                      title={t("sidebar.tabs.help.changelog")}
                      to="/changelog"
                      icon={<DisplaySettingsOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  </>
                </Box>
              </>
            )}
          </Menu>
        </ProSidebar>
      </Box>
    </>
  );
};

export default Sidebar;
