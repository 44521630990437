import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  TextField,
  Alert,
  AlertTitle,
  useTheme,
  Typography,
  Container,
  Avatar,
  Grid,
  Fade,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import HowToRegOutlinedIcon from "@mui/icons-material/HowToRegOutlined";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { Formik } from "formik";
import * as yup from "yup";
import LoadingScreen from "../global/LoadingScreen";
import { AuthContext } from "../../context/authContext";
import { tokens } from "../../theme";
import YupPassword from "yup-password";
import { useTranslation } from "react-i18next";
YupPassword(yup);


const Register = () => {
  const { t } = useTranslation();
  const [generalError, setGeneralError] = useState(false);
  const [sessionError, setSessionError] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [completeRegister, setCompleteRegister] = useState(false);
  const [licenseError, setLicenseError] = useState(false);
  const [errorMessageAlreadyCreated, setErrorMessageAlreadyCreated] = useState(false);
  const [profileComplete, setProfileComplete] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [error, setError] = useState(false);
  const [imageAdded, setImageAdded] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [limitError, setLimitError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [isWarning, setIsWarning] = useState(true);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  let initialValues = 0;

  const navigate = useNavigate();

  const { currentUser, logout } = useContext(AuthContext);

  const userSchema = yup.object().shape({
    first_name: yup.string().required(t("register.schema.first_name")).typeError(t("register.schema.first_name")),
    last_name: yup.string().required(t("register.schema.last_name")).typeError(t("register.schema.last_name")),
    license: yup.string().required(t("register.schema.license")).typeError(t("register.schema.license")),
    email: yup.string().email(t("register.schema.email.string")).required(t("register.schema.email.required")),
    email_supervisor: yup
      .string()
      .email(t("register.schema.email_supervisor.string"))
      .required(t("register.schema.email_supervisor.required"))
      .typeError(t("register.schema.email_supervisor.required")),
    password: completeRegister
      ? yup
          .string()
          .password()
          .required(t("register.schema.password.required"))
          .min(8, t("register.schema.password.min"))
          .minLowercase(1, t("register.schema.password.min_lower_case"))
          .minUppercase(1, t("register.schema.password.min_upper_case"))
          .minNumbers(1, t("register.schema.password.min_numbers"))
          .minSymbols(0, t("register.schema.password.min_symbols"))
      : yup.string(),
    password2: completeRegister
      ? yup
          .string()
          .password()
          .required(t("register.schema.password2.required"))
          .min(8, t("register.schema.password2.min"))
          .minLowercase(1, t("register.schema.password2.min_lower_case"))
          .minUppercase(1, t("register.schema.password2.min_upper_case"))
          .minNumbers(1, t("register.schema.password2.min_numbers"))
          .minSymbols(0, t("register.schema.password2.min_symbols"))
          .oneOf([yup.ref("password"), null], t("register.schema.password2.one_of"))
      : yup.string(),
  });

  useEffect(() => {
    if (!currentUser || currentUser.license !== null) {
      navigate("/");
    }
    getEmails();
  }, [currentUser]);

  const handleSubmit = async (event) => {
    handleAxios(event.target.files[0]);
    setSelectedFile(event.target.files[0]);
    setIsImageLoading(true);
    setTimeout(() => {
      setIsImageLoading(false);
    }, 2000);
  };

  const handleAxios = async (event) => {
    const formData = new FormData();
    formData.append("file", event);

    try {
      await axios({
        method: "post",
        url: process.env.REACT_APP_API_CALL + "upload/",
        data: formData,
        headers: { "Content-Type": "multipart/form-data", "access-token": process.env.REACT_APP_ACCESS_TOKEN },
      });
      setError(false);
      setImageAdded(true);
    } catch (error) {
      setError(true);
      setLimitError(true);
      console.log(error);
    }
  };

  if (currentUser) {
    initialValues = {
      first_name: currentUser.first_name,
      last_name: currentUser.last_name,
      email: currentUser.email,
      email_supervisor: currentUser.email_supervisor,
      profile_image: currentUser.profile_image,
      user_type: currentUser.user_type,
      license: currentUser.license,
    };
  }

  const style = {
    "& label.Mui-focused": {
      color: colors.primary[100],
      fontSize: "16px",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: colors.primary[100],
      },
    },
    "& .MuiFormHelperText-root": {
      fontSize: "14px",
    },
  };

  const config = {
    headers: {
      "access-token": process.env.REACT_APP_ACCESS_TOKEN,
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  const config2 = {
    headers: {
      "access-token": process.env.REACT_APP_ACCESS_TOKEN,
      "Content-Type": "application/json", // Adjust the content type if needed
    },
  };

  const getEmails = async () => {
    setIsLoading(true);

    const response = await axios.get(process.env.REACT_APP_API_CALL + `users/${currentUser.email}`, config2);

    if (response.data.data[0].password === null || response.data.data[0].password === undefined) {
      setCompleteRegister(true);
    } else {
      setCompleteRegister(false);
    }

    setIsLoading(false);
  };

  const handleFormSubmit = async (values) => {
    try {
      let valores;
      let licenseValores;
      let licenses;

      const response = await axios.get(process.env.REACT_APP_API_CALL + "licenses/", config2);

      licenses = response.data.data;

      // The key you're searching for
      const searchKey = values.license;

      // Function to find the key in the object
      function findLicenseByKey(key) {
        for (const licenseIndex in licenses) {
          if (licenses[licenseIndex].license === key) {
            return licenses[licenseIndex];
          }
        }
        return null; // Key not found
      }

      // Find the license with the searchKey
      const foundLicense = findLicenseByKey(searchKey);

      if (foundLicense) {
        const response2 = await axios.get(process.env.REACT_APP_API_CALL + `licenses/${foundLicense.id}`, config2);

        setLicenseError(false);

        if (selectedFile && error === true) {
          valores = {
            id: currentUser.id,
            first_name: values.first_name,
            last_name: values.last_name,
            email: currentUser.email,
            email_supervisor: values.email_supervisor,
            profile_image: "",
            user_type: currentUser.user_type,
            license: values.license,
          };
        } else if (selectedFile && error === false) {
          valores = {
            id: currentUser.id,
            first_name: values.first_name,
            last_name: values.last_name,
            email: currentUser.email,
            email_supervisor: values.email_supervisor,
            profile_image: selectedFile.name,
            user_type: currentUser.user_type,
            license: values.license,
          };
        } else {
          valores = {
            id: currentUser.id,
            first_name: values.first_name,
            last_name: values.last_name,
            email: currentUser.email,
            email_supervisor: values.email_supervisor,
            profile_image: currentUser.profile_image,
            user_type: currentUser.user_type,
            license: values.license,
          };
        }

        licenseValores = {
          id: response2.data.data[0].id,
          license: response2.data.data[0].license,
          date: response2.data.data[0].date,
          email: currentUser?.email,
          type: response2.data.data[0].type,
          time: response2.data.data[0].time,
          active: 1,
        };

        await axios.patch(process.env.REACT_APP_API_CALL + "licenses/", licenseValores, config2);

        await axios.patch(process.env.REACT_APP_API_CALL + "users/", valores, config2);

        localStorage.setItem("user", JSON.stringify(valores));
        setProfileComplete(true);
        return;
        // setHide(true);
      } else {
        setLicenseError(true);
        return;
      }
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
      }
    }
  };

  const handleFormSubmitComplete = async (values) => {
    try {
      let valores;
      let valores2;
      let licenseValores;
      let licenses;

      const response = await axios.get(process.env.REACT_APP_API_CALL + "licenses/", config2);

      licenses = response.data.data;

      // The key you're searching for
      const searchKey = values.license;

      // Function to find the key in the object
      function findLicenseByKey(key) {
        for (const licenseIndex in licenses) {
          if (licenses[licenseIndex].license === key) {
            return licenses[licenseIndex];
          }
        }
        return null; // Key not found
      }

      // Find the license with the searchKey
      const foundLicense = findLicenseByKey(searchKey);

      if (foundLicense) {
        const response2 = await axios.get(process.env.REACT_APP_API_CALL + `licenses/${foundLicense.id}`, config2);

        setLicenseError(false);

        if (values.password === values.password2) {
          if (selectedFile && error === true) {
            valores = {
              id: currentUser.id,
              first_name: values.first_name,
              last_name: values.last_name,
              email: currentUser.email,
              email_supervisor: currentUser.email_supervisor,
              profile_image: "",
              password: values.password,
              user_type: currentUser.user_type,
              license: values.license,
            };
            valores2 = {
              id: currentUser.id,
              first_name: values.first_name,
              last_name: values.last_name,
              email: currentUser.email,
              email_supervisor: currentUser.email_supervisor,
              profile_image: "",
              user_type: currentUser.user_type,
              license: values.license,
            };
          } else if (selectedFile && error === false) {
            valores = {
              id: currentUser.id,
              first_name: values.first_name,
              last_name: values.last_name,
              email: currentUser.email,
              email_supervisor: currentUser.email_supervisor,
              profile_image: selectedFile.name,
              password: values.password,
              user_type: currentUser.user_type,
              license: values.license,
            };
            valores2 = {
              id: currentUser.id,
              first_name: values.first_name,
              last_name: values.last_name,
              email: currentUser.email,
              email_supervisor: currentUser.email_supervisor,
              profile_image: "",
              user_type: currentUser.user_type,
              license: values.license,
            };
          } else {
            valores = {
              id: currentUser.id,
              first_name: values.first_name,
              last_name: values.last_name,
              email: currentUser.email,
              email_supervisor: currentUser.email_supervisor,
              profile_image: currentUser.profile_image,
              password: values.password,
              user_type: currentUser.user_type,
              license: values.license,
            };
            valores2 = {
              id: currentUser.id,
              first_name: values.first_name,
              last_name: values.last_name,
              email: currentUser.email,
              email_supervisor: currentUser.email_supervisor,
              profile_image: "",
              user_type: currentUser.user_type,
              license: values.license,
            };
          }

          licenseValores = {
            id: response2.data.data[0].id,
            license: response2.data.data[0].license,
            date: response2.data.data[0].date,
            email: currentUser?.email,
            type: response2.data.data[0].type,
            time: response2.data.data[0].time,
            active: 1,
          };

          await axios.patch(process.env.REACT_APP_API_CALL + "users/password-reset/", valores, config);

          await axios.patch(process.env.REACT_APP_API_CALL + "licenses/", licenseValores, config2);

          localStorage.setItem("user", JSON.stringify(valores2));
          setProfileComplete(true);
          // setHide(true);
        } else {
          console.log("Error!");
        }
      } else {
        setLicenseError(true);
        return;
      }
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <Container component="main" maxWidth="xs">
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: "25px", marginBottom: "100px" }}></Box>

            <Fade
              in={generalError}
              timeout={{ enter: 500, exit: 500 }}
              unmountOnExit={true}
              addEndListener={() => {
                window.scrollTo(0, 0);
                setTimeout(() => {
                  setGeneralError(false);
                }, 3000);
              }}
            >
              <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                <AlertTitle sx={{ fontSize: "16px" }}>{t("register.alert_fields.general_error.title")}</AlertTitle>
                {t("register.alert_fields.general_error.description")}
              </Alert>
            </Fade>

            <Fade
              in={sessionError}
              timeout={{ enter: 500, exit: 500 }}
              unmountOnExit={true}
              addEndListener={() => {
                window.scrollTo(0, 0);
                setTimeout(() => {
                  setSessionError(false);
                  navigate("/login");
                  logout();
                }, 3000);
              }}
            >
              <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                <AlertTitle sx={{ fontSize: "16px" }}>{t("register.alert_fields.session_error.title")}</AlertTitle>
                {t("register.alert_fields.session_error.description")}
              </Alert>
            </Fade>

            <Fade
              in={isRegistered}
              timeout={{ enter: 500, exit: 500 }}
              unmountOnExit={true}
              addEndListener={() => {
                setTimeout(() => {
                  setIsRegistered(false);
                }, 3000);
              }}
            >
              <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="success">
                <AlertTitle sx={{ fontSize: "16px" }}>{t("register.alert_fields.is_registered.title")}</AlertTitle>
                {t("register.alert_fields.is_registered.description")}
              </Alert>
            </Fade>

            <Fade
              in={errorMessageAlreadyCreated}
              timeout={{ enter: 500, exit: 500 }}
              unmountOnExit={true}
              addEndListener={() => {
                setTimeout(() => {
                  setErrorMessageAlreadyCreated(false);
                }, 3000);
              }}
            >
              <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                <AlertTitle sx={{ fontSize: "16px" }}>{t("register.alert_fields.error_message_already_created.title")}</AlertTitle>
                {t("register.alert_fields.error_message_already_created.description")}
              </Alert>
            </Fade>

            <Fade
              in={licenseError}
              timeout={{ enter: 500, exit: 500 }}
              unmountOnExit={true}
              addEndListener={() => {
                setTimeout(() => {
                  setLicenseError(false);
                }, 3000);
              }}
            >
              <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                <AlertTitle sx={{ fontSize: "16px" }}>{t("register.alert_fields.license_error.title")}</AlertTitle>
                {t("register.alert_fields.license_error.description")}
              </Alert>
            </Fade>

            <Fade
              in={profileComplete}
              timeout={{ enter: 500, exit: 500 }}
              unmountOnExit={true}
              addEndListener={() => {
                setTimeout(() => {
                  setProfileComplete(false);
                  navigate(`/`);
                  window.location.reload();
                }, 3000);
              }}
            >
              <Alert severity="success">
                <AlertTitle>{t("register.alert_fields.profile_complete.title")}</AlertTitle>
                {t("register.alert_fields.profile_complete.description")}
              </Alert>
            </Fade>

            <Fade
              in={imageAdded}
              timeout={{ enter: 500, exit: 500 }}
              unmountOnExit={true}
              addEndListener={() => {
                setTimeout(() => {
                  setImageAdded(false);
                }, 2000);
              }}
            >
              <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="success">
                <AlertTitle sx={{ fontSize: "16px" }}>{t("register.alert_fields.image_added.title")}</AlertTitle>
                {t("register.alert_fields.image_added.description")}
              </Alert>
            </Fade>

            <Fade
              in={limitError}
              timeout={{ enter: 500, exit: 500 }}
              unmountOnExit={true}
              addEndListener={() => {
                setTimeout(() => {
                  setLimitError(false);
                }, 3000);
              }}
            >
              <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                <AlertTitle sx={{ fontSize: "16px" }}>{t("register.alert_fields.limit_error.title")}</AlertTitle>
                {t("register.alert_fields.limit_error.description")}
              </Alert>
            </Fade>

            <Fade
              in={isWarning}
              timeout={{ enter: 500, exit: 500 }}
              unmountOnExit={true}
              addEndListener={() => {
                setTimeout(() => {
                  setIsWarning(false);
                }, 8000);
              }}
            >
              <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="info">
                <AlertTitle sx={{ fontSize: "16px" }}>{t("register.alert_fields.is_warning.title")}</AlertTitle>
                {t("register.alert_fields.is_warning.description")}
              </Alert>
            </Fade>

            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <HowToRegOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h2">
              {t("register.title")}
            </Typography>

            <Box display="flex" justifyContent="center" alignItems="center">
              <Avatar
                sx={{
                  marginTop: "10px",
                  width: "100px",
                  height: "100px",
                }}
              >
                {(() => {
                  if (selectedFile && isImageLoading === false) {
                    return (
                      <img
                        style={{ borderRadius: "50%", objectFit: "cover" }}
                        alt="profile"
                        width="100px"
                        height="100px"
                        src={process.env.REACT_APP_API_CALL + "files/" + selectedFile.name}
                      />
                    );
                  } else if (selectedFile && isImageLoading === true) {
                    return <CircularProgress style={{ color: colors.greenAccent[500] }} />;
                  } else {
                    return <InsertPhotoOutlinedIcon sx={{ fontSize: "50px" }} />;
                  }
                })()}
              </Avatar>
            </Box>

            <Button
              style={{
                backgroundColor: colors.blueAccent[300],
                color: "#FFFFFF",
              }}
              fullWidth
              variant="contained"
              component="label"
              sx={{
                mt: 3,
                color: colors.blueAccent[500],
              }}
            >
              <Typography variant="h4" fontWeight="bold" color={colors.primary[500]}>
                {t("register.form.photo_title")}
              </Typography>
              <input type="file" onChange={handleSubmit} label="Escolha a Imagem" hidden />
            </Button>

            {completeRegister === true ? (
              <Formik
                onSubmit={handleFormSubmitComplete}
                initialValues={initialValues}
                validationSchema={userSchema}
                validateOnChange={false}
                validateOnBlur={true}
              >
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                  <form onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2} mt="10px">
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          variant="filled"
                          type="text"
                          label={t("register.form.first_name")}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.first_name}
                          name="first_name"
                          error={!!touched.first_name && !!errors.first_name}
                          helperText={touched.first_name && errors.first_name}
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          sx={style}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          variant="filled"
                          type="text"
                          label={t("register.form.last_name")}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.last_name}
                          name="last_name"
                          error={!!touched.last_name && !!errors.last_name}
                          helperText={touched.last_name && errors.last_name}
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          sx={style}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          variant="filled"
                          type="text"
                          label={t("register.form.email")}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.email}
                          name="email"
                          error={!!touched.email && !!errors.email}
                          helperText={touched.email && errors.email}
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          sx={style}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          variant="filled"
                          type="text"
                          label={t("register.form.email_supervisor")}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          disabled={completeRegister}
                          value={values.email_supervisor}
                          name="email_supervisor"
                          error={!!touched.email_supervisor && !!errors.email_supervisor}
                          helperText={touched.email_supervisor && errors.email_supervisor}
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          sx={style}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          variant="filled"
                          type="text"
                          label={t("register.form.license")}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.license}
                          name="license"
                          error={!!touched.license && !!errors.license}
                          helperText={touched.license && errors.license}
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          sx={style}
                        />
                      </Grid>

                      <>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            variant="filled"
                            type={showOldPassword ? "text" : "password"}
                            label={t("register.form.password")}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.password}
                            name="password"
                            disabled={false}
                            error={!!touched.password && !!errors.password}
                            helperText={touched.password && errors.password}
                            InputProps={{
                              style: { fontSize: 16 },
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={() => setShowOldPassword(!showOldPassword)} edge="end">
                                    {showOldPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            sx={style}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            variant="filled"
                            type={showNewPassword ? "text" : "password"}
                            label={t("register.form.password2")}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.password2}
                            name="password2"
                            disabled={false}
                            error={!!touched.password2 && !!errors.password2}
                            helperText={touched.password2 && errors.password2}
                            InputProps={{
                              style: { fontSize: 16 },
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() => setShowNewPassword(!showNewPassword)}
                                    edge="end"
                                    // style={{ marginRight: "0px" }}
                                  >
                                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            sx={style}
                          />
                        </Grid>
                      </>
                    </Grid>

                    <Button
                      type="submit"
                      color="secondary"
                      fullWidth
                      variant="contained"
                      sx={{
                        mt: 3,
                        mb: 2,
                        fontWeight: "bold",
                        color: colors.primary[500],
                      }}
                    >
                      <Typography variant="h4" fontWeight="bold" color={colors.primary[500]}>
                        {t("register.form.buttons.register")}
                      </Typography>
                    </Button>
                  </form>
                )}
              </Formik>
            ) : (
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={userSchema}
                validateOnChange={false}
                validateOnBlur={true}
              >
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                  <form onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2} mt="10px">
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          variant="filled"
                          type="text"
                          label={t("register.form.first_name")}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.first_name}
                          name="first_name"
                          error={!!touched.first_name && !!errors.first_name}
                          helperText={touched.first_name && errors.first_name}
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          sx={style}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          variant="filled"
                          type="text"
                          label={t("register.form.last_name")}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.last_name}
                          name="last_name"
                          error={!!touched.last_name && !!errors.last_name}
                          helperText={touched.last_name && errors.last_name}
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          sx={style}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          variant="filled"
                          type="text"
                          label={t("register.form.email")}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.email}
                          name="email"
                          error={!!touched.email && !!errors.email}
                          helperText={touched.email && errors.email}
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          sx={style}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          variant="filled"
                          type="text"
                          label={t("register.form.email_supervisor")}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          disabled={completeRegister}
                          value={values.email_supervisor}
                          name="email_supervisor"
                          error={!!touched.email_supervisor && !!errors.email_supervisor}
                          helperText={touched.email_supervisor && errors.email_supervisor}
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          sx={style}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          variant="filled"
                          type="text"
                          label={t("register.form.license")}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.license}
                          name="license"
                          error={!!touched.license && !!errors.license}
                          helperText={touched.license && errors.license}
                          inputProps={{ style: { fontSize: 16 } }}
                          InputLabelProps={{ style: { fontSize: 16 } }}
                          sx={style}
                        />
                      </Grid>
                    </Grid>

                    <Button
                      type="submit"
                      color="secondary"
                      fullWidth
                      variant="contained"
                      sx={{
                        mt: 3,
                        mb: 2,
                        fontWeight: "bold",
                        color: colors.primary[500],
                      }}
                    >
                      <Typography variant="h4" fontWeight="bold" color={colors.primary[500]}>
                        {t("register.form.buttons.register")}
                      </Typography>
                    </Button>
                  </form>
                )}
              </Formik>
            )}

            {/* <Grid container justifyContent="flex-end">
              <Grid item>
                <Typography justifyContent="end" variant="h5" color={colors.grey[100]} component={Link} to="/login">
                  Já possuí conta? Faça login aqui!
                </Typography>
              </Grid>
            </Grid> */}
          </Box>
        </Container>
      )}
    </>
  );
};

export default Register;
