import { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Box,
  Button,
  TextField,
  Alert,
  AlertTitle,
  Typography,
  useTheme,
  Avatar,
  Container,
  Grid,
  Fade,
  InputAdornment,
  IconButton,
} from "@mui/material";
import axios from "axios";
import { Formik } from "formik";
import * as yup from "yup";
import LoadingScreen from "../global/LoadingScreen";
import { AuthContext } from "../../context/authContext";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { tokens } from "../../theme";
import { useTranslation } from "react-i18next";
import Topbar from "../global/Topbar";

const initialValues = {
  email: "",
  password: "",
};

const initialValuesEmail = {
  email: "",
};

const Login = () => {
  const { t } = useTranslation();
  const [isSent, setIsSent] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [isNone, setIsNone] = useState(false);
  const [choices, setChoices] = useState(true);
  const [complete, setComplete] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [generalError, setGeneralError] = useState(false);
  const [sessionError, setSessionError] = useState(false);
  const [errorMessageUser, setErrorMessageUser] = useState(false);
  const [errorMessagePassword, setErrorMessagePassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();

  const userSchema = yup.object().shape({
    email: yup.string().email(t("login.schema.email.string")).required(t("login.schema.email.require")),
    password: yup
      .string()
      .password()
      .required(t("login.schema.password.required"))
      .min(8, t("login.schema.password.min"))
      .minLowercase(1, t("login.schema.password.min_lower_case"))
      .minUppercase(1, t("login.schema.password.min_upper_case"))
      .minNumbers(1, t("login.schema.password.min_numbers"))
      .minSymbols(0, t("login.schema.password.min_symbols")),
  });

  const emailSchema = yup.object().shape({
    email: yup.string().email(t("login.schema.email.string")).required(t("login.schema.email.require")),
  });

  const config = {
    headers: {
      "access-token": process.env.REACT_APP_ACCESS_TOKEN,
      "Content-Type": "application/json", // Adjust the content type if needed
    },
    withCredentials: true,
  };

  const { currentUser, login, logout } = useContext(AuthContext);
  let currentLicense = 10;

  useEffect(() => {
    // setIsLoading(true);
    if (currentUser && currentUser.license !== null) {
      // console.log(currentUser);
      navigate("/");
    } else {
      logout();
    }

    // setIsLoading(false);
  }, []);

  const style = {
    "& label.Mui-focused": {
      color: colors.primary[100],
      fontSize: "16px",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: colors.primary[100],
      },
    },
    "& .MuiFormHelperText-root": {
      fontSize: "14px",
    },
  };

  const handleFormSubmit = async (values) => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + `licenses/email/${values.email}`, config);

      if (response.data.data.length === 0) {
        currentLicense = 0;
      } else if (
        response.data.data[0].time === "0" ||
        response.data.data[0].time === 0 ||
        response.data.data[0].active === 0 ||
        response.data.data[0].active === "0"
      ) {
        currentLicense = 1;
      } else if (response.data.data[0].active === 1) {
        currentLicense = 2;
      }

      const response2 = await axios.get(process.env.REACT_APP_API_CALL + `users/${values.email}`, config);

      if (response2.data.data.length <= 0) {
        setErrorMessageUser(true);
      } else {
        let compareBody = {
          id: response2.data.data[0].id,
          first_name: response2.data.data[0].first_name,
          last_name: response2.data.data[0].last_name,
          email: values.email,
          email_supervisor: response2.data.data[0].email_supervisor,
          profile_image: response2.data.data[0].profile_image,
          password: values.password,
          user_type: response2.data.data[0].user_type,
          license: response2.data.data[0].license,
        };
        let valores = {
          id: response2.data.data[0].id,
          first_name: response2.data.data[0].first_name,
          last_name: response2.data.data[0].last_name,
          email: values.email,
          email_supervisor: response2.data.data[0].email_supervisor,
          profile_image: response2.data.data[0].profile_image,
          user_type: response2.data.data[0].user_type,
          license: response2.data.data[0].license,
        };
        const response3 = await axios.post(process.env.REACT_APP_API_CALL + "users/verify", compareBody, config);

        if (response3.data.success !== 1) {
          setErrorMessagePassword(true);
          setErrorMessageUser(false);
          setIsSent(false);
          setIsNone(false);
          setIsExpired(false);
          return;
        } else {
          if (currentLicense === 1) {
            setIsExpired(true);
            setErrorMessagePassword(false);
            setErrorMessageUser(false);
            setIsSent(false);
            setIsNone(false);
            return;
          } else if (currentLicense === 0) {
            localStorage.setItem("user", JSON.stringify(valores));
            setIsNone(true);
            setErrorMessagePassword(false);
            setErrorMessageUser(false);
            setIsSent(false);
            setIsExpired(false);
            return;
          } else {
            setIsSent(true);
            setIsNone(false);
            setErrorMessagePassword(false);
            setErrorMessageUser(false);
            setIsExpired(false);
            setTimeout(() => {
              login(values);
            }, 1000);
          }
        }
      }
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
        logout();
      }
    }
  };

  const handleFormSubmitEmail = async (values) => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + `licenses/email/${values.email}`, config);

      if (response.data.data.length === 0) {
        currentLicense = 0;
      } else if (
        response.data.data[0].time === "0" ||
        response.data.data[0].time === 0 ||
        response.data.data[0].active === 0 ||
        response.data.data[0].active === "0"
      ) {
        currentLicense = 1;
      } else if (response.data.data[0].active === 1) {
        currentLicense = 2;
      }

      const response2 = await axios.get(process.env.REACT_APP_API_CALL + `users/${values.email}`, config);

      if (response2.data.data.length <= 0) {
        setErrorMessageUser(true);
      } else if (
        response2.data.data[0].password !== null &&
        response2.data.data[0].password !== undefined &&
        response2.data.data[0].license !== undefined &&
        response2.data.data[0].license !== null
      ) {
        setIsRegistered(true);
      } else if (
        (response2.data.data[0].password === null && currentLicense === 0) ||
        response2.data.data[0].password === undefined ||
        currentLicense === 0
      ) {
        let valores = {
          id: response2.data.data[0].id,
          first_name: response2.data.data[0].first_name,
          last_name: response2.data.data[0].last_name,
          email: response2.data.data[0].email,
          email_supervisor: response2.data.data[0].email_supervisor,
          profile_image: response2.data.data[0].profile_image,
          user_type: response2.data.data[0].user_type,
          license: response2.data.data[0].license,
        };
        setIsComplete(true);
        localStorage.setItem("user", JSON.stringify(valores));
      }
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
        <Topbar />
          <Container component="main" maxWidth="xs">
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: "-25px" }}>
                <img alt="company-logo" width="100%" height="auto" src={`../../assets/company_logo.png`} />
              </Box>
              <Typography component="h1" variant="h2" mb="30px" mt="-50px">
                AWS
              </Typography>

              <Fade
                in={generalError}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  window.scrollTo(0, 0);
                  setTimeout(() => {
                    setGeneralError(false);
                    window.location.reload();
                  }, 3000);
                }}
              >
                <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                  <AlertTitle sx={{ fontSize: "16px" }}>{t("login.alert_fields.general_error.title")}</AlertTitle>
                  {t("login.alert_fields.general_error.description")}
                </Alert>
              </Fade>

              <Fade
                in={sessionError}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  window.scrollTo(0, 0);
                  setTimeout(() => {
                    setSessionError(false);
                    navigate("/login");
                    logout();
                  }, 3000);
                }}
              >
                <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                  <AlertTitle sx={{ fontSize: "16px" }}>{t("login.alert_fields.session_error.title")}</AlertTitle>
                  {t("login.alert_fields.session_error.description")}
                </Alert>
              </Fade>

              <Fade
                in={isExpired}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  setTimeout(() => {
                    setIsExpired(false);
                    window.location.reload();
                  }, 3000);
                }}
              >
                <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                  <AlertTitle sx={{ fontSize: "16px" }}>{t("login.alert_fields.is_expired.title")}</AlertTitle>
                  {t("login.alert_fields.is_expired.description")}
                </Alert>
              </Fade>

              <Fade
                in={isNone}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  setTimeout(() => {
                    setIsNone(false);
                    navigate(`/register/`);
                    window.location.reload();
                  }, 3000);
                }}
              >
                <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="warning">
                  <AlertTitle sx={{ fontSize: "16px" }}>{t("login.alert_fields.is_none.title")}</AlertTitle>
                  {t("login.alert_fields.is_none.description")}
                </Alert>
              </Fade>

              <Fade
                in={isSent}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  setTimeout(() => {
                    setIsSent(false);
                    window.location.reload();
                  }, 2000);
                }}
              >
                <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="success">
                  <AlertTitle sx={{ fontSize: "16px" }}>{t("login.alert_fields.is_sent.title")}</AlertTitle>
                  {t("login.alert_fields.is_sent.description")}
                </Alert>
              </Fade>

              <Fade
                in={isComplete}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  setTimeout(() => {
                    setIsComplete(false);
                    navigate("/register/");
                    window.location.reload();
                  }, 3000);
                }}
              >
                <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="success">
                  <AlertTitle sx={{ fontSize: "16px" }}>{t("login.alert_fields.is_complete.title")}</AlertTitle>
                  {t("login.alert_fields.is_complete.description")}
                </Alert>
              </Fade>

              <Fade
                in={errorMessageUser}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  setTimeout(() => {
                    setErrorMessageUser(false);
                    window.location.reload();
                  }, 5000);
                }}
              >
                <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                  <AlertTitle sx={{ fontSize: "16px" }}>{t("login.alert_fields.error_message_user.title")}</AlertTitle>
                  {t("login.alert_fields.error_message_user.description")}
                </Alert>
              </Fade>

              <Fade
                in={errorMessagePassword}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  setTimeout(() => {
                    setErrorMessagePassword(false);
                    window.location.reload();
                  }, 3000);
                }}
              >
                <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                  <AlertTitle sx={{ fontSize: "16px" }}>{t("login.alert_fields.error_message_password.title")}</AlertTitle>
                  {t("login.alert_fields.error_message_password.description")}
                </Alert>
              </Fade>

              <Fade
                in={isRegistered}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  setTimeout(() => {
                    setIsRegistered(false);
                    window.location.reload();
                    logout();
                  }, 3000);
                }}
              >
                <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="warning">
                  <AlertTitle sx={{ fontSize: "16px" }}>{t("login.alert_fields.is_registered.title")}</AlertTitle>
                  {t("login.alert_fields.is_registered.description")}
                </Alert>
              </Fade>

              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LoginOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h2">
                {t("login.login")}
              </Typography>

              {choices === true ? (
                <>
                  <Button
                    onClick={() => {
                      setComplete(false);
                      setChoices(false);
                    }}
                    type="submit"
                    color="secondary"
                    variant="contained"
                    fullWidth
                    sx={{
                      mt: 3,
                      mb: 2,
                    }}
                  >
                    <Typography variant="h4" fontWeight="bold" color={colors.primary[500]}>
                      {t("login.login")}
                    </Typography>
                  </Button>
                  <Button
                    onClick={() => {
                      setComplete(true);
                      setChoices(false);
                    }}
                    type="submit"
                    color="secondary"
                    variant="contained"
                    fullWidth
                    sx={{
                      mt: 3,
                      mb: 2,
                    }}
                  >
                    <Typography variant="h4" fontWeight="bold" color={colors.primary[500]}>
                      {t("login.finish_register")}
                    </Typography>
                  </Button>
                </>
              ) : (
                <>
                  {complete === true ? (
                    <>
                      <Formik
                        onSubmit={handleFormSubmitEmail}
                        initialValues={initialValuesEmail}
                        validationSchema={emailSchema}
                        validateOnChange={false}
                        validateOnBlur={true}
                      >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                          <form onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            <TextField
                              margin="normal"
                              fullWidth
                              variant="filled"
                              type="text"
                              label={t("login.form.email")}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.email}
                              name="email"
                              error={!!touched.email && !!errors.email}
                              helperText={touched.email && errors.email}
                              inputProps={{ style: { fontSize: 16 } }}
                              InputLabelProps={{ style: { fontSize: 16 } }}
                              sx={style}
                            />

                            <Button
                              type="submit"
                              color="secondary"
                              variant="contained"
                              fullWidth
                              sx={{
                                mt: 3,
                                mb: 2,
                              }}
                            >
                              <Typography variant="h4" fontWeight="bold" color={colors.primary[500]}>
                                {t("login.finish_register")}
                              </Typography>
                            </Button>
                          </form>
                        )}
                      </Formik>
                    </>
                  ) : (
                    <>
                      <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={userSchema}
                        validateOnChange={false}
                        validateOnBlur={true}
                      >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                          <form onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            <TextField
                              margin="normal"
                              fullWidth
                              variant="filled"
                              type="text"
                              label={t("login.form.email")}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.email}
                              name="email"
                              error={!!touched.email && !!errors.email}
                              helperText={touched.email && errors.email}
                              inputProps={{ style: { fontSize: 16 } }}
                              InputLabelProps={{ style: { fontSize: 16 } }}
                              sx={style}
                            />
                            <TextField
                              margin="normal"
                              fullWidth
                              variant="filled"
                              type={showPassword ? "text" : "password"}
                              label={t("login.form.password")}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.password}
                              name="password"
                              error={!!touched.password && !!errors.password}
                              helperText={touched.password && errors.password}
                              InputProps={{
                                style: { fontSize: 16 },
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() => setShowPassword(!showPassword)}
                                      edge="end"
                                      // style={{ marginRight: "0px" }}
                                    >
                                      {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              InputLabelProps={{ style: { fontSize: 16 } }}
                              sx={style}
                            />

                            <Button
                              type="submit"
                              color="secondary"
                              variant="contained"
                              fullWidth
                              sx={{
                                mt: 3,
                                mb: 2,
                              }}
                            >
                              <Typography variant="h4" fontWeight="bold" color={colors.primary[500]}>
                                {t("login.login")}
                              </Typography>
                            </Button>
                          </form>
                        )}
                      </Formik>
                    </>
                  )}
                </>
              )}
            </Box>
            <Grid container justifyContent="flex-end">
              <Grid item m>
                <Typography justifyContent="end" variant="h5" color={colors.grey[100]} component={Link} to="/forgot_password">
                  {t("login.form.lost_password")}
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </>
      )}
    </>
  );
};

export default Login;
