import { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Box, Button, TextField, Alert, AlertTitle, useTheme, Typography, Container, Avatar, Grid, Fade } from "@mui/material";
import axios from "axios";
import { Formik } from "formik";
import * as yup from "yup";
import LoadingScreen from "../global/LoadingScreen";
import { tokens } from "../../theme";
import PasswordOutlinedIcon from "@mui/icons-material/PasswordOutlined";
import { useTranslation } from "react-i18next";

const initialValues2 = {
  new_password: "",
  new_password2: "",
};



const config = {
  headers: {
    "access-token": process.env.REACT_APP_ACCESS_TOKEN,
    "Content-Type": "application/json",
  },
  withCredentials: true,
};

const config2 = {
  headers: {
    "access-token": process.env.REACT_APP_ACCESS_TOKEN,
    "Content-Type": "application/json", // Adjust the content type if needed
  },
};

const ResetPassword = () => {
  const { t } = useTranslation();
  const { tokenReset } = useParams();
  const [errorMessageUser, setErrorMessageUser] = useState(false);
  const [errorMessagePassword, setErrorMessagePassword] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [generalError, setGeneralError] = useState(false);
  const [legit, setLegit] = useState(false);
  const [tempData, setTempData] = useState(null);
  const [notVerified, setNotVerified] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const userSchema2 = yup.object().shape({
    new_password: yup
      .string()
      .password()
      .required(t("reset_password.schema.password.required"))
      .min(8, t("reset_password.schema.password.min"))
      .minLowercase(1, t("reset_password.schema.password.min_lower_case"))
      .minUppercase(1, t("reset_password.schema.password.min_upper_case"))
      .minNumbers(1, t("reset_password.schema.password.min_numbers"))
      .minSymbols(0, t("reset_password.schema.password.min_symbols")),
    new_password2: yup
      .string()
      .password()
      .required(t("reset_password.schema.password.required"))
      .min(8, t("reset_password.schema.password.min"))
      .minLowercase(1, t("reset_password.schema.password.min_lower_case"))
      .minUppercase(1, t("reset_password.schema.password.min_upper_case"))
      .minNumbers(1, t("reset_password.schema.password.min_numbers"))
      .minSymbols(0, t("reset_password.schema.password.min_symbols"))
      .oneOf([yup.ref("new_password"), null], t("reset_password.schema.password.one_of")),
  });

  const style = {
    "& label.Mui-focused": {
      color: colors.primary[100],
      fontSize: "16px",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: colors.primary[100],
      },
    },
    "& .MuiFormHelperText-root": {
      fontSize: "14px",
    },
  };

  const navigate = useNavigate();

  const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem("user")) || null);
  const [tempUser, setTempUser] = useState(null);

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(currentUser));
  }, [currentUser]);

  // useEffect(() => {
  //   if (!currentUser) {
  //     navigate("/reset_password");
  //     verifyToken();
  //   } else if (
  //     (currentUser && currentUser.user_type === 2) ||
  //     (currentUser && currentUser.user_type === 1) ||
  //     (currentUser && currentUser.user_type === 0)
  //   ) {
  //     navigate("/");
  //   }
  // }, [currentUser]);

  useEffect(() => {
    verifyToken();
  }, []);

  const verifyToken = async () => {
    try {
      let values2 = {
        token: tokenReset,
      };

      const response1 = await axios.post(process.env.REACT_APP_API_CALL + "users/verify-token", values2, config);

      setTempData(response1.data.authData.user);

      if (response1.data.success === 1) {
        // console.log("Verificado");
        setLegit(true);
        setNotVerified(false);
      } else {
        console.log("Erro!");
        setNotVerified(true);
        setLegit(false);
      }
    } catch (error) {
      setGeneralError(true);
      console.log(error);
    }
  };

  useEffect(() => {
    if (isLoading === true) {
      setIsLoading(false);
      setIsVerified(false);
    }
  }, [isLoading]);

  const handleFormSubmit = async (values) => {
    const response1 = await axios.get(process.env.REACT_APP_API_CALL + `users/user/${tempData.id}`, config2);

    try {
      let values2 = {
        password: values.new_password,
        user_type: response1.data.data.user_type,
        first_name: response1.data.data.first_name,
        last_name: response1.data.data.last_name,
        id: response1.data.data.id,
        email: response1.data.data.email,
        email_supervisor: response1.email_supervisor,
        license: response1.license,
      };

      await axios.patch(process.env.REACT_APP_API_CALL + "users/password-reset/", values2, config);
      setErrorMessageUser(false);
      setIsChanged(true);
      setIsLoading(true);
    } catch (error) {
      setGeneralError(true);
      console.log(error);
    }
  };
  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <Container component="main" maxWidth="xs">
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: "-25px", marginBottom: "-50px" }}>
              <img alt="company-logo" width="100%" height="auto" src={`../../assets/company_logo.png`} />
            </Box>

            <Fade
              in={generalError}
              timeout={{ enter: 500, exit: 500 }}
              unmountOnExit={true}
              addEndListener={() => {
                setTimeout(() => {
                  setGeneralError(false);
                }, 3000);
              }}
            >
              <Alert severity="error">
                <AlertTitle>{t("reset_password.alert_fields.general_error.title")}</AlertTitle>
                {t("reset_password.alert_fields.general_error.description")}
              </Alert>
            </Fade>

            <Fade
              in={isChanged}
              timeout={{ enter: 500, exit: 500 }}
              unmountOnExit={true}
              addEndListener={() => {
                setTimeout(() => {
                  setIsChanged(false);
                  navigate("/login");
                }, 3000);
              }}
            >
              <Alert severity="success">
                <AlertTitle>{t("reset_password.alert_fields.is_changed.title")}</AlertTitle>
                {t("reset_password.alert_fields.is_changed.description")}
              </Alert>
            </Fade>

            <Fade
              in={notVerified}
              timeout={{ enter: 500, exit: 500 }}
              unmountOnExit={true}
              addEndListener={() => {
                setTimeout(() => {
                  setIsChanged(false);
                  navigate("/login");
                }, 5000);
              }}
            >
              <Alert severity="error">
                <AlertTitle>{t("reset_password.alert_fields.not_verified.title")}</AlertTitle>
                {t("reset_password.alert_fields.not_verified.description")}
              </Alert>
            </Fade>

            <Fade
              in={errorMessageUser}
              timeout={{ enter: 500, exit: 500 }}
              unmountOnExit={true}
              addEndListener={() => {
                setTimeout(() => {
                  setErrorMessageUser(false);
                }, 3000);
              }}
            >
              <Alert severity="error">
                <AlertTitle>{t("reset_password.alert_fields.error_message_user.title")}</AlertTitle>
                {t("reset_password.alert_fields.error_message_user.description")}
              </Alert>
            </Fade>

            <Fade
              in={errorMessagePassword}
              timeout={{ enter: 500, exit: 500 }}
              unmountOnExit={true}
              addEndListener={() => {
                setTimeout(() => {
                  setErrorMessagePassword(false);
                }, 3000);
              }}
            >
              <Alert severity="error">
                <AlertTitle>{t("reset_password.alert_fields.error_message_password.title")}</AlertTitle>
                {t("reset_password.alert_fields.error_message_password.description")}
              </Alert>
            </Fade>

            <Fade
              in={isVerified}
              timeout={{ enter: 500, exit: 500 }}
              unmountOnExit={true}
              addEndListener={() => {
                setTimeout(() => {
                  setIsVerified(false);
                }, 2000);
              }}
            >
              <Alert severity="success">
                <AlertTitle>{t("reset_password.alert_fields.is_verified.title")}</AlertTitle>
                {t("reset_password.alert_fields.is_verified.description")}
              </Alert>
            </Fade>

            {notVerified === false ? (
              <>
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                  <PasswordOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h2">
                  {t("reset_password.title")}
                </Typography>
              </>
            ) : (
              <></>
            )}
          </Box>

          {notVerified === false ? (
            <>
              <Formik onSubmit={handleFormSubmit} initialValues={initialValues2} validationSchema={userSchema2}>
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                  <form onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <TextField
                      margin="normal"
                      fullWidth
                      variant="filled"
                      type="password"
                      label={t("reset_password.form.new_password")}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.new_password}
                      name="new_password"
                      error={!!touched.new_password && !!errors.new_password}
                      helperText={touched.new_password && errors.new_password}
                      inputProps={{ style: { fontSize: 16 } }}
                      InputLabelProps={{ style: { fontSize: 16 } }}
                      sx={style}
                    />
                    <TextField
                      margin="normal"
                      fullWidth
                      variant="filled"
                      type="password"
                      label={t("reset_password.form.new_password2")}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.new_password2}
                      name="new_password2"
                      error={!!touched.new_password2 && !!errors.new_password2}
                      helperText={touched.new_password2 && errors.new_password2}
                      inputProps={{ style: { fontSize: 16 } }}
                      InputLabelProps={{ style: { fontSize: 16 } }}
                      sx={style}
                    />

                    <Button
                      type="submit"
                      color="secondary"
                      variant="contained"
                      fullWidth
                      sx={{
                        mt: 3,
                        mb: 2,
                      }}
                    >
                      <Typography variant="h4" fontWeight="bold" color={colors.primary[500]}>
                        {t("reset_password.buttons.reset_password")}
                      </Typography>
                    </Button>
                  </form>
                )}
              </Formik>

              <Grid container justifyContent="flex-end">
                <Grid item m>
                  <Typography justifyContent="end" variant="h5" color={colors.grey[100]} component={Link} to="/login">
                    {t("reset_password.buttons.login")}
                  </Typography>
                </Grid>
              </Grid>
            </>
          ) : (
            <></>
          )}
        </Container>
      )}
    </>
  );
};

export default ResetPassword;
