import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  useTheme,
  Grid,
  Fade,
  Alert,
  AlertTitle,
  ListSubheader,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import axios from "axios";
import LoadingScreen from "../global/LoadingScreen";
import { AuthContext } from "../../context/authContext";
import { useTranslation } from "react-i18next";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";

const Changelog = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [generalError, setGeneralError] = useState(false);
  const [sessionError, setSessionError] = useState(false);
  const [openStates, setOpenStates] = useState({});
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();

  const { currentUser, verifyUser, logout } = useContext(AuthContext);
  const [currentLicense, setCurrentLicense] = useState(null);

  const handleClick = (id) => {
    setOpenStates((prevOpenStates) => ({
      ...prevOpenStates,
      [id]: !prevOpenStates[id],
    }));
  };

  const config = {
    headers: {
      "access-token": process.env.REACT_APP_ACCESS_TOKEN,
      "Content-Type": "application/json", // Adjust the content type if needed
    },
  };

  const verifyLicense = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + `licenses/email/${currentUser?.email}`, config);

      if (response.data.success === 0) {
        setCurrentLicense(0);
      } else if (response.data.data[0].time === 0) {
        setCurrentLicense(1);
      } else if (response.data.data[0].active === 1) {
        setCurrentLicense(2);
      }
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
        logout();
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    verifyUser(currentUser);
    if (!currentUser) {
      navigate("/login");
    } else {
      verifyLicense();
      if (currentLicense !== null && currentLicense !== 2) {
        navigate("/login");
      } else if (currentUser.user_type === 1 || currentUser.user_type === 2 || currentUser.user_type === 3) {
        navigate("/");
      }
    }
  }, [currentLicense, currentUser]);

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <Box m="20px">
          <Header title={t("changelog.title")} subtitle={t("changelog.subtitle")} />

          <Grid container xs={1} sm={1} md={1} lg={1} xl={1}>
            <Box
              onClick={() => navigate(-1)}
              p="5px 7px 5px 5px"
              display="flex"
              justifyContent="center"
              borderRadius="4px"
              alignItems="center"
              backgroundColor={colors.blueAccent[600]}
              sx={{
                cursor: "pointer",
                textDecoration: "none",
              }}
            >
              <ArrowBackOutlinedIcon
                sx={{
                  textDecoration: "none",
                  color: colors.primary[100],
                  justifyContent: "center",
                }}
              />
              <Typography
                justifyContent="center"
                color={colors.primary[100]}
                sx={{
                  ml: "5px",
                  textDecoration: "none",
                  fontWeight: "bold",
                  justifyContent: "center",
                  marginTop: "1px",
                }}
              >
                {t("general.back_button")}
              </Typography>
            </Box>
          </Grid>

          <Box m="20px">
            <Fade
              in={generalError}
              timeout={{ enter: 500, exit: 500 }}
              unmountOnExit={true}
              addEndListener={() => {
                window.scrollTo(0, 0);
                setTimeout(() => {
                  setGeneralError(false);
                }, 3000);
              }}
            >
              <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                <AlertTitle sx={{ fontSize: "16px" }}>{t("changelog.alert_fields.general_error.title")}</AlertTitle>
                {t("changelog.alert_fields.general_error.description")}
              </Alert>
            </Fade>

            <Fade
              in={sessionError}
              timeout={{ enter: 500, exit: 500 }}
              unmountOnExit={true}
              addEndListener={() => {
                window.scrollTo(0, 0);
                setTimeout(() => {
                  setSessionError(false);
                  navigate("/login");
                  logout();
                }, 3000);
              }}
            >
              <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                <AlertTitle sx={{ fontSize: "16px" }}>{t("changelog.alert_fields.session_error.title")}</AlertTitle>
                {t("changelog.alert_fields.session_error.description")}
              </Alert>
            </Fade>
          </Box>

          <Box display="flex" flexDirection="column" alignItems="center" mt="30px">
            <Grid container spacing={4} mt="10px" justifyContent="left">
              <Grid item xs={4}>
                <List
                  sx={{
                    width: "100%",
                    bgcolor: colors.blueAccent[800],
                    // hover states
                    "& .MuiListItemButton-root:hover": {
                      bgcolor: colors.blueAccent[500],
                      "&, & .MuiListItemIcon-root": {
                        // color: "yellow",
                      },
                    },
                  }}
                  component="nav"
                  disablePadding
                  aria-labelledby="nested-list-subheader"
                  subheader={
                    <ListSubheader
                      component="div"
                      id="nested-list-subheader"
                      sx={{
                        fontSize: "16px",
                        width: "100%",
                        bgcolor: colors.greenAccent[700],
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      {t("changelog.changes.15_11_2023.title")}
                    </ListSubheader>
                  }
                >
                  <ListItemButton onClick={() => handleClick("item1")}>
                    <ListItemIcon>
                      <FileCopyOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={t("changelog.changes.15_11_2023.items.item_1.title")}
                      primaryTypographyProps={{ fontWeight: "bold" }}
                    />
                    {openStates["item1"] ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={openStates["item1"]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <FeedOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("changelog.changes.15_11_2023.items.item_1.description")} />
                      </ListItemButton>
                    </List>
                  </Collapse>

                  <ListItemButton onClick={() => handleClick("item2")}>
                    <ListItemIcon>
                      <FileCopyOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={t("changelog.changes.15_11_2023.items.item_2.title")}
                      primaryTypographyProps={{ fontWeight: "bold" }}
                    />
                    {openStates["item2"] ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={openStates["item2"]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <StarOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("changelog.changes.15_11_2023.items.item_2.description_1")} />
                      </ListItemButton>
                    </List>
                    <List component="div" disablePadding>
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <FeedOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("changelog.changes.15_11_2023.items.item_2.description_2")} />
                      </ListItemButton>
                    </List>
                    <List component="div" disablePadding>
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <FeedOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("changelog.changes.15_11_2023.items.item_2.description_3")} />
                      </ListItemButton>
                    </List>
                  </Collapse>
                  <ListItemButton onClick={() => handleClick("item3")}>
                    <ListItemIcon>
                      <FileCopyOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={t("changelog.changes.15_11_2023.items.item_3.title")}
                      primaryTypographyProps={{ fontWeight: "bold" }}
                    />
                    {openStates["item3"] ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={openStates["item3"]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <FeedOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("changelog.changes.15_11_2023.items.item_3.description_1")} />
                      </ListItemButton>
                    </List>
                    <List component="div" disablePadding>
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <StarOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("changelog.changes.15_11_2023.items.item_3.description_2")} />
                      </ListItemButton>
                    </List>
                  </Collapse>
                  <ListItemButton onClick={() => handleClick("item4")}>
                    <ListItemIcon>
                      <FileCopyOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={t("changelog.changes.15_11_2023.items.item_4.title")}
                      primaryTypographyProps={{ fontWeight: "bold" }}
                    />
                    {openStates["item4"] ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={openStates["item4"]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <StarOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("changelog.changes.15_11_2023.items.item_4.description_1")} />
                      </ListItemButton>
                    </List>
                    <List component="div" disablePadding>
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <StarOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("changelog.changes.15_11_2023.items.item_4.description_2")} />
                      </ListItemButton>
                    </List>
                    <List component="div" disablePadding>
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <StarOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("changelog.changes.15_11_2023.items.item_4.description_3")} />
                      </ListItemButton>
                    </List>
                  </Collapse>
                  <ListItemButton onClick={() => handleClick("item5")} sx={{ backgroundColor: colors.greenAccent[700] }}>
                    <ListItemIcon>
                      <FileCopyOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={t("changelog.changes.15_11_2023.items.item_5.title")}
                      primaryTypographyProps={{ fontWeight: "bold" }}
                    />
                    {openStates["item5"] ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={openStates["item5"]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <StarOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("changelog.changes.15_11_2023.items.item_5.description")} />
                      </ListItemButton>
                    </List>
                  </Collapse>
                  <ListItemButton onClick={() => handleClick("item6")} sx={{ backgroundColor: colors.greenAccent[700] }}>
                    <ListItemIcon>
                      <FileCopyOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={t("changelog.changes.15_11_2023.items.item_6.title")}
                      primaryTypographyProps={{ fontWeight: "bold" }}
                    />
                    {openStates["item6"] ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={openStates["item6"]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <StarOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("changelog.changes.15_11_2023.items.item_6.description")} />
                      </ListItemButton>
                    </List>
                  </Collapse>
                  <ListItemButton onClick={() => handleClick("item7")} sx={{ backgroundColor: colors.greenAccent[700] }}>
                    <ListItemIcon>
                      <FileCopyOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={t("changelog.changes.15_11_2023.items.item_7.title")}
                      primaryTypographyProps={{ fontWeight: "bold" }}
                    />
                    {openStates["item7"] ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={openStates["item7"]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <StarOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("changelog.changes.15_11_2023.items.item_7.description")} />
                      </ListItemButton>
                    </List>
                  </Collapse>
                  <ListItemButton onClick={() => handleClick("item8")} sx={{ backgroundColor: colors.greenAccent[700] }}>
                    <ListItemIcon>
                      <FileCopyOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={t("changelog.changes.15_11_2023.items.item_8.title")}
                      primaryTypographyProps={{ fontWeight: "bold" }}
                    />
                    {openStates["item8"] ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={openStates["item8"]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <StarOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("changelog.changes.15_11_2023.items.item_8.description")} />
                      </ListItemButton>
                    </List>
                  </Collapse>
                </List>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Changelog;
