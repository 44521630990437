import { useContext } from "react";
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route, Navigate } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import LoadingScreen from "./scenes/global/LoadingScreen";
import Dashboard from "./scenes/dashboard";
import Register from "./scenes/register";
import Login from "./scenes/login";
import AWSUsers from "./scenes/awsUsers";
import EditAWSUser from "./scenes/editAWSUser";
import EditProfile from "./scenes/editProfile";
import Defaults from "./scenes/defaults";
import LicenseManager from "./scenes/licenseManager";
import CreateUser from "./scenes/createUser";
import CreateSimulation from "./scenes/createSimulation";
import Simulations from "./scenes/simulations";
import MySimulations from "./scenes/mySimulations";
import EditSimulation from "./scenes/editSimulation/index.tsx";
import CreateScenario from "./scenes/createScenario/index.tsx";
import ScenarioReport from "./scenes/scenarioReport/index.tsx";
import EditScenario from "./scenes/editScenario/index.tsx";
import ForgotPassword from "./scenes/forgotPassword";
import ResetPassword from "./scenes/resetPassword";
import CompareScenario from "./scenes/compareScenario";
import FrequentQuestions from "./scenes/frequentQuestions";
import Glossary from "./scenes/glossary";
import Downloads from "./scenes/downloads";
import Changelog from "./scenes/changelog";
import { AuthContext } from "./context/authContext";
import AddToHomeScreen from '@ideasio/add-to-homescreen-react';
import "./i18n";
import { useTranslation } from 'react-i18next';
import { defaults_t } from './types.ts';


function App() {
  const [theme, colorMode] = useMode();
  const { currentUser } = useContext(AuthContext);

  if (!currentUser && currentUser?.license === null) {
    return <LoadingScreen />
  } else {
    return (
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AddToHomeScreen />
          <div className="app">
            {(currentUser && currentUser.license !== null) ? <Sidebar /> : <></>}
            <main className="content">
              {(currentUser && currentUser.license !== null) ? <Topbar /> : <></>}
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/register/" element={<Register />} />
                <Route path="/login" element={<Login />} />
                <Route path="/aws_users" element={<AWSUsers />} />
                <Route path="/forgot_password" element={<ForgotPassword />} />
                <Route path="/reset_password/:idReset/:tokenReset" element={<ResetPassword />} />
                <Route path="/edit_profile/:idProfile" element={<EditProfile />} />
                <Route path="/users/:idUser" element={<EditAWSUser />} />
                <Route path="/defaults" element={<Defaults />} />
                <Route path="/license_manager" element={<LicenseManager />} />
                <Route path="/create_user" element={<CreateUser />} />
                <Route path="/create_simulation" element={<CreateSimulation />} />
                <Route path="/simulations" element={<Simulations />} />
                <Route path="/my_simulations" element={<MySimulations />} />
                <Route path="/simulations/:idSimulation" element={<EditSimulation />} />
                <Route path="/create_scenario/:idSimulation" element={<CreateScenario />} />
                <Route path="/compare_scenario/:idSimulation" element={<CompareScenario />} />
                <Route path="/scenarios/:idScenario" element={<EditScenario />} />
                <Route path="/scenario_report/:idScenario" element={<ScenarioReport />} />
                <Route path="/frequent_questions/" element={<FrequentQuestions />} />
                <Route path="/glossary/" element={<Glossary />} />
                <Route path="/downloads/" element={<Downloads />} />
                <Route path="/changelog/" element={<Changelog />} />
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    )
  }


}

export default App;
