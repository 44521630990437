import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTable } from 'react-table'

export const Styles = styled.div`
  padding: 1rem;
  text-align:center;
  table {
    border-collapse:collapse;
    border-spacing: 0;
    tr {
      overflow:hidden;
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th ,
    td {
      margin: 0;
      padding: 0.5rem;

      :last-child {
        border-right: 0;
      }
    }
  }
`

function MatrixTable({ columns, data }) {
  const [isPrintMediaType, setIsPrintMediaType] =  useState(window.matchMedia("print").matches)
  useEffect(() => {
    const mediaQueryList = window.matchMedia("print");
    const listener = (e) => {
      setIsPrintMediaType(e.matches);
    };
    mediaQueryList.addListener(listener);
    return () => {
      mediaQueryList.removeListener(listener);
    };
  })
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  // Render the UI for your table
  return (
    <table {...getTableProps()} style={{
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor:  isPrintMediaType ? 'black': 'white',
      color: isPrintMediaType ? 'black': 'white'}}>
      <thead>
        {headerGroups.map((headerGroup, i) => (
          <tr style={{
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor:  isPrintMediaType ? 'black': 'white'
          }}key={"headerGroup_" + i} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th style={{   color: isPrintMediaType ? 'black': 'white', fontWeight: 'bolder' }} {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i1) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()} key={"table_body_props_" + i1}>
              {row.cells.map((cell, i2) => {
                if (cell.value !== "" || (row.cells[i2 + 1] && row.cells[i2 + 1].value !== "")) {
                  if (typeof (cell.value) === "number")
                    row.cells[i2].value = parseFloat(row.cells[i2].value.toFixed(2))
                  return <td key={"ceel_" + i1 * i2} style={{ 
                    color: isPrintMediaType ? 'black': 'white',
                    borderBottomColor:  isPrintMediaType ? 'black': 'white',
                    borderBottomStyle: 'solid',
                    borderBottomWidth: '1px',
                    borderRightColor:  isPrintMediaType ? 'black': 'white',
                    borderRightStyle: 'solid',
                    borderRightWidth: '1px'
                  }} {...cell.getCellProps()}>{cell.value}</td>
                }
                else
                  return <td key={"ceel_" + i1 * i2} style={{ border: 'none' }}></td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
export default MatrixTable;