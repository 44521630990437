import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import {
  Box,
  Button,
  TextField,
  Alert,
  AlertTitle,
  useTheme,
  Typography,
  Container,
  Avatar,
  Grid,
  Fade,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DesignServicesOutlinedIcon from "@mui/icons-material/DesignServicesOutlined";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import Header from "../../components/Header";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { Formik } from "formik";
import * as yup from "yup";
import LoadingScreen from "../global/LoadingScreen";
import { AuthContext } from "../../context/authContext";
import { tokens } from "../../theme";
import { useTranslation } from "react-i18next";

const EditProfile = () => {
  const { t } = useTranslation();
  const { idProfile } = useParams();
  const [generalError, setGeneralError] = useState(false);
  const [sessionError, setSessionError] = useState(false);
  const [imageAdded, setImageAdded] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [profileUpdated, setProfileUpdated] = useState(false);
  const [limitError, setLimitError] = useState(false);
  const [error, setError] = useState(false);
  const [passwordChange, setPasswordChange] = useState(false);
  const [emptyFieldError, setEmptyFieldError] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [currentLicense, setCurrentLicense] = useState(null);
  const [licenseType, setLicenseType] = useState(null);
  const [hide, setHide] = useState(false);
  let imgSize = "150px";
  let initialValues = 0;

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const { currentUser, verifyUser, logout } = useContext(AuthContext);

  const config2 = {
    headers: {
      "access-token": process.env.REACT_APP_ACCESS_TOKEN,
      "Content-Type": "application/json", // Adjust the content type if needed
    },
  };

  const config = {
    headers: {
      "access-token": process.env.REACT_APP_ACCESS_TOKEN,
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  const verifyLicense = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + `licenses/email/${currentUser?.email}`, config2);

      setLicenseType(response.data.data[0].type);

      if (response.data.success === 0) {
        setCurrentLicense(0);
      } else if (response.data.data[0].time === 0) {
        setCurrentLicense(1);
      } else if (response.data.data[0].active === 1) {
        setCurrentLicense(2);
      }
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
        logout();
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    verifyUser(currentUser);
    if (!currentUser || currentUser === null || currentUser === undefined) {
      navigate("/login");
    } else {
      verifyLicense();
      if (currentLicense !== null && currentLicense !== 2) {
        navigate("/login");
      }
    }
  }, [currentLicense, currentUser]);

  useEffect(() => {
    if (emptyFieldError === true) {
      window.scrollTo(500, 500);
    }
  }, [emptyFieldError]);

  if (currentUser) {
    let tipo;
    let name_license;

    if (currentUser.user_type === 0) {
      tipo = t("edit_profile.form.user_type.0");
    } else if (currentUser.user_type === 1) {
      tipo = t("edit_profile.form.user_type.1");
    } else if (currentUser.user_type === 2) {
      tipo = t("edit_profile.form.user_type.2");
    } else {
      tipo = t("edit_profile.form.user_type.3");
    }

    if (licenseType && licenseType === "0") {
      name_license = t("edit_profile.form.license_type.0");
    } else if (licenseType && licenseType === "1") {
      name_license = t("edit_profile.form.license_type.1");
    } else if (licenseType && licenseType === "2") {
      name_license = t("edit_profile.form.license_type.2");
    } else if (licenseType && licenseType === "3") {
      name_license = t("edit_profile.form.license_type.3");
    } else if (licenseType && licenseType === "4") {
      name_license = t("edit_profile.form.license_type.4");
    } else if (licenseType && licenseType === "5") {
      name_license = t("edit_profile.form.license_type.5");
    }

    initialValues = {
      first_name: currentUser.first_name,
      last_name: currentUser.last_name,
      email: currentUser.email,
      email_supervisor: currentUser.email_supervisor,
      profile_image: currentUser.profile_image,
      user_type: tipo,
      license: currentUser.license,
      license_type: name_license,
    };
  }

  const userSchema = yup.object().shape({
    first_name: yup.string().required(t("edit_profile.schema.first_name")),
    last_name: yup.string().required(t("edit_profile.schema.last_name")),
    email: yup.string().email(t("edit_profile.schema.email.string")).required(t("edit_profile.schema.email.required")),
    old_password: passwordChange
      ? yup
          .string()
          .password()
          .required(t("edit_profile.schema.old_password.required"))
          .min(8, t("edit_profile.schema.old_password.min"))
          .minLowercase(1, t("edit_profile.schema.old_password.min_lower_case"))
          .minUppercase(1, t("edit_profile.schema.old_password.min_upper_case"))
          .minNumbers(1, t("edit_profile.schema.old_password.min_numbers"))
          .minSymbols(0, t("edit_profile.schema.old_password.min_symbols"))
      : yup.string(),
    new_password: passwordChange
      ? yup
          .string()
          .password()
          .required(t("edit_profile.schema.new_password.required"))
          .min(8, t("edit_profile.schema.new_password.min"))
          .minLowercase(1, t("edit_profile.schema.new_password.min_lower_case"))
          .minUppercase(1, t("edit_profile.schema.new_password.min_upper_case"))
          .minNumbers(1, t("edit_profile.schema.new_password.min_numbers"))
          .minSymbols(0, t("edit_profile.schema.new_password.min_symbols"))
      : yup.string(),
    confirm_password: passwordChange
      ? yup
          .string()
          .password()
          .required(t("edit_profile.schema.confirm_password.required"))
          .min(8, t("edit_profile.schema.confirm_password.min"))
          .minLowercase(1, t("edit_profile.schema.confirm_password.min_lower_case"))
          .minUppercase(1, t("edit_profile.schema.confirm_password.min_upper_case"))
          .minNumbers(1, t("edit_profile.schema.confirm_password.min_numbers"))
          .minSymbols(0, t("edit_profile.schema.confirm_password.min_symbols"))
          .oneOf([yup.ref("new_password"), null], t("edit_profile.schema.confirm_password.one_of"))
      : yup.string(),
  });

  const handleSubmit = async (event) => {
    handleAxios(event.target.files[0]);
    setSelectedFile(event.target.files[0]);
    setIsImageLoading(true);
    setTimeout(() => {
      setIsImageLoading(false);
    }, 2000);
  };

  const handleAxios = async (event) => {
    const formData = new FormData();
    formData.append("file", event);

    try {
      await axios({
        method: "post",
        url: process.env.REACT_APP_API_CALL + "upload",
        data: formData,
        headers: { "Content-Type": "multipart/form-data", "access-token": process.env.REACT_APP_ACCESS_TOKEN },
      });
      setError(false);
      setLimitError(false);
      setImageAdded(true);
    } catch (error) {
      setLimitError(true);
      setError(true);
      console.log(error);
    }
  };

  const handleFormSubmit = async (values) => {
    if (passwordChange === false) {
      try {
        let valores;

        if (selectedFile && error === true) {
          valores = {
            id: currentUser.id,
            first_name: values.first_name,
            last_name: values.last_name,
            email: currentUser.email,
            email_supervisor: currentUser.email_supervisor,
            profile_image: "",
            user_type: currentUser.user_type,
            license: currentUser.license,
          };
        } else if (selectedFile && error === false) {
          valores = {
            id: currentUser.id,
            first_name: values.first_name,
            last_name: values.last_name,
            email: currentUser.email,
            email_supervisor: currentUser.email_supervisor,
            profile_image: selectedFile.name,
            user_type: currentUser.user_type,
            license: currentUser.license,
          };
        } else {
          valores = {
            id: currentUser.id,
            first_name: values.first_name,
            last_name: values.last_name,
            email: currentUser.email,
            email_supervisor: currentUser.email_supervisor,
            profile_image: currentUser.profile_image,
            user_type: currentUser.user_type,
            license: currentUser.license,
          };
        }
        await axios.patch(process.env.REACT_APP_API_CALL + "users", valores, config2);
        localStorage.setItem("user", JSON.stringify(valores));
        setProfileUpdated(true);
        setHide(true);
      } catch (error) {
        if (error.response.data.auth === false) {
          setSessionError(true);
        } else {
          setGeneralError(true);
          console.log(error);
        }
      }
    } else {
      try {
        let valores;
        let valores2;

        let compareBody = {
          id: currentUser.id,
          first_name: values.first_name,
          last_name: values.last_name,
          email: currentUser.email,
          email_supervisor: currentUser.email_supervisor,
          profile_image: "",
          password: values.old_password,
          user_type: currentUser.user_type,
          license: currentUser.license,
        };

        try {
          const response = await axios.post(process.env.REACT_APP_API_CALL + "users/verify", compareBody, config2);
          if (response.data.success === 1) {
            if (values.new_password === values.confirm_password) {
              if (selectedFile && error === true) {
                valores = {
                  id: currentUser.id,
                  first_name: values.first_name,
                  last_name: values.last_name,
                  email: currentUser.email,
                  email_supervisor: currentUser.email_supervisor,
                  profile_image: "",
                  password: values.new_password,
                  user_type: currentUser.user_type,
                  license: currentUser.license,
                };
                valores2 = {
                  id: currentUser.id,
                  first_name: values.first_name,
                  last_name: values.last_name,
                  email: currentUser.email,
                  email_supervisor: currentUser.email_supervisor,
                  profile_image: "",
                  user_type: currentUser.user_type,
                  license: currentUser.license,
                };
              } else if (selectedFile && error === false) {
                valores = {
                  id: currentUser.id,
                  first_name: values.first_name,
                  last_name: values.last_name,
                  email: currentUser.email,
                  email_supervisor: currentUser.email_supervisor,
                  profile_image: selectedFile.name,
                  password: values.new_password,
                  user_type: currentUser.user_type,
                  license: currentUser.license,
                };
                valores2 = {
                  id: currentUser.id,
                  first_name: values.first_name,
                  last_name: values.last_name,
                  email: currentUser.email,
                  email_supervisor: currentUser.email_supervisor,
                  profile_image: "",
                  user_type: currentUser.user_type,
                  license: currentUser.license,
                };
              } else {
                valores = {
                  id: currentUser.id,
                  first_name: values.first_name,
                  last_name: values.last_name,
                  email: currentUser.email,
                  email_supervisor: currentUser.email_supervisor,
                  profile_image: currentUser.profile_image,
                  password: values.new_password,
                  user_type: currentUser.user_type,
                  license: currentUser.license,
                };
                valores2 = {
                  id: currentUser.id,
                  first_name: values.first_name,
                  last_name: values.last_name,
                  email: currentUser.email,
                  email_supervisor: currentUser.email_supervisor,
                  profile_image: "",
                  user_type: currentUser.user_type,
                  license: currentUser.license,
                };
              }

              await axios.patch(process.env.REACT_APP_API_CALL + "users/password/", valores, config);

              localStorage.setItem("user", JSON.stringify(valores2));
              setProfileUpdated(true);
              setHide(true);
            } else {
              console.log("Error!");
            }
          } else {
            setError(true);
            return;
          }
        } catch (error) {
          setError(true);
          console.log(error);
        }
      } catch (error) {
        if (error.response.data.auth === false) {
          setSessionError(true);
        } else {
          setGeneralError(true);
          console.log(error);
        }
      }
    }
  };

  const style = {
    "& label.Mui-focused": {
      color: colors.primary[100],
      fontSize: "16px",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: colors.primary[100],
      },
    },
    "& .MuiFormHelperText-root": {
      fontSize: "14px",
    },
  };

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : currentUser && licenseType !== null ? (
        <>
          <Box m="20px">
            <Header title={t("edit_profile.title")} subtitle={t("edit_profile.subtitle")} />
          </Box>

          <Grid container xs={1} sm={1} md={1} lg={1} xl={1}>
            <Box
              ml="20px"
              onClick={() => navigate(-3)}
              p="5px 7px 5px 5px"
              display="flex"
              justifyContent="center"
              borderRadius="4px"
              alignItems="center"
              backgroundColor={colors.blueAccent[600]}
              sx={{
                cursor: "pointer",
                textDecoration: "none",
              }}
            >
              <ArrowBackOutlinedIcon
                sx={{
                  textDecoration: "none",
                  color: colors.primary[100],
                  justifyContent: "center",
                }}
              />
              <Typography
                justifyContent="center"
                color={colors.primary[100]}
                sx={{
                  ml: "5px",
                  textDecoration: "none",
                  fontWeight: "bold",
                  justifyContent: "center",
                  marginTop: "1px",
                }}
              >
                {t("general.back_button")}
              </Typography>
            </Box>
          </Grid>

          <Container component="main" maxWidth="xs">
            <Box display="flex" flexDirection="column" alignItems="center" mt="30px">
              <Fade
                in={generalError}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  window.scrollTo(0, 0);
                  setTimeout(() => {
                    setGeneralError(false);
                  }, 3000);
                }}
              >
                <Alert severity="error">
                  <AlertTitle>{t("edit_profile.alert_fields.general_error.title")}</AlertTitle>
                  {t("edit_profile.alert_fields.general_error.description")}
                </Alert>
              </Fade>

              <Fade
                in={sessionError}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  window.scrollTo(0, 0);
                  setTimeout(() => {
                    setSessionError(false);
                    navigate("/login");
                    logout();
                  }, 3000);
                }}
              >
                <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                  <AlertTitle sx={{ fontSize: "16px" }}>{t("edit_profile.alert_fields.session_error.title")}</AlertTitle>
                  {t("edit_profile.alert_fields.session_error.description")}
                </Alert>
              </Fade>

              <Fade
                in={imageAdded}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  setTimeout(() => {
                    setImageAdded(false);
                  }, 2000);
                }}
              >
                <Alert severity="success">
                  <AlertTitle>{t("edit_profile.alert_fields.image_added.title")}</AlertTitle>
                  {t("edit_profile.alert_fields.image_added.description")}
                </Alert>
              </Fade>

              <Fade
                in={profileUpdated}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  setTimeout(() => {
                    setProfileUpdated(false);
                    navigate(`/edit_profile/${idProfile}`);
                    navigate(0);
                  }, 2000);
                }}
              >
                <Alert severity="success">
                  <AlertTitle>{t("edit_profile.alert_fields.profile_updated.title")}</AlertTitle>
                  {t("edit_profile.alert_fields.profile_updated.description")}
                </Alert>
              </Fade>

              <Fade
                in={limitError}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  setTimeout(() => {
                    setLimitError(false);
                  }, 3000);
                }}
              >
                <Alert severity="error">
                  <AlertTitle>{t("edit_profile.alert_fields.limit_error.title")}</AlertTitle>
                  {t("edit_profile.alert_fields.limit_error.description")}
                </Alert>
              </Fade>

              <Fade
                in={emptyFieldError}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  setTimeout(() => {
                    setEmptyFieldError(false);
                  }, 6000);
                }}
              >
                <Alert severity="warning">
                  <AlertTitle>{t("edit_profile.alert_fields.empty_field_error.title")}</AlertTitle>
                  {t("edit_profile.alert_fields.empty_field_error.description")}
                </Alert>
              </Fade>

              {hide === false ? (
                <>
                  <Box display="flex" justifyContent="center" alignItems="center">
                    <Avatar
                      sx={{
                        marginTop: "20px",
                        width: imgSize,
                        height: imgSize,
                      }}
                    >
                      {(() => {
                        if (selectedFile && isImageLoading === false && error === false) {
                          return (
                            <img
                              style={{ borderRadius: "50%", objectFit: "cover" }}
                              alt="profile"
                              width={imgSize}
                              height={imgSize}
                              src={process.env.REACT_APP_API_CALL + "files/" + selectedFile.name}
                            />
                          );
                        } else if (selectedFile && isImageLoading === true) {
                          return <CircularProgress style={{ color: colors.greenAccent[500] }} />;
                        } else if (
                          (currentUser && currentUser.profile_image === null) ||
                          (currentUser && currentUser.profile_image === "") ||
                          error === true
                        ) {
                          return (
                            <Avatar
                              sx={{
                                bgcolor: colors.blueAccent[500],
                                width: imgSize,
                                height: imgSize,
                              }}
                            >
                              <Typography variant="h1" color={colors.primary[500]} fontWeight="bold">
                                {Array.from(currentUser.first_name)[0]} {Array.from(currentUser.last_name)[0]}
                              </Typography>
                            </Avatar>
                          );
                        } else {
                          return (
                            <img
                              style={{ borderRadius: "50%", objectFit: "cover" }}
                              alt="profile"
                              width={imgSize}
                              height={imgSize}
                              src={process.env.REACT_APP_API_CALL + "files/" + currentUser.profile_image}
                            />
                          );
                        }
                      })()}
                    </Avatar>
                  </Box>

                  <Button
                    style={{
                      backgroundColor: colors.blueAccent[400],
                      color: "#FFFFFF",
                      textTransform: "none",
                    }}
                    fullWidth
                    variant="contained"
                    component="label"
                    sx={{
                      mt: 3,
                      mb: 1,
                    }}
                  >
                    <AddPhotoAlternateOutlinedIcon
                      sx={{
                        textDecoration: "none",
                        color: colors.grey[100],
                        justifyContent: "center",
                      }}
                    />
                    <Typography
                      variant="h4"
                      justifyContent="center"
                      color={colors.primary[100]}
                      sx={{
                        ml: "5px",
                        textDecoration: "none",
                        fontWeight: "bold",
                        justifyContent: "center",
                        marginTop: "1px",
                      }}
                    >
                      {t("edit_profile.form.photo_title")}
                    </Typography>
                    <input type="file" onChange={handleSubmit} label="Escolha a Imagem" hidden />
                  </Button>

                  <Formik onSubmit={handleFormSubmit} initialValues={initialValues} validationSchema={userSchema}>
                    {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                      <form onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2} mt="10px">
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              multiline
                              variant="filled"
                              type="text"
                              label={t("edit_profile.form.first_name")}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.first_name}
                              name="first_name"
                              error={!!touched.first_name && !!errors.first_name}
                              helperText={touched.first_name && errors.first_name}
                              inputProps={{ style: { fontSize: 16 } }}
                              InputLabelProps={{ style: { fontSize: 16 } }}
                              sx={style}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              multiline
                              variant="filled"
                              type="text"
                              label={t("edit_profile.form.last_name")}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.last_name}
                              name="last_name"
                              error={!!touched.last_name && !!errors.last_name}
                              helperText={touched.last_name && errors.last_name}
                              inputProps={{ style: { fontSize: 16 } }}
                              InputLabelProps={{ style: { fontSize: 16 } }}
                              sx={style}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              multiline
                              variant="filled"
                              type="text"
                              label={t("edit_profile.form.email")}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.email}
                              name="email"
                              disabled={true}
                              error={!!touched.email && !!errors.email}
                              helperText={touched.email && errors.email}
                              inputProps={{ style: { fontSize: 16 } }}
                              InputLabelProps={{ style: { fontSize: 16 } }}
                              sx={style}
                            />
                          </Grid>
                          {currentUser.user_type === 0 ? (
                            <></>
                          ) : (
                            <Grid item xs={12}>
                              <TextField
                                fullWidth
                                multiline
                                variant="filled"
                                type="text"
                                label={t("edit_profile.form.email_supervisor")}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.email_supervisor}
                                name="email_supervisor"
                                disabled={true}
                                error={!!touched.email_supervisor && !!errors.email_supervisor}
                                helperText={touched.email_supervisor && errors.email_supervisor}
                                inputProps={{ style: { fontSize: 16 } }}
                                InputLabelProps={{ style: { fontSize: 16 } }}
                                sx={style}
                              />
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              multiline
                              variant="filled"
                              type="text"
                              label={t("edit_profile.form.user_type.title")}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.user_type}
                              name="user_type"
                              disabled={true}
                              error={!!touched.user_type && !!errors.user_type}
                              helperText={touched.user_type && errors.user_type}
                              inputProps={{ style: { fontSize: 16 } }}
                              InputLabelProps={{ style: { fontSize: 16 } }}
                              sx={style}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              multiline
                              variant="filled"
                              type="text"
                              label={t("edit_profile.form.email_supervisor")}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.email_supervisor}
                              name="email_supervisor"
                              disabled={true}
                              error={!!touched.email_supervisor && !!errors.email_supervisor}
                              helperText={touched.email_supervisor && errors.email_supervisor}
                              inputProps={{ style: { fontSize: 16 } }}
                              InputLabelProps={{ style: { fontSize: 16 } }}
                              sx={style}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              multiline
                              variant="filled"
                              type="text"
                              label={t("edit_profile.form.license")}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.license}
                              name="license"
                              disabled={true}
                              error={!!touched.license && !!errors.license}
                              helperText={touched.license && errors.license}
                              inputProps={{ style: { fontSize: 16 } }}
                              InputLabelProps={{ style: { fontSize: 16 } }}
                              sx={style}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              multiline
                              variant="filled"
                              type="text"
                              label={t("edit_profile.form.license_type.title")}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.license_type}
                              name="license_type"
                              disabled={true}
                              error={!!touched.license_type && !!errors.license_type}
                              helperText={touched.license_type && errors.license_type}
                              inputProps={{ style: { fontSize: 16 } }}
                              InputLabelProps={{ style: { fontSize: 16 } }}
                              sx={style}
                            />
                          </Grid>
                          {passwordChange === true ? (
                            <>
                              <Grid item xs={12}>
                                <TextField
                                  fullWidth
                                  variant="filled"
                                  type={showOldPassword ? "text" : "password"}
                                  label={t("edit_profile.form.old_password")}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.old_password}
                                  name="old_password"
                                  disabled={false}
                                  error={!!touched.old_password && !!errors.old_password}
                                  helperText={touched.old_password && errors.old_password}
                                  InputProps={{
                                    style: { fontSize: 16 },
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton onClick={() => setShowOldPassword(!showOldPassword)} edge="end">
                                          {showOldPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                  InputLabelProps={{ style: { fontSize: 16 } }}
                                  sx={style}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  fullWidth
                                  variant="filled"
                                  type={showNewPassword ? "text" : "password"}
                                  label={t("edit_profile.form.new_password")}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.new_password}
                                  name="new_password"
                                  disabled={false}
                                  error={!!touched.new_password && !!errors.new_password}
                                  helperText={touched.new_password && errors.new_password}
                                  InputProps={{
                                    style: { fontSize: 16 },
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          onClick={() => setShowNewPassword(!showNewPassword)}
                                          edge="end"
                                          // style={{ marginRight: "0px" }}
                                        >
                                          {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                  InputLabelProps={{ style: { fontSize: 16 } }}
                                  sx={style}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  fullWidth
                                  variant="filled"
                                  type={showConfirmPassword ? "text" : "password"}
                                  label={t("edit_profile.form.confirm_password")}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.confirm_password}
                                  name="confirm_password"
                                  disabled={false}
                                  error={!!touched.confirm_password && !!errors.confirm_password}
                                  helperText={touched.confirm_password && errors.confirm_password}
                                  InputProps={{
                                    style: { fontSize: 16 },
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
                                          {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                  InputLabelProps={{
                                    style: { fontSize: 16 },
                                  }}
                                  sx={style}
                                />
                              </Grid>

                              <Grid container justifyContent="flex-end">
                                <Grid item m>
                                  <Typography
                                    justifyContent="end"
                                    variant="h5"
                                    color={colors.grey[100]}
                                    component={Link}
                                    to="/forgot_password"
                                  >
                                    {t("edit_profile.form.forgot_password")}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </>
                          ) : (
                            <></>
                          )}

                          <Grid item xs={12} mt="10px" mb="10px">
                            <Box
                              p="5px 7px 5px 5px"
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              borderRadius="4px"
                              backgroundColor={colors.greenAccent[600]}
                              sx={{
                                cursor: "pointer",
                                textDecoration: "none",
                              }}
                              onClick={handleSubmit}
                            >
                              <DesignServicesOutlinedIcon
                                sx={{
                                  textDecoration: "none",
                                  color: colors.grey[100],
                                  justifyContent: "center",
                                }}
                              />
                              <Typography
                                variant="h4"
                                justifyContent="center"
                                color={colors.grey[100]}
                                sx={{
                                  ml: "5px",
                                  textDecoration: "none",
                                  fontWeight: "bold",
                                  justifyContent: "center",
                                  marginTop: "1px",
                                }}
                              >
                                {t("edit_profile.form.buttons.edit_profile")}
                              </Typography>
                            </Box>
                          </Grid>
                          {passwordChange === true ? (
                            <></>
                          ) : (
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginBottom="20px">
                              <Box
                                m="0 auto"
                                p="5px 7px 5px 5px"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                borderRadius="4px"
                                backgroundColor={colors.grey[600]}
                                sx={{
                                  cursor: "pointer",
                                  textDecoration: "none",
                                }}
                                onClick={() => {
                                  setPasswordChange(true);
                                  setEmptyFieldError(true);
                                }}
                              >
                                <BorderColorOutlinedIcon
                                  sx={{
                                    textDecoration: "none",
                                    color: colors.grey[100],
                                    justifyContent: "center",
                                  }}
                                />
                                <Typography
                                  variant="h4"
                                  justifyContent="center"
                                  color={colors.grey[100]}
                                  sx={{
                                    ml: "5px",
                                    textDecoration: "none",
                                    fontWeight: "bold",
                                    justifyContent: "center",
                                    marginTop: "1px",
                                  }}
                                >
                                  {t("edit_profile.form.buttons.change_password")}
                                </Typography>
                              </Box>
                            </Grid>
                          )}

                          {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box
                              m="0 auto"
                              p="5px 7px 5px 5px"
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              borderRadius="4px"
                              backgroundColor={colors.redAccent[600]}
                              sx={{
                                cursor: "pointer",
                                textDecoration: "none",
                              }}
                              onClick={logout}
                            >
                              <LogoutOutlinedIcon
                                sx={{
                                  textDecoration: "none",
                                  color: colors.grey[100],
                                  justifyContent: "center",
                                }}
                              />
                              <Typography
                                variant="h4"
                                justifyContent="center"
                                color={colors.grey[100]}
                                sx={{
                                  ml: "5px",
                                  textDecoration: "none",
                                  fontWeight: "bold",
                                  justifyContent: "center",
                                }}
                              >
                                Logout
                              </Typography>
                            </Box>
                          </Grid> */}
                        </Grid>
                      </form>
                    )}
                  </Formik>
                </>
              ) : (
                <LoadingScreen />
              )}
            </Box>
          </Container>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default EditProfile;
