import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  TextField,
  Alert,
  AlertTitle,
  useTheme,
  Typography,
  Container,
  Grid,
  Fade,
  Select,
  MenuItem,
  Avatar,
} from "@mui/material";
import axios from "axios";
import { Formik } from "formik";
import * as yup from "yup";
import LoadingScreen from "../global/LoadingScreen";
import { AuthContext } from "../../context/authContext";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { useTranslation } from "react-i18next";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";

const CreateUser = () => {
  const { t } = useTranslation();
  const [generalError, setGeneralError] = useState(false);
  const [sessionError, setSessionError] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [errorMessageAlreadyCreated, setErrorMessageAlreadyCreated] = useState(false);
  const [emailError, setEmailError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [emails0, setEmails0] = useState([]);
  const [emails1, setEmails1] = useState([]);
  const [emails2, setEmails2] = useState([]);
  const [hide, setHide] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  let emails_supervisor = [];

  const userSchema = yup.object().shape({
    email: yup.string().email(t("create_user.schema.email.string")).required(t("create_user.schema.email.required")),
  });

  const navigate = useNavigate();
  const { currentUser, verifyUser, logout } = useContext(AuthContext);
  const [currentLicense, setCurrentLicense] = useState(null);

  const config = {
    headers: {
      "access-token": process.env.REACT_APP_ACCESS_TOKEN,
      "Content-Type": "application/json", // Adjust the content type if needed
    },
    withCredentials: true,
  };

  const verifyLicense = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + `licenses/email/${currentUser?.email}`, config);

      if (response.data.success === 0) {
        setCurrentLicense(0);
      } else if (response.data.data[0].time === 0) {
        setCurrentLicense(1);
      } else if (response.data.data[0].active === 1) {
        setCurrentLicense(2);
      }
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
        logout();
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    verifyUser(currentUser);
    if (!currentUser) {
      navigate("/login");
    } else {
      verifyLicense();
      if (currentLicense !== null && currentLicense !== 2) {
        navigate("/login");
      } else if (currentUser.user_type === 2 || currentUser.user_type === 3) {
        navigate("/");
      }
    }
  }, [currentLicense, currentUser]);

  let initialValues = {
    email: "",
    email_supervisor: currentUser?.email,
    user_type: 3,
  };

  useEffect(() => {
    getEmails();
  }, []);

  const getEmails = async () => {
    try {
      const response0 = await axios.get(process.env.REACT_APP_API_CALL + "users/emails/0", config);

      const response1 = await axios.get(process.env.REACT_APP_API_CALL + "users/emails/1", config);

      const response2 = await axios.get(process.env.REACT_APP_API_CALL + "users/emails/2", config);

      setEmails0(response0.data.data);
      setEmails1(response1.data.data);
      setEmails2(response2.data.data);
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
      }
    }
  };

  if (currentUser?.user_type === 0) {
    if (emails0 === undefined || emails1 === undefined || emails2 === undefined) {
      setEmailError(true);
      return;
    } else {
      for (let i = 0; i < emails0.length; i++) {
        emails_supervisor.push(emails0[i].email);
      }
      for (let i = 0; i < emails1.length; i++) {
        emails_supervisor.push(emails1[i].email);
      }
      for (let i = 0; i < emails2.length; i++) {
        emails_supervisor.push(emails2[i].email);
      }
    }
  }

  if (currentUser && currentUser.user_type === 1) {
    if (emails1 === undefined || emails2 === undefined) {
      setEmailError(true);
      return;
    } else {
      for (let i = 0; i < emails1.length; i++) {
        emails_supervisor.push(emails1[i].email);
      }
      for (let i = 0; i < emails2.length; i++) {
        emails_supervisor.push(emails2[i].email);
      }
    }
  }

  const style = {
    "& label.Mui-focused": {
      color: colors.primary[100],
      fontSize: "16px",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: colors.primary[100],
      },
    },
    "& .MuiFormHelperText-root": {
      fontSize: "14px",
    },
  };

  const handleFormSubmit = async (values) => {
    try {
      setIsLoading(true);
      const response1 = await axios.get(process.env.REACT_APP_API_CALL + `users/email/${values.email}`, config);
      setIsLoading(false);
      if (response1.data.data.length > 0) {
        setErrorMessageAlreadyCreated(true);
        setIsRegistered(false);
      } else {
        let valores;

        valores = {
          email: values.email,
          email_supervisor: values.email_supervisor,
          user_type: values.user_type,
        };

        await axios.post(process.env.REACT_APP_API_CALL + "users/register", valores, config);

        setHide(true);
        setErrorMessageAlreadyCreated(false);
        setIsRegistered(true);
      }
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <Box m="20px">
            <Header title={t("create_user.title")} subtitle={t("create_user.subtitle")} />
          </Box>
          <Container component="main" maxWidth="xs">
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: "25px", marginBottom: "100px" }}></Box>

              <Fade
                in={generalError}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  window.scrollTo(0, 0);
                  setTimeout(() => {
                    setGeneralError(false);
                  }, 3000);
                }}
              >
                <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                  <AlertTitle sx={{ fontSize: "16px" }}>{t("create_user.alert_fields.general_error.title")}</AlertTitle>
                  {t("create_user.alert_fields.general_error.description")}
                </Alert>
              </Fade>

              <Fade
                in={sessionError}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  window.scrollTo(0, 0);
                  setTimeout(() => {
                    setSessionError(false);
                    navigate("/login");
                    logout();
                  }, 3000);
                }}
              >
                <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                  <AlertTitle sx={{ fontSize: "16px" }}>{t("create_user.alert_fields.session_error.title")}</AlertTitle>
                  {t("create_user.alert_fields.session_error.description")}
                </Alert>
              </Fade>

              <Fade
                in={isRegistered}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  setTimeout(() => {
                    setIsRegistered(false);
                    navigate("/aws_users");
                  }, 3000);
                }}
              >
                <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="success">
                  <AlertTitle sx={{ fontSize: "16px" }}>{t("create_user.alert_fields.is_registered.title")}</AlertTitle>
                  {t("create_user.alert_fields.is_registered.description")}
                </Alert>
              </Fade>

              <Fade
                in={emailError}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  window.scrollTo(0, 0);
                  setTimeout(() => {
                    setEmailError(false);
                  }, 3000);
                }}
              >
                <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                  <AlertTitle sx={{ fontSize: "16px" }}>{t("create_user.alert_fields.email_error.title")}</AlertTitle>
                  {t("create_user.alert_fields.email_error.description")}
                </Alert>
              </Fade>

              <Fade
                in={errorMessageAlreadyCreated}
                timeout={{ enter: 500, exit: 500 }}
                unmountOnExit={true}
                addEndListener={() => {
                  setTimeout(() => {
                    setErrorMessageAlreadyCreated(false);
                  }, 3000);
                }}
              >
                <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
                  <AlertTitle sx={{ fontSize: "16px" }}>{t("create_user.alert_fields.already_created_error.title")}</AlertTitle>
                  {t("create_user.alert_fields.already_created_error.description")}
                </Alert>
              </Fade>

              {hide === false ? (
                <>
                  <Avatar sx={{ m: 1, bgcolor: "secondary.main", height: "70px", width: "70px" }}>
                    <PersonAddAltOutlinedIcon sx={{ m: 1, bgcolor: "secondary.main", height: "30px", width: "30px" }} />
                  </Avatar>

                  <Typography component="h1" variant="h2">
                    {t("create_user.form.title")}
                  </Typography>

                  <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={initialValues}
                    validationSchema={userSchema}
                    validateOnChange={false}
                    validateOnBlur={true}
                  >
                    {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                      <form onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={4} mt="10px">
                          <Grid item xs={12} sx={{ maxWidth: "xs" }}>
                            <TextField
                              fullWidth
                              multiline
                              variant="filled"
                              type="text"
                              label={t("create_user.form.email")}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.email}
                              name="email"
                              error={!!touched.email && !!errors.email}
                              helperText={touched.email && errors.email}
                              inputProps={{ style: { fontSize: 16 } }}
                              InputLabelProps={{ style: { fontSize: 16 } }}
                              sx={style}
                            />
                          </Grid>

                          {currentUser?.user_type === 0 || currentUser?.user_type === 1 ? (
                            <>
                              <Grid item xs={12} sx={{ maxWidth: "xs" }}>
                                <Typography
                                  sx={{
                                    textDecoration: "none",
                                    paddingLeft: "6px",
                                    marginTop: "-22px",
                                    justifyContent: "left",
                                  }}
                                  component="h1"
                                  variant="h6"
                                >
                                  {t("create_user.form.email_supervisor")}
                                </Typography>
                                <Select
                                  fullWidth
                                  hiddenLabel
                                  label={t("create_user.form.email_supervisor")}
                                  variant="filled"
                                  labelId="email_supervisor"
                                  id="email_supervisor"
                                  value={values.email_supervisor}
                                  onChange={handleChange("email_supervisor")}
                                  sx={{
                                    fontSize: "16px",
                                    height: "94%",
                                    maxWidth: "100% !important",
                                  }}
                                >
                                  {emails_supervisor.map((option) => (
                                    <MenuItem value={option} sx={{ fontSize: "16px" }}>
                                      {option}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Grid item xs={12} sx={{ maxWidth: "xs" }}>
                                <TextField
                                  fullWidth
                                  multiline
                                  variant="filled"
                                  type="text"
                                  label={t("create_user.form.email_supervisor")}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.email_supervisor}
                                  name="email_supervisor"
                                  error={!!touched.email_supervisor && !!errors.email_supervisor}
                                  helperText={touched.email_supervisor && errors.email_supervisor}
                                  inputProps={{ style: { fontSize: 16 } }}
                                  InputLabelProps={{ style: { fontSize: 16 } }}
                                  sx={style}
                                  disabled={true}
                                />
                              </Grid>
                            </>
                          )}

                          {currentUser?.user_type === 0 || currentUser.user_type === 1 ? (
                            <>
                              <Grid item xs={12} sx={{ maxWidth: "xs" }}>
                                <Typography
                                  sx={{
                                    textDecoration: "none",
                                    paddingLeft: "6px",
                                    marginTop: "-22px",
                                    justifyContent: "left",
                                  }}
                                  component="h1"
                                  variant="h6"
                                >
                                  {t("create_user.form.user_type.title")}
                                </Typography>

                                {currentUser?.user_type === 0 ? (
                                  <Select
                                    fullWidth
                                    hiddenLabel
                                    label={t("create_user.form.user_type.title")}
                                    variant="filled"
                                    labelId="user_type"
                                    id="user_type"
                                    value={values.user_type}
                                    onChange={handleChange("user_type")}
                                    sx={{
                                      fontSize: "16px",
                                      height: "94%",
                                      maxWidth: "100% !important",
                                    }}
                                  >
                                    <MenuItem value={0} sx={{ fontSize: "16px" }}>
                                      {t("create_user.form.user_type.0")}
                                    </MenuItem>
                                    <MenuItem value={1} sx={{ fontSize: "16px" }}>
                                      {t("create_user.form.user_type.1")}
                                    </MenuItem>
                                    <MenuItem value={2} sx={{ fontSize: "16px" }}>
                                      {t("create_user.form.user_type.2")}
                                    </MenuItem>
                                    <MenuItem value={3} sx={{ fontSize: "16px" }}>
                                      {t("create_user.form.user_type.3")}
                                    </MenuItem>
                                  </Select>
                                ) : (
                                  <Select
                                    fullWidth
                                    hiddenLabel
                                    label={t("create_user.form.user_type.title")}
                                    variant="filled"
                                    labelId="user_type"
                                    id="user_type"
                                    value={values.user_type}
                                    onChange={handleChange("user_type")}
                                    sx={{
                                      fontSize: "16px",
                                      height: "94%",
                                      maxWidth: "100% !important",
                                    }}
                                  >
                                    <MenuItem value={2} sx={{ fontSize: "16px" }}>
                                      {t("create_user.form.user_type.2")}
                                    </MenuItem>
                                    <MenuItem value={3} sx={{ fontSize: "16px" }}>
                                      {t("create_user.form.user_type.3")}
                                    </MenuItem>
                                  </Select>
                                )}
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Grid item xs={12} sx={{ maxWidth: "xs" }}>
                                <TextField
                                  fullWidth
                                  multiline
                                  variant="filled"
                                  type="text"
                                  label={t("create_user.form.user_type.title")}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.user_type}
                                  name="user_type"
                                  error={!!touched.user_type && !!errors.user_type}
                                  helperText={touched.user_type && errors.user_type}
                                  inputProps={{ style: { fontSize: 16 } }}
                                  InputLabelProps={{ style: { fontSize: 16 } }}
                                  sx={style}
                                  disabled={true}
                                />
                              </Grid>
                            </>
                          )}
                        </Grid>

                        <Grid item xs={12} mt="25px" mb="10px">
                          <Box
                            p="5px 7px 5px 5px"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            borderRadius="4px"
                            backgroundColor={colors.greenAccent[600]}
                            sx={{
                              cursor: "pointer",
                              textDecoration: "none",
                            }}
                            onClick={handleSubmit}
                          >
                            <PersonAddAltOutlinedIcon
                              sx={{
                                textDecoration: "none",
                                color: colors.grey[100],
                                justifyContent: "center",
                              }}
                            />
                            <Typography
                              variant="h4"
                              justifyContent="center"
                              color={colors.grey[100]}
                              sx={{
                                ml: "5px",
                                textDecoration: "none",
                                fontWeight: "bold",
                                justifyContent: "center",
                                marginTop: "1px",
                              }}
                            >
                              {t("create_user.form.buttons.create_user")}
                            </Typography>
                          </Box>
                        </Grid>
                      </form>
                    )}
                  </Formik>
                </>
              ) : (
                <LoadingScreen />
              )}
            </Box>
          </Container>
        </>
      )}
    </>
  );
};

export default CreateUser;
