import React from 'react';

export default class DynamicTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: this.props.initialName || '', // Initialize with the initialName prop
            t: this.props.t,
            editMode: this.props.editMode,
            index: this.props.index,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.editMode !== this.props.editMode) {
            this.setState({ editMode: this.props.editMode });
        }
    }

    handleNameChanged(event) {
        const newName = event.target.value;
        this.setState({ name: newName });
        this.props.setName(newName);
    }

    renderRows() {
        const context = this;
        return (
            <tr key={"name-" + this.state.index} className="projectFieldContainerContainer">
                <td>
                    <p className='projectFieldTitle'>{context.state.t('components.simple_cool_table.description')}</p>
                </td>
                <td>
                    <input
                        type="text"
                        className='projectFieldInput'
                        disabled={context.state.editMode >= 1 ? true : false}
                        value={this.state.name}
                        onChange={context.handleNameChanged.bind(context)}
                    />
                </td>
            </tr>
        );
    }

    render() {
        return (
            <table style={{ display: 'flex', width: '100%' }}>
                <thead>
                    <tr></tr>
                </thead>
                <tbody>
                    {this.renderRows()}
                </tbody>
            </table>
        );
    }
}