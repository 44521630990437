import {
  Box,
  useTheme,
  Typography,
  Grid,
  Stack,
  MenuItem,
  Select,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { ColorModeContext, tokens } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/Logout";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import { AuthContext } from "../../context/authContext";
import axios from "axios";
import { useTranslation } from "react-i18next";

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [currentLicense, setCurrentLicense] = useState(null);
  const [generalError, setGeneralError] = useState(false);
  const [sessionError, setSessionError] = useState(false);
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const { currentUser, logout, verifyUser } = useContext(AuthContext);

  const config = {
    headers: {
      "access-token": process.env.REACT_APP_ACCESS_TOKEN,
      "Content-Type": "application/json", // Adjust the content type if needed
    },
    withCredentials: true,
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Update the language in i18next
    localStorage.setItem("i18nextLng", lng); // Save the selected language to localStorage
  };

  const currentLanguage = localStorage.getItem("i18nextLng") || "pt"; // Get the current language from localStorage

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    changeLanguage(selectedLanguage);
  };

  // const languageOptions = [
  //   { code: "en", label: t("english") },
  //   { code: "fr", label: t("french") },
  //   { code: "pt", label: t("portuguese") },
  //   // Add more language options as needed
  // ];

  const forceLogout = () => {
    navigate("/");
    logout();
  };

  const verifyLicense = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + `licenses/email/${currentUser?.email}`, config);

      if (response.data.success === 0) {
        setCurrentLicense(0);
      } else if (response.data.data[0].time === 0) {
        setCurrentLicense(1);
      } else if (response.data.data[0].active === 1) {
        setCurrentLicense(2);
      }
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
        logout();
      }
    }
  };

  useEffect(() => {
    verifyUser(currentUser);
    if (!currentUser) {
      logout();
      navigate("/login");
    } else {
      verifyLicense();
      if (currentLicense !== null && currentLicense !== 2) {
        logout();
        navigate("/login");
      }
    }
  }, [currentLicense, currentUser]);

  // const verifyLicense = async () => {
  //   try {
  //     const response = await axios.get(
  //        process.env.REACT_APP_API_CALL + `licenses/email/${currentUser?.email}`
  //     );

  //     if (response.data.success === 0) {
  //       setCurrentLicense(0);
  //     } else if (response.data.data[0].time === 0) {
  //       setCurrentLicense(1);
  //     } else if (response.data.data[0].active === 1) {
  //       setCurrentLicense(2);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     logout();
  //   }
  // };

  // useEffect(() => {
  //   verifyUser(currentUser);
  //   if (!currentUser) {
  //     navigate("/login");
  //   } else {
  //     verifyLicense();
  //     if (currentLicense !== null && currentLicense !== 2) {
  //       navigate("/login");
  //     } else if (currentUser.user_type === 3) {
  //       navigate("/");
  //     }
  //   }
  // }, [currentLicense, currentUser]);

  return (
    <>
      <Stack spacing={4}>
        <Grid spacing={2} container xs={12} justifyContent="flex-end" paddingTop={2} paddingLeft={4} paddingRight={2}>
          {(currentUser && currentUser.user_type === 0) ||
          (currentUser && currentUser.user_type === 1) ||
          (currentUser && currentUser.user_type === 2) ||
          (currentUser && currentUser.user_type === 3) ? (
            <>
              <Grid item xs={12} sm={2.5} md={2} lg={2} xl={1}>
                <Box display="flex" justifyContent="left" borderRadius="4px" alignItems="left" backgroundColor={colors.blueAccent[600]}>
                  <Select
                    fullWidth
                    hiddenLabel
                    variant="filled"
                    value={currentLanguage}
                    onChange={handleLanguageChange}
                    sx={{
                      fontSize: "15px",
                    }}
                    size="small"
                  >
                    <MenuItem value={"pt"} sx={{ fontSize: "16px" }}>
                      {/* <Flag code="pt" width={"10%"} /> */}
                      Português
                    </MenuItem>
                    <MenuItem value={"en"} sx={{ fontSize: "16px" }}>
                      {/* <Flag code="en" width={"10%"} /> */}
                      English
                    </MenuItem>
                    <MenuItem value={"de"} sx={{ fontSize: "16px" }}>
                      {/* <Flag code="de" width={"10%"} /> */}
                      Deutsch
                    </MenuItem>
                    {/* <MenuItem value={"en"} sx={{ fontSize: "16px" }}>
                      EN
                    </MenuItem> */}
                  </Select>

                  {/* <ToggleButtonGroup
                    fullWidth
                    sx={{
                      cursor: "pointer",
                      textDecoration: "none",
                    }}
                    value={currentLanguage}
                    exclusive
                    onChange={handleLanguageChange}
                  >
                    <ToggleButton
                      sx={{
                        ml: "5px",
                        textDecoration: "none",
                        fontWeight: "bold",
                        justifyContent: "center",
                        marginTop: "1px",
                      }}
                      value="pt"
                    >
                      PT
                    </ToggleButton>
                    <ToggleButton
                      sx={{
                        ml: "5px",
                        textDecoration: "none",
                        fontWeight: "bold",
                        justifyContent: "center",
                        marginTop: "1px",
                      }}
                      value="en"
                    >
                      EN
                    </ToggleButton>
                  </ToggleButtonGroup> */}
                </Box>
              </Grid>
              {/* {theme.palette.mode === "dark" ? (
                <Grid item xs={12} sm={3.5} md={2.5} lg={2} xl={1.5}>
                  <Box
                    m="0 auto"
                    p="5px 7px 5px 5px"
                    display="flex"
                    justifyContent="center"
                    borderRadius="4px"
                    alignItems="center"
                    backgroundColor={colors.greenAccent[600]}
                    sx={{
                      cursor: "pointer",
                      textDecoration: "none",
                    }}
                    onClick={colorMode.toggleColorMode}
                  >
                    <LightModeOutlinedIcon
                      sx={{
                        textDecoration: "none",
                        color: colors.grey[100],
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    />
                    <Typography
                      justifyContent="center"
                      color={colors.grey[100]}
                      sx={{
                        ml: "5px",
                        textDecoration: "none",
                        fontWeight: "bold",
                        justifyContent: "center",
                        marginTop: "1px",
                      }}
                    >
                      Mudar Tema
                    </Typography>
                  </Box>
                </Grid>
              ) : (
                <Grid item xs={12} sm={3.5} md={2.5} lg={2} xl={1}>
                  <Box
                    m="0 auto"
                    p="5px 7px 5px 5px"
                    display="flex"
                    justifyContent="center"
                    borderRadius="4px"
                    alignItems="center"
                    backgroundColor={colors.greenAccent[600]}
                    sx={{
                      cursor: "pointer",
                      textDecoration: "none",
                    }}
                    onClick={colorMode.toggleColorMode}
                  >
                    <DarkModeOutlinedIcon
                      sx={{
                        textDecoration: "none",
                        color: colors.grey[100],
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    />
                    <Typography
                      justifyContent="center"
                      color={colors.grey[100]}
                      sx={{
                        ml: "5px",
                        textDecoration: "none",
                        fontWeight: "bold",
                        justifyContent: "center",
                        marginTop: "1px",
                      }}
                    >
                      Mudar Tema
                    </Typography>
                  </Box>
                </Grid>
              )} */}
              <Grid item xs={12} sm={2.5} md={1.5} lg={1.5} xl={1}>
                <Box
                  m="0 auto"
                  p="5px 7px 5px 5px"
                  display="flex"
                  justifyContent="center"
                  borderRadius="4px"
                  alignItems="center"
                  backgroundColor={colors.blueAccent[600]}
                  sx={{
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                  component={Link}
                  to={`/edit_profile/${currentUser.id}`}
                >
                  <AccountBoxOutlinedIcon
                    sx={{
                      textDecoration: "none",
                      color: colors.primary[100],
                      justifyContent: "center",
                    }}
                  />
                  <Typography
                    justifyContent="center"
                    color={colors.primary[100]}
                    sx={{
                      ml: "5px",
                      textDecoration: "none",
                      fontWeight: "bold",
                      justifyContent: "center",
                      marginTop: "1px",
                    }}
                  >
                    {t("topbar.profile")}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={2.5} md={1.5} lg={1.5} xl={1}>
                <Box
                  m="0 auto"
                  p="5px 7px 5px 5px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="4px"
                  backgroundColor={colors.redAccent[600]}
                  sx={{
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                  onClick={forceLogout}
                >
                  <LogoutOutlinedIcon
                    sx={{
                      textDecoration: "none",
                      color: colors.grey[100],
                      justifyContent: "center",
                    }}
                  />
                  <Typography
                    justifyContent="center"
                    color={colors.grey[100]}
                    sx={{
                      ml: "5px",
                      textDecoration: "none",
                      fontWeight: "bold",
                      justifyContent: "center",
                      marginTop: "1px",
                    }}
                  >
                    {t("topbar.logout")}
                  </Typography>
                </Box>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} sm={2.5} md={2} lg={2} xl={1}>
                <Box display="flex" justifyContent="left" borderRadius="4px" alignItems="left" backgroundColor={colors.blueAccent[600]}>
                  <Select
                    fullWidth
                    hiddenLabel
                    variant="filled"
                    value={currentLanguage}
                    onChange={handleLanguageChange}
                    sx={{
                      fontSize: "15px",
                    }}
                    size="small"
                  >
                    <MenuItem value={"pt"} sx={{ fontSize: "16px" }}>
                      {/* <Flag code="pt" width={"10%"} /> */}
                      Português
                    </MenuItem>
                    <MenuItem value={"en"} sx={{ fontSize: "16px" }}>
                      {/* <Flag code="en" width={"10%"} /> */}
                      English
                    </MenuItem>
                    <MenuItem value={"de"} sx={{ fontSize: "16px" }}>
                      {/* <Flag code="de" width={"10%"} /> */}
                      Deutsch
                    </MenuItem>
                    {/* <MenuItem value={"en"} sx={{ fontSize: "16px" }}>
                      EN
                    </MenuItem> */}
                  </Select>

                  {/* <ToggleButtonGroup
                    fullWidth
                    sx={{
                      cursor: "pointer",
                      textDecoration: "none",
                    }}
                    value={currentLanguage}
                    exclusive
                    onChange={handleLanguageChange}
                  >
                    <ToggleButton
                      sx={{
                        ml: "5px",
                        textDecoration: "none",
                        fontWeight: "bold",
                        justifyContent: "center",
                        marginTop: "1px",
                      }}
                      value="pt"
                    >
                      PT
                    </ToggleButton>
                    <ToggleButton
                      sx={{
                        ml: "5px",
                        textDecoration: "none",
                        fontWeight: "bold",
                        justifyContent: "center",
                        marginTop: "1px",
                      }}
                      value="en"
                    >
                      EN
                    </ToggleButton>
                  </ToggleButtonGroup> */}
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </Stack>
    </>
  );
};

export default Topbar;
